import { Modal } from '@sweb-front/components';
import { ModalLeaveWrapper, StyledValidateButtonWrapper } from '@vat/utils';
import {
  ButtonLeaveModalWrapper,
  DescriptionWrapper,
  LeaveContentWrapper,
  SVGIconErrorWrapper,
  SVGIconReturnWrapper,
  TitleWrapper,
} from './styles';
import { t } from 'i18next';
import useModalLeave from './useModalLeave';
import { StyledButtonLoader } from '@sweb-front/styles';

export interface IMoodalLeaveProps {
  close: () => void;
}

const ModalLeave = (props: IMoodalLeaveProps) => {
  const { returnToUrlWebMerchant, isLoading } = useModalLeave();
  return (
    <>
      <Modal
        isVisible={true}
        isFullScreen={false}
        isClosable={true}
        handleCloseModal={() => {
          if (!isLoading) {
            props.close();
          }
        }}
      >
        <ModalLeaveWrapper>
          <SVGIconErrorWrapper>
            <base-icon icon="warning" />
          </SVGIconErrorWrapper>
          <LeaveContentWrapper>
            <TitleWrapper level={3}>{t('LeavePage.title')}</TitleWrapper>
            <DescriptionWrapper>
              <span className="strong">{t('LeavePage.description')}</span>
              {t('LeavePage.confirmation')}
            </DescriptionWrapper>
          </LeaveContentWrapper>
          <ButtonLeaveModalWrapper>
            <StyledValidateButtonWrapper
              disabled={isLoading}
              onClick={props.close}
              id="modal-cancel-button"
            >
              {t('common.cancel')}
            </StyledValidateButtonWrapper>
            <StyledValidateButtonWrapper
              onClick={returnToUrlWebMerchant}
              id="modal-edit-button"
            >
              {isLoading ? (
                <StyledButtonLoader
                  isLoading
                  isTextInline
                  isHideBackground={false}
                />
              ) : (
                <>
                  <SVGIconReturnWrapper>
                    <base-icon icon="arrow-left-circle" />
                  </SVGIconReturnWrapper>
                  {t('common.back')}
                </>
              )}
            </StyledValidateButtonWrapper>
          </ButtonLeaveModalWrapper>
        </ModalLeaveWrapper>
      </Modal>
    </>
  );
};

export default ModalLeave;
