import { axiosInstance } from '@sweb-front/services';
import { useCallback, useState } from 'react';
import { useBreakpoints } from '@sweb-front/hooks';

const useOptionalDocumentCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isDesktop } = useBreakpoints();
  const [selected, setSelected] = useState<string | undefined>();
  const readFile = useCallback(
    async (url: string, name: string, fileName: string) => {
      setSelected(name);
      setIsLoading(true);
      try {
        const response = await axiosInstance().get(url);
        const base64File = response.data?.content;
        if (base64File) {
          if (isDesktop) {
            const pdfNewTab = window.open('');
            pdfNewTab?.document.write(
              `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${base64File}'></iframe>`
            );
          } else {
            const downloadLink = document.createElement('a');
            downloadLink.href = `data:application/pdf;base64,${base64File}`;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        } else {
          console.error(
            `Le fichier est récupéré mais la lecture du document ${fileName} depuis la COS rencontre une erreur`
          );
        }
        setIsLoading(false);
      } catch (e: any) {
        console.error(
          `La récupération du fichier ${fileName} depuis le COS a rencontré un problème, \n${e?.message}`
        );
        setIsLoading(false);
      }
    },
    [isDesktop]
  );

  const onClickOnLink = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!isLoading) {
        readFile(
          `vendors/opportunities/v1/cos/${e.target.id.replace(/^[_]+/gi, '')}`,
          e.target.id.replace(/^[_]+/gi, ''),
          e.target.name
        );
      }
    },
    [isLoading]
  );

  return {
    selected,
    isLoading,
    readFile,
    onClickOnLink,
    isDesktop,
  };
};

export default useOptionalDocumentCard;
