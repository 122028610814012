export const useRipple = () => {
  return {
    createRipple: (event: React.ChangeEvent<HTMLInputElement>) => {
      const button = event.currentTarget;
      const circle = document.createElement('span');
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
      circle.style.width = `${diameter}px`;
      circle.style.height = `${diameter}px`;
      circle.style.left = `${
        (event as any).clientX - (button.offsetLeft + radius)
      }px`;
      circle.style.top = `${(event as any).clientY - button.offsetTop + 10}px`;
      circle.classList.add('ripple');
      const ripple = button.getElementsByClassName('ripple')[0];
      if (ripple) {
        ripple.remove();
      }
      button.appendChild(circle);
    },
  };
};
