import axiosInstance from './httpCommon';
const MOCK_ALL = process.env.REACT_APP_MOCK_ALL;

// appel au BFF pour qu'il nettoie la session
export const cleanSession = (): Promise<void> => {
  if (MOCK_ALL === 'true') {
    console.log('mock BFF clean TRUE');
    return new Promise((resolve) => {
      resolve();
    });
  }

  return axiosInstance()
    .get(`/session/clean`)
    .then(() => {
      return Promise.resolve();
    });
};

export const fillSession = (
  onFailure: (error: string) => void
): Promise<void> => {
  // if (MOCK_ALL === 'true') {
  console.log('mock BFF fill TRUE');
  return new Promise((resolve) => {
    resolve();
  });
  // }
};
