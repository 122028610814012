export type BackArrowProps = {
    fill?: string;
  };
  
  export const BackArrowIcon = ({ fill = 'none' }) => {
    return (
      <svg
        width="28px"
        height="16px"
        viewBox="0 0 28 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>B6B419CC-4C0F-4A1C-9BEC-B2B7233685D3</title>
        <g stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
          <g transform="translate(-98.000000, -76.000000)" fill={fill}>
            <g
              id="Button/Ghost/Green/L/Enabled"
              transform="translate(98.666667, 71.000000)"
            >
              <g
                id="Ic/36px/Action/plus-circle-Copy-12"
                transform="translate(0.000000, 5.000000)"
              >
                <path
                  d="M26.6666667,8 C26.6666667,7.50915036 26.2402984,7.11123811 25.7143465,7.11123811 L3.25292334,7.11123811 L9.2468264,1.51914827 C9.4877101,1.29434125 9.5817859,0.966677874 9.49361634,0.659585771 C9.40544679,0.352493668 9.14842693,0.112627431 8.81937367,0.0303423502 C8.49032041,-0.0519427308 8.13922476,0.0358544055 7.89834106,0.260661427 L0.279779822,7.37075658 C0.100664208,7.53749589 4.4408921e-16,7.76389638 4.4408921e-16,8 C4.4408921e-16,8.23610362 0.100664208,8.46250411 0.279779822,8.62924342 L7.89834106,15.7393386 C8.13922476,15.9641456 8.49032041,16.0519427 8.81937367,15.9696576 C9.14842693,15.8873726 9.40544679,15.6475063 9.49361634,15.3404142 C9.5817859,15.0333221 9.4877101,14.7056588 9.2468264,14.4808517 L3.25292334,8.88876189 L25.7143465,8.88876189 C26.2402984,8.88876189 26.6666667,8.49084964 26.6666667,8 Z"
                  id="arrow-left"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };
  