const SvgInfooutlines: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 .952a9.048 9.048 0 100 18.096 9.048 9.048 0 000-18.096zm0 7.46c.438 0 .794.356.794.794v5.556a.794.794 0 01-.694.787l-.1.007a.794.794 0 01-.787-.695l-.007-.1v-5.555c0-.404.303-.738.694-.787l.1-.006zm0-3.968a1.19 1.19 0 011.184 1.07l.006.12a1.19 1.19 0 01-1.068 1.185L12 8.825a1.19 1.19 0 010-2.38z"
      />
    </g>
  </svg>
);

export default SvgInfooutlines;
