export const computeInitialTouched = (initialValues: {
  [key: string]: unknown;
}) =>
  Object.entries(initialValues)
    .map(([key, value]) =>
      value !== undefined && value !== '' ? key : undefined
    )
    .filter((item) => item !== undefined)
    .reduce(
      (acc, item) => (item !== undefined ? { ...acc, [item]: true } : acc),
      {}
    );
