import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  CloseButtonWrapper,
  ModalBackground,
  ModalWrapper,
  StyledCSSBackgroundTransition,
  StyledCSSModalTransition,
  StyledCloseButton,
  StyledCloseIcon,
} from './styles';

export type ModalProps = {
  id?: string;
  isOpened: boolean;
  closeModal: () => void;
  onClose?: () => void;
  children?: ReactNode;
};

const Modal = ({
  children,
  id,
  isOpened,
  closeModal,
  onClose = () => {},
}: ModalProps) => {
  const modalRef = useRef(null);
  const backgroundRef = useRef(null);
  return (
    <StyledCSSBackgroundTransition id={`${id}-modal-background`}>
      <CSSTransition
        in={isOpened}
        nodeRef={backgroundRef}
        timeout={75}
        classNames="modal-background"
      >
        <ModalBackground ref={backgroundRef}>
          <StyledCSSModalTransition id={id}>
            <CSSTransition
              in={isOpened}
              nodeRef={modalRef}
              timeout={275}
              classNames="modal"
            >
              <ModalWrapper ref={modalRef} className={id}>
                <CloseButtonWrapper className={`${id}`}>
                  <StyledCloseButton
                    type="button"
                    onClick={() => {
                      onClose();
                      closeModal();
                    }}
                  >
                    <StyledCloseIcon />
                  </StyledCloseButton>
                </CloseButtonWrapper>
                {children}
              </ModalWrapper>
            </CSSTransition>
          </StyledCSSModalTransition>
        </ModalBackground>
      </CSSTransition>
    </StyledCSSBackgroundTransition>
  );
};

export default Modal;
