import { useTranslation } from 'react-i18next';
import GenericInsuranceContainer from '../../GenericInsuranceContainer';
import { IInsuranceCmpProps } from '../OptionalInsuranceCard';
import useConstants from '../useConstants';

const InsuranceDeath = ({
  onSelectOption,
  onReturnClick,
  value,
  priceDetails,
}: IInsuranceCmpProps) => {
  const { t } = useTranslation();
  const { documents, offerDetails, options } = useConstants(
    priceDetails,
    false,
    ['DIM', 'DIMC']
  );

  return (
    <GenericInsuranceContainer
      offerTitle={t('insurance.details.dTitle')}
      offerDetails={offerDetails}
      documents={documents}
      options={options}
      value={value}
      onReturnClick={onReturnClick}
      onSelectOption={() => onSelectOption(value)}
    />
  );
};

export default InsuranceDeath;
