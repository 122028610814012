import styled from 'styled-components';

export const ScrollIndicator = styled.span`
  background: linear-gradient(#ffffff00, #ffffffd6);
  position: sticky;
  padding: 1.5rem;
  bottom: 0;
  width: 100%;
`;

export const ScrollContainerDiv = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .scroll-indicator-enter {
    z-index: 1;
    opacity: 0;
  }
  .scroll-indicator-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .scroll-indicator-exit {
    opacity: 1;
  }
  .scroll-indicator-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
  .scroll-indicator-exit-done {
    z-index: -1000;
  }
`;
