/* eslint-disable no-nested-ternary */
import { useTheme } from 'styled-components';
import { Theme } from '@sweb-front/styles';
import {
  ErrorMessageWrapper,
  IconCenter,
  IconContainer,
  SelectInputContainer,
  SelectLabel,
  StyledButton,
  StyledChevronRightIcon,
  TextContainer,
} from './styles';
import {
  CrossCircleWrapper,
  InfoGlyphIconWrapper,
} from '../InputNumber/styles';

export type SelectInputProps = {
  id: string;
  label?: string;
  placeholder?: string;
  onClick: () => void;
  errorMessage?: string;
  value?: string;
  touched?: boolean;
  isBoldText?: boolean;
};

const SelectInputWithoutOption = ({
  id,
  label,
  placeholder,
  onClick,
  errorMessage,
  value,
  touched = false,
  isBoldText = false,
}: SelectInputProps) => {
  const theme = useTheme() as Theme;
  const isDirty = touched || value !== undefined;
  const isError = errorMessage !== undefined && isDirty;
  const isValid = errorMessage === undefined && isDirty;

  return (
    <SelectInputContainer className={id}>
      <SelectLabel htmlFor={id}>{label}</SelectLabel>
      <StyledButton
        className={id}
        id={id}
        type="button"
        onClick={onClick}
        isValid={isValid}
        isError={isError}
      >
        <TextContainer className={id} isBold={isBoldText}>
          {value || placeholder || ''}
        </TextContainer>
        <IconContainer className={id}>
          <IconCenter>
            {isDirty ? (
              isError ? (
                <InfoGlyphIconWrapper fill={theme.vendor.colors.error} />
              ) : (
                <CrossCircleWrapper fill={theme.vendor.colors.primary} />
              )
            ) : (
              <></>
            )}
          </IconCenter>
          <IconCenter>
            <StyledChevronRightIcon className={id} />
          </IconCenter>
        </IconContainer>
      </StyledButton>
      {isError && (
        <ErrorMessageWrapper id="errorMessage">
          {errorMessage}
        </ErrorMessageWrapper>
      )}
    </SelectInputContainer>
  );
};

export default SelectInputWithoutOption;
