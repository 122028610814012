import { useTranslation } from 'react-i18next';
import { BackButtonWrapper } from './styles';

export interface IReturnButtonProps {
  onReturnClick: () => void;
}

const ReturnButton = ({ onReturnClick }: IReturnButtonProps) => {
  const { t } = useTranslation();
  return (
    <BackButtonWrapper>
      <button-link
        label={t('insurance.details.button.backToAssurance')}
        onClick={onReturnClick}
      />
    </BackButtonWrapper>
  );
};

export default ReturnButton;
