import { TAB } from '@sweb-front/utils';
import { Icons } from '@sweb-front/components';
import styled from 'styled-components';

export const OrderSummaryCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.vendor.colors.title};
`;

export const OrderSummaryCardGlobal = styled(OrderSummaryCardHeader)``;

export const OrderSummaryCardHeaderProductSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and ${TAB} {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
`;
export const OrderSummaryProductSummaryDescription = styled.div<{
  isRecapDescription?: boolean;
}>`
  font-size: 1.2rem;
  margin-top: ${({ isRecapDescription }) =>
    isRecapDescription ? 'calc(1.8rem - 1.6rem)' : ''};
  line-height: 1.6rem;
  color: var(--neutrals-400);
`;

export const OrderSummaryCardHeaderFinanceSummary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  .paddedSpan {
    text-align: right;
  }
  @media screen and ${TAB} {
    .paddedSpan {
      display: flex;
    }
  }
`;

export const TranslationWrapper = styled.div`
  span:last-child {
    font-family: 'Open Sans Bold';
  }
`;

export const OrderSummaryCheckCircleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -0.8rem;
  .checkCircleOrderSummaryCardCreditContent {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

export const OrderSummaryCheckCircleLabel = styled.span`
  margin-left: 0.4rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const PaddedSpan = styled.span`
  color: var(--neutrals-700);
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: 'Open Sans Bold';
  &.summaryPaddedSpan {
    span {
      color: var(--neutrals-700);
    }
  }
`;

export const MediumSpan = styled.span`
  color: var(--neutrals-500);
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const BigSpan = styled.span`
  color: var(--neutrals-700);
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const CenterCol = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  max-height: 2rem;
  justify-content: center;
`;

export const StyledChevronDownIconWrapper = styled.div`
  text-align: center;
  width: 2rem;
  height: 2.4rem;
  &.styledChevronDownIcon {
    margin-left: 1.6rem;
  }
`;

export const StyledChevronDownIcon = styled((props) => (
  <Icons.ChevronDownIcon {...props} />
))<{
  isOpened: boolean;
}>`
  & path {
    stroke: var(--neutrals-500);
    stroke-width: 0.5px;
    height: 1.6rem;
    width: 0.8rem;
  }
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  transform: rotate(${({ isOpened }) => (isOpened ? '180deg' : '0deg')});
`;

export const BoldText = styled.span`
  font-family: 'Open Sans Bold';
  color: var(--neutrals-500);
  font-size: 1.2rem;
  line-height: 1.6rem;
  @media screen and ${TAB} {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
`;

export const BoldTextAmount = styled.span`
  font-family: 'Open Sans Bold';
  color: var(--neutrals-500);
  font-size: 1.4rem;
  line-height: 2rem;
  &.boldTextAmountFixedTaeg {
    color: var(--neutrals-700);
  }
`;

export const BolderTextAmount = styled(BoldTextAmount)`
  font-size: 1.6rem;
  line-height: 2.4rem;
  &.summaryBolderTextAmount {
    color: var(--neutrals-700);
  }
`;

export const SmallTextPill = styled.span`
  font-family: 'Open Sans';
  color: var(--neutrals-700);
  font-size: 1.2rem;
  line-height: 1.6rem;
  @media screen and ${TAB} {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }
`;

export const SubTextLabel = styled.span`
  color: var(--neutrals-500);
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const TextWrapper = styled.div``;

export const TextHeaderAmount = styled.span`
  color: var(--neutrals-500);
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const BoldTextHeader = styled.span`
  font-family: 'Ubuntu Bold';
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: var(--neutrals-800);
`;

export const OrderSummaryGlobalCardTitle = styled(BoldTextHeader)`
  @media screen and ${TAB} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

export const NormalText = styled.span`
  font-family: 'Open Sans';
  color: var(--neutrals-700);
  line-height: 2rem;
  font-size: 1.4rem;
  &.isChildren {
    font-family: 'Open Sans Bold';
  }
  &.displayLineClamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
`;

export const MonthlyLabel = styled.span`
  font-family: 'Open Sans Bold';
  line-height: 2rem;
  font-size: 1.4rem;
`;

export const ProductNameTitle = styled.span`
  font-family: 'Open Sans';
  color: var(--neutrals-700);
  line-height: 2rem;
  font-size: 1.4rem;
  &.displayLineClamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
`;

export const ProductNameAndPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

export const CardContent = styled.div`
  position: relative;
  padding:  0 1.6rem 1.6rem 1.6rem;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  row-gap: 1.6rem;
  color: var(--neutrals-500)
  transition-property: visibility;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  transform-origin: top;
`;

export const CardContentGlobal = styled.div`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #c7cacd;
  }
  position: relative;
  display: flex;
  padding-top: 1.2rem;
  margin-top: 1.2rem;
  flex-direction: column;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  row-gap: 1.2rem;
  color: var(--neutrals-500)
  transition-property: visibility;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  transform-origin: top;
  @media screen and ${TAB} {
    padding: 1.6rem 0;
  }
`;

export const CardCashContent = styled.div<{ withBorderTop: boolean }>`
  padding: 1.6rem 1.6rem 0 1.6rem;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  row-gap: 2.6rem;
  color: ${({ theme }) => theme.vendor.colors.title};
  @media screen and ${TAB} {
    &:before {
      width: calc(100% - 8rem);
    }
  }
`;

export const CardContentLineGlobal = styled.div<{ name?: string }>`
  display: flex;
  position: relative;
  color: var(--neutrals-500);
  flex-direction: row;
  margin-top: ${({ name }) =>
    name === 'totalAmount' ? 'calc(2.6rem - 1.6rem)' : ''};
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const PeriodLineGlobal = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
`;

export const CardContentLine = styled.div`
  display: flex;
  position: relative;
  color: var(--neutrals-500);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  &:after {
    content: '';
    position: absolute;
    bottom: -0.8rem;
    left: 0;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid var(--neutrals-200);
  }
`;

export const CardContentLineTradeIn = styled.div`
  display: flex;
  row-gap: 0.8rem;
  flex-direction: column;
`;

export const CardContentLineTradeInIconWrapper = styled.span`
  color: var(--neutrals-400);
`;

export const CardContentLineTradeInAmount = styled.span`
  text-align: right;
`;

export const CardContentLineTradeInAmountLight = styled(
  CardContentLineTradeInAmount
)`
  color: var(--neutrals-400);
`;

export const CardContentLineTAEGCredit = styled.div`
  display: flex;
  position: relative;
  color: var(--neutrals-500);
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 2rem;
  &:after {
    content: '';
    position: absolute;
    bottom: -0.8rem;
    left: 0;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid var(--neutrals-200);
  }
`;

export const CardContentLineGlobalTAEG = styled.div`
  display: flex;
  position: relative;
  color: var(--neutrals-500);
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const StyledLink = styled.a`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: 1.6rem;
  display: inline-block;
  color: ${({ theme }) => theme.vendor.colors.primary};
  text-decoration: underline;
`;

export const CardContentLineBold = styled.div<{ withBorderTop: boolean }>`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-500);
  &:after {
    content: '';
    position: absolute;
    bottom: -0.8rem;
    left: 0;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid var(--neutrals-200);
    display: ${({ withBorderTop }) => (withBorderTop ? 'block' : 'none')};
  }
`;

export const CardDisclosure = styled.div<{
  withBorderTop?: boolean;
  fullWidth?: boolean;
}>`
  &:before {
    ${({ withBorderTop }) =>
      withBorderTop &&
      `
        content : "";
        position: absolute;
        top  : 0;
        height  : 1px;
        border-bottom:1px solid #c7cacd;
      `}
    left: ${({ fullWidth }) => (fullWidth ? '0' : '1.6rem')};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(100% - 3.6rem)')};
  }
  padding: 1.6rem;
  ${({ withBorderTop }) =>
    withBorderTop &&
    `
    padding-top: 2.35rem;
  `}
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export const CardSummaryCardCreditContent = styled.div`
  .animation-enter {
    opacity: 0;
  }
  .animation-enter-active {
    -webkit-animation: fromTop 200ms ease-in both; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fromTop 200ms ease-in both; /* Firefox < 16 */
    -ms-animation: fromTop 200ms ease-in both; /* Internet Explorer */
    -o-animation: fromTop 200ms ease-in both; /* Opera < 12.1 */
    animation: fromTop 200ms ease-in both;
  }
  @keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  /* Firefox < 16 */
  @-moz-keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  /* Opera < 12.1 */
  @-o-keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .animation-exit {
    opacity: 1;
  }
  .animation-exit-active {
    -webkit-animation: toTop 200ms ease-in-out both; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: toTop 200ms ease-in-out both; /* Firefox < 16 */
    -ms-animation: toTop 200ms ease-in-out both; /* Internet Explorer */
    -o-animation: toTop 200ms ease-in-out both; /* Opera < 12.1 */
    animation: toTop 200ms ease-in-out both;
  }
  @keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  /* Firefox < 16 */
  @-moz-keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  /* Internet Explorer */
  @-ms-keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  /* Opera < 12.1 */
  @-o-keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const StyledImage = styled.img`
  box-sizing: border-box;
  border-radius: 4px;
  width: 48px;
  height: 44px;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1.6rem;
`;
