import { styled } from 'styled-components';
import { ChevronRightIcon } from '../Icons';

export const SelectInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
  &[class*=' education_'] {
    row-gap: 1.6rem;
  }
`;

export const StyledButton = styled.button<{ isValid; isError }>`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.vendor.colors.text};
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ theme, isValid, isError }) => {
    if (isValid) {
      return theme.vendor.colors.primary;
    }
    if (isError) {
      return theme.vendor.colors.error;
    }
    return theme.vendor.colors.text;
  }};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.8rem;
  &[class*=' education_'] {
    text-align: left;
    padding: 1.6rem;
  }
`;

export const SelectLabel = styled.label`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const TextContainer = styled.span<{ isBold }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  font-family: ${({ isBold }) => (isBold ? 'Ubuntu Bold' : 'Open Sans')};
  &[class*=' education_'] {
    color: var(--neutrals-700);
    font-family: 'Ubuntu Bold';
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
`;

export const StyledChevronRightIcon = styled(ChevronRightIcon)`
  height: 2.4rem;
  color: ${({ theme }) => theme.vendor.colors.text};
  &[class*=' education_'] {
    path {
      fill: var(--neutrals-700);
    }
  }
`;

export const IconCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  &[class*!=' education_'] {
    flex-direction: row;
    column-gap: 0.8rem;
  }
  align-self: center;
`;

export const ErrorMessageWrapper = styled.span`
  color: ${({ theme }) => theme.vendor.colors.error};
`;
