import styled from 'styled-components';

export const SpinnerLongText = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: var(--neutrals-500);
`;

export const SpinnerLabel = styled.div`
  font-size: 1.8rem;
  font-family: 'Open Sans Bold';
  text-align: center;
  color: var(--neutrals-700);
`;
