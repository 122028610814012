import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProject, IState } from './configurationTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const updateRequestIdAndTimeout = (reqIdAndTimeout: Partial<IState>) => {
  if (!reqIdAndTimeout) {
    return;
  }
  if (reqIdAndTimeout.requestId) {
    sessionStorage.setItem('requestId', reqIdAndTimeout.requestId);
  }
  if (reqIdAndTimeout.timeout) {
    sessionStorage.setItem('timeout', '' + reqIdAndTimeout.timeout);
  }
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfiguration(configState, action: PayloadAction<IState>) {
      configState.state = { ...action.payload };
      updateRequestIdAndTimeout(configState.state);
    },
    updateConfiguration(configState, action: PayloadAction<Partial<IState>>) {
      configState.state = { ...configState.state, ...action.payload };
      updateRequestIdAndTimeout(configState.state);
    },
    setCardName(configState, action: PayloadAction<string>) {
      configState.state.cardName = action.payload;
    },
    setOrganizationId(configState, action: PayloadAction<string>) {
      configState.state.organizationId = action.payload;
    },
    setChainNb(configState, action: PayloadAction<string>) {
      configState.state.chainNb = action.payload;
    },
    setProject(configState, action: PayloadAction<IProject>) {
      configState.state.project = action.payload;
    },
    setVendorId(configState, action: PayloadAction<string>) {
      configState.state.vendorId = action.payload;
    },
    setInstallmentId(configState, action: PayloadAction<string>) {
      configState.state.installmentId = action.payload;
    },
    setReturnUrl(configState, action: PayloadAction<string>) {
      configState.state.returnUrl = action.payload;
    },
    setIsInTestMode(configState, action: PayloadAction<boolean>) {
      configState.state.isInTestMode = action.payload;
    },
    setRequestId(configState, action: PayloadAction<string>) {
      configState.state.requestId = action.payload;
      updateRequestIdAndTimeout(configState.state);
    },
    setAvailableToRiba(configState, action: PayloadAction<boolean>) {
      configState.state.availableToRiba = action.payload;
    },
    clear(configState) {
      configState.state = getInitialState().state;
    },
  },
});

export const selectConfiguration = (configState: {
  configuration: { state: IState };
}) => configState.configuration.state;

export const {
  setConfiguration,
  updateConfiguration,
  setCardName,
  setOrganizationId,
  setChainNb,
  setProject,
  setVendorId,
  setInstallmentId,
  setReturnUrl,
  setIsInTestMode,
  setRequestId,
  setAvailableToRiba,
  clear,
} = configurationSlice.actions;

export default configurationSlice.reducer;
