import {
  BannerWrapper,
  BasicCardWrapper,
  CardDescriptionWrapper,
  DescriptionContentWrapper,
  ListDetailsWrapper,
} from './styles';

export interface ICustomizedCardProps {
  bannerTitle?: string;
  cardDescription?: React.ReactNode;
  children: React.ReactNode;
}

const CustomizedCard = ({
  bannerTitle,
  cardDescription,
  children,
}: ICustomizedCardProps) => {
  return (
    <BasicCardWrapper>
      {bannerTitle && bannerTitle !== '' && (
        <BannerWrapper>{bannerTitle}</BannerWrapper>
      )}
      {cardDescription && (
        <CardDescriptionWrapper>
          <DescriptionContentWrapper>
            {cardDescription}
          </DescriptionContentWrapper>
        </CardDescriptionWrapper>
      )}
      <ListDetailsWrapper>{children}</ListDetailsWrapper>
    </BasicCardWrapper>
  );
};

export default CustomizedCard;
