import { useState } from 'react';

export const useModal = () => {
  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => {
    setIsOpened(true);
    (document.body?.style as CSSStyleDeclaration).overflow = 'hidden';
  };
  const closeModal = () => {
    setIsOpened(false);
    (document.body?.style as CSSStyleDeclaration).overflow = 'scroll';
  };

  return {
    isOpened,
    openModal,
    closeModal,
  };
};
