import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectCombo } from '@sweb-front/types';
import { IState as IReferentielState } from './referentielsTypes';

const getInitialState = () => {
  return { state: {} } as { state: IReferentielState };
};

export const referentielsSlice = createSlice({
  name: 'referentiel',
  initialState: getInitialState(),
  reducers: {
    clear(referentiel) {
      referentiel.state = getInitialState().state;
    },
    setCountries(referentiel, data: PayloadAction<ISelectCombo[]>) {
      referentiel.state.countries = data.payload;
    },
    setCities(referentiel, data: PayloadAction<ISelectCombo[]>) {
      referentiel.state.cities = data.payload;
    },
    setDepartements(referentiel, data: PayloadAction<ISelectCombo[]>) {
      referentiel.state.departements = data.payload.map((item) => {
        return { ...item, name: `${item.name} - ${item.test}` };
      });
    },
    setNationalities(referentiel, data: PayloadAction<ISelectCombo[]>) {
      const nationalities = [...data.payload];
      referentiel.state.nationalities = nationalities.map((item) => {
        if (item.test === 'FR') return { ...item, name: 'Française' };
        return item;
      });
    },
  },
});

export const {
  clear,
  setCountries,
  setCities,
  setDepartements,
  setNationalities,
} = referentielsSlice.actions;

export type { IReferentielState };
export default referentielsSlice.reducer;
