import styled from 'styled-components';

export const EditableInputColumnsLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.4rem;
`;

export const EditableInputRowLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
