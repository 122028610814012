import { abortOpportunity } from '@vat/services';
import { ERRORDETAILS } from '@sweb-front/services';
import { useCallback } from 'react';

export const useCloseOpportunity = () => ({
  closeOpportunity: useCallback(
    (
      token: string,
      reason?: string,
      callback?: () => void,
      errorCallback?: () => void
    ) => {
      abortOpportunity(token, reason)
        .then(() => {
          console.warn(`Aborted opportunity successfully, reason: ${reason}`);
          localStorage.removeItem(ERRORDETAILS);
          callback?.();
        })
        .catch((err) => {
          errorCallback?.();
          console.error('Error while aborting opportunity', err);
        });
    },
    []
  ),
  updateAbortErrorMessage: useCallback((otherReasons = '') => {
    let actualErrorMessage = localStorage.getItem(ERRORDETAILS);
    if (!actualErrorMessage) {
      actualErrorMessage = otherReasons;
    } else {
      actualErrorMessage = (actualErrorMessage ?? '').replace(
        '$OtherReasons',
        otherReasons
      );
    }
    localStorage.setItem(ERRORDETAILS, actualErrorMessage ?? '');
  }, []),
});
