import { StyledValidateButtonWrapper } from '@vat/utils';
import { styled } from 'styled-components';
import { DESKTOP, TAB } from '@sweb-front/utils';

export const StyledContinueButtonWrapper = styled((props) => (
  <StyledValidateButtonWrapper {...props} />
))`
  min-width: 28.8rem;
  @media screen and ${TAB} {
    width: 50%;
  }
`;

export const ObButtonAWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin-top: 4.2rem;
`;

export const ObTitleWrapper = styled.div`
  text-align: center;
  margin-top: 2.4rem;
  font-family: 'Ubuntu Bold';
  font-size: 2rem;
  line-height: 2.8rem;

  @media screen and ${TAB} {
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
  }
`;

export const ObContentWrapper = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin-top: 2.4rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Open Sans';

  @media screen and ${TAB} {
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
  }
`;
