import { createComponent } from '@lit-labs/react';
import React from 'react';
import { CustomValidity, Replacement } from '.';

export class EmailInputDS extends HTMLElement {
  required?: boolean;
  maxLength?: string;
  class?: string;
  value: unknown;
  label?: string;
  replacement?: string | Replacement[];
  customValidityPattern?: string | CustomValidity[];
  constructor() {
    super();
  }
}

export const EmailInput = createComponent(React, 'email-input', EmailInputDS, {
  onInputChange: 'input-changed',
  onFocus: 'element-focused',
  onLetterChanged: 'letter-changed',
  onBlur: 'element-blured',
});
