import styled from 'styled-components';

const AsterikWrapper = styled.span`
  font-family: 'Open Sans';
  color: var(--neutrals-400);
  font-size: 1.2rem;
  line-height: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const AsteriksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export default AsterikWrapper;
