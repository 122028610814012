import { useAppSelector } from '@sweb-front/store';
import { MONTHS_MAPPING, SOLVAPAGE } from '@vat/configuration';
import { setAllFieldsTouched } from '@vat/utils';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  DesktopSelect,
  InputNumber,
  Modal,
  SelectInputWithoutOption,
  NavigationButtons,
  RadioGroup,
  useModal,
} from '@vat/components';
import {
  FormWrapper,
  IncomeDetailsInfoWrapper,
  ModalRadioGroupContainer,
} from './styles';
import { useIncomeExpensesForm } from './useIncomeExpensesForm';
import { BubbleInfo } from '@sweb-front/components';

export interface IIncomeExpensesFormProps {
  fields: string[];
}

const IncomeExpensesForm = ({ fields }: IIncomeExpensesFormProps) => {
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const {
    formik,
    t,
    isDesktop,
    isDirty,
    goBackToStep,
    sectors,
    showBubbleInfo,
    showBaseMonthIncome,
    showExpensesBubbleInfo,
    onIncomeFocus,
    onIncomeBlur,
    onExpensesFocus,
    onExpensesBlur,
    onIncomeChange,
    isActionLoading,
  } = useIncomeExpensesForm({
    opportunity,
    codeParameters: parameters.wayCd,
    fields,
  });
  const { isOpened, openModal, closeModal } = useModal();
  const incomeDetailsRef = useRef(null);
  const expensesDetailsRef = useRef(null);

  return (
    <>
      <FormWrapper>
        {fields.includes(SOLVAPAGE.SOLVACT) &&
          (isDesktop ? (
            <>
              <DesktopSelect
                id="activitySector"
                label={t('incomesAndExpenses.form.fields.activitySectors')}
                placeholder={t(
                  'incomesAndExpenses.form.placeholder.activitySectors'
                )}
                options={sectors}
                errorMessage={formik.errors.activitySector}
                value={formik.values.activitySector}
                onChange={(value) =>
                  formik.setFieldValue('activitySector', value)
                }
                onClose={() => formik.setFieldTouched('activitySector')}
                touched={formik.touched.activitySector}
              />
            </>
          ) : (
            <>
              <SelectInputWithoutOption
                id="activitySector"
                onClick={() => openModal()}
                errorMessage={formik.errors.activitySector}
                label={t('incomesAndExpenses.form.fields.activitySectors')}
                placeholder={t(
                  'incomesAndExpenses.form.placeholder.activitySectors'
                )}
                value={
                  sectors.find(
                    ({ value }) => value === formik.values.activitySector
                  )?.label
                }
                touched={formik.touched.activitySector}
              />
            </>
          ))}
        {fields.includes(SOLVAPAGE.SOLVREV) && (
          <div>
            <InputNumber
              id="monthlyIncome"
              errorMessage={formik.errors.monthlyIncome}
              key={`monthlyIncome-${formik.touched.monthlyIncome}`}
              label={t(
                'incomesAndExpenses.form.fields.monthlyIncomeAndExpenses'
              )}
              placeholder={t(
                'incomesAndExpenses.form.placeholder.monthlyIncomeAndExpenses'
              )}
              value={
                formik.values.monthlyIncome
                  ? String(formik.values.monthlyIncome)
                  : undefined
              }
              afterText="€ / mois"
              onChange={onIncomeChange}
              maxLength={5}
              onFocus={onIncomeFocus}
              onBlur={onIncomeBlur}
              touched={formik.touched.monthlyIncome}
              withResetButton
              isInteger
            />
            <CSSTransition
              in={showBubbleInfo}
              nodeRef={incomeDetailsRef}
              timeout={225}
              unmountOnExit
              classNames="animation"
            >
              <IncomeDetailsInfoWrapper ref={incomeDetailsRef}>
                <BubbleInfo
                  id="bubbleinfo-income"
                  title={t('incomesAndExpenses.form.bubbleInfos.report')}
                  bubbleItems={[
                    t('incomesAndExpenses.form.bubbleInfos.incomeAndBonus'),
                    t('incomesAndExpenses.form.bubbleInfos.retirements'),
                    t('incomesAndExpenses.form.bubbleInfos.freelanceincomes'),
                    t('incomesAndExpenses.form.bubbleInfos.pensions'),
                  ]}
                  isCustomedMarker={false}
                  marker="default"
                  hasIcon={true}
                  textColor="#007590"
                />
              </IncomeDetailsInfoWrapper>
            </CSSTransition>
          </div>
        )}
        {showBaseMonthIncome && (
          <RadioGroup
            id="monthNumberBaseCalculation"
            key={`monthNumberBaseCalculation-error_${
              formik.errors.monthNumberBaseCalculation !== undefined
            }`}
            errorMessage={formik.errors.monthNumberBaseCalculation}
            touched={formik.touched.monthlyExpenses}
            label={t(
              'incomesAndExpenses.form.fields.monthNumberBaseCalculation'
            )}
            buttonWidth="4.8rem"
            rowGap="1.2rem"
            wrapOnMobile={false}
            options={MONTHS_MAPPING}
            onChange={({ value }) =>
              formik.setFieldValue('monthNumberBaseCalculation', Number(value))
            }
            value={String(formik.values.monthNumberBaseCalculation)}
          />
        )}
        {fields.includes(SOLVAPAGE.SOLVCHRGS) && (
          <div>
            <InputNumber
              id="monthlyExpenses"
              key={`monthlyExpenses-${formik.touched.monthlyExpenses}`}
              errorMessage={formik.errors.monthlyExpenses}
              label={t('incomesAndExpenses.form.fields.monthlyExpenses')}
              placeholder={t(
                'incomesAndExpenses.form.placeholder.monthlyExpenses'
              )}
              afterText="€ / mois"
              value={
                formik.values.monthlyExpenses !== undefined
                  ? String(formik.values.monthlyExpenses)
                  : undefined
              }
              onChange={(value) =>
                formik.setFieldValue('monthlyExpenses', value)
              }
              maxLength={5}
              onFocus={onExpensesFocus}
              onBlur={onExpensesBlur}
              touched={formik.touched.monthlyExpenses}
              withResetButton
              isInteger
            />
            <CSSTransition
              in={showExpensesBubbleInfo}
              nodeRef={expensesDetailsRef}
              timeout={225}
              unmountOnExit
              classNames="animation"
            >
              <IncomeDetailsInfoWrapper ref={expensesDetailsRef}>
                <BubbleInfo
                  id="bubbleinfo-income"
                  title={`${t('incomesAndExpenses.form.bubbleInfos.report')}`}
                  bubbleItems={[
                    t(
                      'incomesAndExpenses.form.bubbleInfos.rentOrMonthlyMortagePayement'
                    ),
                    t(
                      'incomesAndExpenses.form.bubbleInfos.loanInProgressNotRealEstate'
                    ),
                    t('incomesAndExpenses.form.bubbleInfos.careExpenses'),
                  ]}
                  marker="default"
                  isCustomedMarker={false}
                  hasIcon={true}
                  textColor="#007590"
                />
              </IncomeDetailsInfoWrapper>
            </CSSTransition>
          </div>
        )}
        <NavigationButtons
          id="navigation-buttons"
          key={`nav-buttons-${
            isDirty && formik.isValid ? 'isValid' : 'isInvalid'
          }`}
          validateLabel={t('common.validate')}
          isLoading={isActionLoading}
          validateAction={() => {
            setAllFieldsTouched(formik.values, formik.setTouched);
            formik.handleSubmit();
          }}
          backLabel={t('common.back')}
          backAction={goBackToStep}
          isDisabled={!isDirty || !formik.isValid}
        />
      </FormWrapper>
      <Modal
        isOpened={isOpened}
        closeModal={closeModal}
        onClose={() => formik.setFieldTouched('activitySector')}
      >
        <ModalRadioGroupContainer>
          <RadioGroup
            id="activitySector-radio-group"
            label={t('incomesAndExpenses.form.sectorList.fieldDescription')}
            title={t('incomesAndExpenses.form.fields.activitySectors')}
            options={sectors}
            onChange={({ value }) => {
              formik.setFieldValue('activitySector', value);
              closeModal();
            }}
            value={formik.values.activitySector}
            touched={formik.touched.activitySector}
            buttonWidth="100%"
            rowGap="1.6rem"
            wrapOnMobile={false}
          />
        </ModalRadioGroupContainer>
      </Modal>
    </>
  );
};

export default IncomeExpensesForm;
