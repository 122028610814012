/* eslint-disable consistent-return */
import {
  ChangeEvent,
  KeyboardEvent,
  ChangeEventHandler,
  FocusEventHandler,
  FocusEvent,
  useState,
  useRef,
  useEffect,
  RefObject,
} from 'react';
import { useTheme } from 'styled-components';
import { Theme } from '@sweb-front/styles';
import {
  CrossCircleWrapper,
  IconWrapper,
  InfoGlyphIconWrapper,
  InputContainer,
  InputLabel,
  InputDateWrapper,
  UnstyledInput,
  ErrorMessageWrapper,
  InputSeparator,
  TripleInputContainer,
  CrossWrapper,
} from './styles';
import { CrossIcon } from '../Icons';

export type InputDateProps = {
  id: string;
  label?: string;
  value?: Date;
  dayOptional?: boolean;
  autoComplete?: { day: string; month: string; year: string };
  disabled?: boolean;
  onChange?: (value?: Date) => void;
  onChangeEvent?: ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string | any;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  touched?: boolean | any;
};

const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};
const computeDate = (number) => {
  return `${number}`.length === 1 ? `0${number}` : number;
};

const InputDate = ({
  id,
  label,
  value: valueProp,
  dayOptional = false,
  autoComplete,
  disabled = false,
  onChange: onChangeProp,
  onChangeEvent,
  errorMessage,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  touched = false,
}: InputDateProps) => {
  const theme = useTheme() as Theme;
  const [isFocused, setIsFocused] = useState(false);
  const [day, setDay] = useState(
    valueProp ? computeDate(valueProp.getDate()) : ''
  );
  const [month, setMonth] = useState(
    valueProp ? computeDate(valueProp.getMonth() + 1) : ''
  );
  const [year, setYear] = useState(valueProp?.getFullYear() ?? '');
  const [isDirty, setIsDirty] = useState(touched || valueProp !== undefined);

  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const onKeyPressSanitize = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Backspace' && e.key.match(/([^0-9])+/)) {
      e.preventDefault();
    }
  };

  const onDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (dayOptional) {
      return;
    }
    const newValue = e.target.value;
    setDay(newValue);
    if (newValue.length >= 2) {
      monthRef.current?.focus();
    }
  };

  const onMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target?.value;
    setMonth(newValue);
    if (newValue.length >= 2) {
      yearRef.current?.focus();
    }
  };

  const onYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target?.value;
    if (newValue.length > 4) {
      return;
    }
    setYear(newValue);
  };

  const onBackspacePress = (
    e: KeyboardEvent<HTMLInputElement>,
    currentField: RefObject<HTMLInputElement>,
    fieldToGoTo: RefObject<HTMLInputElement>
  ) =>
    currentField.current?.value === '' && e.key === 'Backspace'
      ? (() => {
          if (onChangeProp) {
            onChangeProp(undefined);
          }
          fieldToGoTo.current?.focus();
        })()
      : (() => {})();

  const onInputContainerClick = () => {
    if (dayRef.current?.value === '') {
      dayRef.current.focus();
      return;
    }
    if (monthRef.current?.value === '') {
      monthRef.current.focus();
      return;
    }
    if (yearRef.current?.value === '') {
      yearRef.current.focus();
    }
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocusProp) {
      onFocusProp(e);
    }
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget as Node)) {
      return;
    }
    setIsFocused(false);
    if (onBlurProp) {
      onBlurProp(e);
    }
    setIsDirty(true);
    setDay(day ? computeDate(day) : day);
    setMonth(month ? computeDate(month) : month);
  };

  const onResetClick = () => {
    setDay('');
    setMonth('');
    setYear('');
    if (onChangeProp) {
      onChangeProp(undefined);
    }
  };

  const isError = errorMessage !== undefined && isDirty && touched;
  const isValid = errorMessage === undefined && isDirty;

  useEffect(() => {
    const condition = dayOptional
      ? month === '' || year === ''
      : day === '' || month === '' || year === '';

    if (condition) {
      return;
    }

    const yearNumber = Number(year);
    const monthNumber = Number(month);
    const dayNumber = dayOptional ? 1 : Number(day);
    const date = new Date(
      Date.UTC(
        yearNumber < 100 ? NaN : yearNumber,
        monthNumber >= 1 && monthNumber <= 12 ? monthNumber - 1 : NaN,
        dayNumber >= 1 && dayNumber <= getDaysInMonth(monthNumber, yearNumber)
          ? dayNumber
          : NaN,
        12
      )
    );

    if (onChangeEvent) {
      onChangeEvent({
        target: { value: date.toString() },
      } as unknown as ChangeEvent<HTMLInputElement>);
    }

    if (onChangeProp) {
      onChangeProp(date);
    }

    setIsDirty(true);
  }, [day, month, year]);

  return (
    <InputDateWrapper id={id}>
      <InputLabel disabled={disabled}>{label}</InputLabel>
      <InputContainer
        disabled={disabled}
        isError={isError}
        dayOptional={dayOptional}
        isValid={isValid}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onInputContainerClick}
      >
        <TripleInputContainer>
          {!dayOptional && (
            <>
              <UnstyledInput
                id={`${id}-dd`}
                name={`${id}-day`}
                type="number"
                value={day}
                autoComplete={autoComplete?.day}
                placeholder="jj"
                disabled={disabled}
                onChange={onDayChange}
                onKeyPress={onKeyPressSanitize}
                textAlign="center"
                placeholderAlign="center"
                ref={dayRef}
                width="3.25rem"
                min={1}
                max={31}
              />
              <InputSeparator disabled={disabled}>
                <span>/</span>
              </InputSeparator>
            </>
          )}
          <UnstyledInput
            id={`${id}-mm`}
            name={`${id}-month`}
            type="number"
            value={month}
            autoComplete={autoComplete?.month}
            placeholder="mm"
            disabled={disabled}
            onChange={onMonthChange}
            onKeyPress={onKeyPressSanitize}
            onKeyDown={(e) => onBackspacePress(e, monthRef, dayRef)}
            textAlign="center"
            ref={monthRef}
            width="3.25rem"
            min={1}
            max={12}
          />
          <InputSeparator disabled={disabled}>
            <span>/</span>
          </InputSeparator>
          <UnstyledInput
            id={`${id}-yyyy`}
            name={`${id}-year`}
            type="number"
            value={year}
            autoComplete={autoComplete?.year}
            placeholder="aaaa"
            disabled={disabled}
            onChange={onYearChange}
            onKeyPress={onKeyPressSanitize}
            onKeyDown={(e) => onBackspacePress(e, yearRef, monthRef)}
            textAlign="center"
            ref={yearRef}
            width="4.5rem"
            min={1900}
            max={new Date().getFullYear()}
          />
        </TripleInputContainer>
        <IconWrapper>
          {isFocused ? (
            <CrossWrapper
              tabIndex={-1}
              type="button"
              onClick={() => onResetClick()}
            >
              <CrossIcon fill={theme.vendor.colors.border} />
            </CrossWrapper>
          ) : isDirty ? (
            errorMessage ? (
              <InfoGlyphIconWrapper fill={theme.vendor.colors.error} />
            ) : (
              <CrossCircleWrapper fill={theme.vendor.colors.primary} />
            )
          ) : (
            <></>
          )}
        </IconWrapper>
      </InputContainer>
      {isError && !disabled && (
        <ErrorMessageWrapper id="errorMessage">
          {errorMessage}
        </ErrorMessageWrapper>
      )}
    </InputDateWrapper>
  );
};

export default InputDate;
