import { BaseButton } from '@sweb-front/components';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectButtonWrapper } from './styles';

export interface ISelectButtonProps {
  onSelectOption: (e: MouseEvent<HTMLElement>) => void | undefined;
}

const SelectButton = ({ onSelectOption }: ISelectButtonProps) => {
  const { t } = useTranslation();
  return (
    <SelectButtonWrapper>
      <BaseButton onClick={onSelectOption}>
        {t('insurance.details.button.selectOption')}
      </BaseButton>
    </SelectButtonWrapper>
  );
};

export default SelectButton;
