import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './optionTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    setOption(optionState, action: PayloadAction<IState | undefined>) {
      optionState.state = {
        ...(action?.payload ?? (getInitialState().state as IState)),
      };
    },
    clear(optionState) {
      optionState.state = getInitialState().state;
    },
  },
});

export const selectOption = (optionState: { option: { state: IState } }) =>
  optionState.option.state;

export const { setOption, clear } = optionSlice.actions;

export default optionSlice.reducer;
