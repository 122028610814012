import { Section, Loader } from '@sweb-front/components';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useManagingExternalApp } from '@vat/utils';
import { useNavigate } from 'react-router-dom';

import { LoadingWrapper } from '@sweb-front/styles';

const InitOnbo = () => {
  const { t } = useTranslation();
  const { waitingPageBeforeOnbo } = useManagingExternalApp();

  useEffect(() => {
    waitingPageBeforeOnbo();
  }, []);

  return (
    <Section>
      <LoadingWrapper>
        <Loader
          isLoading
          primaryText={t('common.loader.shortText')}
          secondaryText={t('common.loader.longText')}
          isTextInline={false}
        />
      </LoadingWrapper>
    </Section>
  );
};

export default InitOnbo;
