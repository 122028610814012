import { createComponent } from '@lit-labs/react';
import React from 'react';

export class IconButtonDS extends HTMLElement {
  clickable?: boolean;

  disabled?: boolean | null;

  class?: string | null;

  icon?: string | null;
}

export const IconButton = createComponent(
  React,
  'icon-button',
  IconButtonDS,
  {}
);
