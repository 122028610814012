import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStep } from './stepType';

const getInitialState = () => {
  return { state: [] } as { state: IStep[] };
};

// Initial State
export const initialState = getInitialState();

const stepSlice = createSlice({
  name: 'stepsSlice',
  initialState,
  reducers: {
    addStep(stepsState, action: PayloadAction<IStep>) {
      stepsState.state.push(action.payload);
    },
    setSteps(stepsState, action: PayloadAction<IStep[]>) {
      stepsState.state = action.payload;
    },
    clear(stepState) {
      stepState.state = getInitialState().state;
    },
  },
});

export const selectSteps = (stepsState: { steps: { state: IStep[] } }) =>
  stepsState.steps.state;

export type { IStep };
export const { addStep, setSteps, clear } = stepSlice.actions;
export default stepSlice.reducer;
