import { Section } from '@sweb-front/components';
import { usePageRedirection } from '@sweb-front/hooks';
import { LOGEMENT } from '@vat/configuration';
import { HousingForm } from '@vat/containers';
import { useTranslation } from 'react-i18next';

const Housing = () => {
  const { t } = useTranslation();
  const { isPageLoading } = usePageRedirection(LOGEMENT);
  return (
    !isPageLoading && (
      <Section title={t('housing.title')}>
        <HousingForm />
      </Section>
    )
  );
};
export default Housing;
