import { createComponent } from '@lit-labs/react';
import React from 'react';

export class ChevronSelectDS extends HTMLElement {
  class?: string;

  value: unknown;

  label?: string;

  invalid?: boolean | null;

  valid?: boolean | null;

  required?: boolean;
}

export const ChevronSelect = createComponent(
  React,
  'chevron-select',
  ChevronSelectDS,
  {
    onSelectedElementChange: 'selected-element-changed',
  }
);
