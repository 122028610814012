import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseModalDS extends HTMLElement {
  show?: boolean;
  noClose?: boolean;
  contentSize?: DOMRect;
  fit?: boolean;
  name?: string;
  constructor() {
    super();
  }
}

export const BaseModal = createComponent(React, 'base-modal', BaseModalDS, {});
