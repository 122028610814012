import { Centering } from '@sweb-front/components';
import { DESKTOP, TAB } from '@sweb-front/utils';

import styled from 'styled-components';

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  @media screen and ${DESKTOP} {
    padding-top: 12rem;
    justify-content: center;
    background-color: var(--neutrals-0);
  }
  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
  }
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;

export const AppCenterContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  @media screen and ${DESKTOP} {
    background-color: #fff;
    height: 100%;
    width: 66rem;
    align-self: center;
    margin: 0; /* 120px */
    padding: 2.5rem; /* 24px */
  }
`;

export const FooterWrapper = styled.div`
  .banner-shown & div {
    margin-bottom: 8rem;
  }
  margin-top: 40px;
`;

export const RouteWrapper = styled.div`
  flex-grow: 1;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const TestModeBadgeWrapper = styled.div`
  min-height: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  width: fit-content;
`;

export const CenteringHeaderWrapper = styled((props) => (
  <Centering {...props} />
))`
  margin-bottom: 1rem;
  margin-top: 1rem;
  @media screen and ${TAB} {
    padding: 0 11.4rem;
    margin-bottom: 1.4rem;
    margin-top: 1.2rem;
  }
  @media screen and ${DESKTOP} {
    padding: 0 3rem;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }
`;
