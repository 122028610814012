import { createComponent } from '@lit-labs/react';
import React from 'react';

export class RadioButtonCardDS extends HTMLElement {
  class?: string;

  value: any;

  selected?: boolean;

  checked?: boolean;

  delegatesControl?: boolean;
}

export const RadioButtonCard = createComponent(
  React,
  'radio-button-card',
  RadioButtonCardDS,
  {
    onSelectedChange: 'selected-changed',
  }
);
