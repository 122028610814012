import { ReactNode } from 'react';
import { PillContainer } from './styles';

export type PillProps = {
  children: ReactNode;
};

export const Pill = ({ children }: PillProps) => {
  return <PillContainer>{children}</PillContainer>;
};

export default Pill;
