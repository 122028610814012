import { Level } from '@sweb-front/types';

export const LOGGER_ENDPOINT = '/ihmlogger/';

// TODO définir ici l'affichage des logs en fonction de l'environnement
// Les logs s'affichent dans la console du navigateur en ajoutant : REACT_APP_ENV=DEV dans le fichier env.local
const DO_CONSOLE_LOG = process.env.REACT_APP_ENV === 'DEV';

const getDataIn = (
  message: unknown[],
  level: Level
): { level: Level; log: Array<string> } => {
  let log: string;
  if (message?.length === 1) {
    if (typeof message[0] === 'string') {
      const [firstMessage] = message;
      log = firstMessage;
    } else {
      log = JSON.stringify(message[0], null, 2);
    }
  } else {
    log = JSON.stringify(message, null, 2);
  }
  return {
    level,
    log: log?.match(/(\S|\s){1,400}/g) ?? [], // Découpage en string de 400 => limitation WAF à 500 chars - marge pour les caractères d'échappement
  };
};

const basic = (message: unknown[], level = Level.LOG): void => {
  if (DO_CONSOLE_LOG) {
    console[level](...message);
  }
  // axiosInstance().post(LOGGER_ENDPOINT, getDataIn(message, level));
};

const log = (...args: unknown[]): void => {
  basic(args);
};

const warn = (...args: unknown[]): void => {
  basic(args, Level.WARN);
};

const info = (...args: unknown[]): void => {
  basic(args, Level.INFO);
};

const error = (...args: unknown[]): void => {
  basic(args, Level.ERROR);
};

const LOGGER = {
  log,
  warn,
  info,
  error,
};

export default LOGGER;
