import { createComponent } from '@lit-labs/react';
import React from 'react';
import { CustomValidity, Replacement } from '.';

export class DateInputDS extends HTMLElement {
  required?: boolean;

  label?: string;

  class?: string;

  value: unknown;

  date?: Date;

  defaultValue?: unknown;

  replacement?: string | Replacement[];

  customValidityPattern?: string | CustomValidity[];

  invalid?: boolean | null;

  pattern?: string | null;

  noDay?: boolean;
}

export const DateInput = createComponent(React, 'date-input', DateInputDS, {
  onInputChange: 'input-changed',
  onFocus: 'element-focused',
  onLetterChanged: 'letter-changed',
  onBlur: 'element-blured',
});
