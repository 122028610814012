import { IOption } from '@sweb-front/types';
import { v4 as uuid } from 'uuid';

export const getLabelFromList = (
  key: string,
  lists: IOption[]
): string | undefined => {
  const foundItem = lists.find((item) => item.id === key);
  return foundItem?.label;
};

export const getKeyFromList = (
  label: string,
  lists: IOption[]
): string | undefined => {
  const foundItem = lists.find((item) => item.label === label);
  return foundItem?.id;
};

export const getOptionFromList = (
  id: string,
  lists: IOption[] | null
): IOption | null => {
  return (lists ?? []).find((item) => item.id === id) ?? null;
};

export const getUniqueId = (): string => {
  return uuid();
};
