export const setAllFieldsTouched = (
  values: Record<string, unknown>,
  setTouched: (
    fields: Record<string, boolean>,
    shouldRevalidate?: boolean
  ) => void
) => {
  setTouched(
    Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
    true
  );
};
