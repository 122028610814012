import {ErrorPage} from '@vat/layouts';

interface SpecificErrorPageProp {
  handler: () => void;
}
const SpecificErrorPage = ({ handler }: SpecificErrorPageProp) => {
  handler();
  return <ErrorPage />;
};

export default SpecificErrorPage;
