export type CheckCircleProps = {
  fill?: string;
  className?: string;
};

const CheckCircle = ({
  fill = '#4B4F54',
  className = 'checkCircle',
}: CheckCircleProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.75C7.16751 20.75 3.25 16.8325 3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75ZM15.5832 8.26829L10.9997 13.8034L8.75531 11.5592C8.46241 11.2663 7.98754 11.2663 7.69465 11.5592C7.40177 11.8521 7.40178 12.327 7.69469 12.6199L10.4697 15.3947C10.7627 15.6876 11.2377 15.6875 11.5306 15.3944L16.6444 9.32846C16.9371 9.03543 16.9369 8.56056 16.6439 8.2678C16.3509 7.97504 15.876 7.97526 15.5832 8.26829Z"
        fill={fill}
      />
    </svg>
  );
};
export default CheckCircle;
