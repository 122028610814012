const SvgLoader: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 35 35" className={className}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#E5E5E5"
        fillRule="nonzero"
        d="M17.067 0C7.64 0 0 7.641 0 17.067c0 9.425 7.641 17.066 17.067 17.066 9.425 0 17.066-7.64 17.066-17.066C34.133 7.64 26.493 0 17.067 0zm0 4.876c6.732 0 12.19 5.458 12.19 12.19 0 6.733-5.458 12.191-12.19 12.191-6.733 0-12.19-5.458-12.19-12.19 0-6.733 5.457-12.19 12.19-12.19z"
      />
      <path
        fill="currentColor"
        d="M17.067 0c9.425 0 17.066 7.641 17.066 17.067a2.438 2.438 0 11-4.876 0c0-6.733-5.458-12.19-12.19-12.19a2.438 2.438 0 110-4.877z"
      />
    </g>
  </svg>
);

export default SvgLoader;
