import styled from 'styled-components';

const DescriptionWrapper = styled.div`
  font-family: 'Open Sans';
  color: var(--neutrals-500);
  margin: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-top: 0.4rem;
`;

export default DescriptionWrapper;
