import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: 'Open Sans';
  letter-spacing: 0;
`;

export const StyledCheckboxInput = styled.input`
  margin-top: 0.3rem;
  width: 2rem;
  height: 2rem;
  line-height: 1.9rem;
  position: relative;
  cursor: pointer;
  appearance: none;
  border-radius: 0.2rem;
  border: 0.1rem solid var(--neutrals-400);
  accent-color: var(--neutrals-400);

  &:active,
  &:focus-visible {
    outline: 0.2rem solid var(--dark-green-400);
    outline-offset: 0.2rem;
  }

  &:not(:checked):disabled,
  &:checked:disabled {
    cursor: not-allowed;
  }

  &:checked:disabled {
    background-color: var(--neutrals-300);
    border-color: transparent;
  }

  &:checked:not(:disabled) {
    background-color: var(--main-green-500);
  }

  &:checked:not(:disabled):hover {
    background-color: var(--main-green-600);
  }

  &:not(:checked):not(:disabled):hover {
    box-shadow: 0 0 0 0.4rem var(--main-green-50);
  }

  &:checked::after {
    content: url(/icons/checkMark.svg);
    position: absolute;
    left: 0.3rem;
  }
`;

export const StyledCheckboxLabel = styled.label`
  flex: 1;
  color: var(--neutrals-500);
  cursor: pointer;
`;
