import { formatNumberWithComma } from '@vat/containers';
import {
  TextHeaderAmount,
  CardCashContent,
  OrderSummaryCardHeader,
  OrderSummaryCardHeaderProductSummary,
  ProductContainer,
  ProductNameAndPriceContainer,
  NormalText,
} from './styles';

export type OrderSummaryCardCashContentProps = {
  logo?: string;
  description: string;
  productType: string;
  unitAmount: number;
  sequenceOrder: number;
  withBorderTop?: boolean;
};

const OrderSummaryCardCashContent = ({
  description,
  unitAmount,
  withBorderTop = false,
}: OrderSummaryCardCashContentProps) => {
  return (
    <CardCashContent
      withBorderTop={withBorderTop}
      data-testid="orderSummary-cash-product"
    >
      <OrderSummaryCardHeader>
        <OrderSummaryCardHeaderProductSummary>
          <ProductContainer>
            <ProductNameAndPriceContainer>
              <NormalText>{description}</NormalText>
              <TextHeaderAmount>
                {formatNumberWithComma(unitAmount)} €
              </TextHeaderAmount>
            </ProductNameAndPriceContainer>
          </ProductContainer>
        </OrderSummaryCardHeaderProductSummary>
      </OrderSummaryCardHeader>
    </CardCashContent>
  );
};

export default OrderSummaryCardCashContent;
