import styled from 'styled-components';
import { Description } from '@sweb-front/components';
import { TAB } from '@sweb-front/utils';

export const BasicCardWrapper = styled.div`
  border-radius: 0.8rem 0.8rem;
  background: #fff;
  box-shadow: var(--shadow-15);
`;

export const BannerWrapper = styled.div`
  background-color: var(--information-500);
  word-wrap: break-word;
  justify-content: center;
  vertical-align: middle;
  color: #fff;
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 0.8rem;
  font-family: 'Open Sans Bold';
  font-size: 1.2rem;
  line-height: 1.6rem;
  @media screen and ${TAB} {
    padding: 1rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

export const CardDescriptionWrapper = styled.div`
  margin: 0;
  padding: 1.6rem 0 0 1.6rem;
`;

export const ListDetailsWrapper = styled.div`
  padding: 0.8rem 1.6rem 0 1.6rem;
`;

export const DescriptionContentWrapper = styled((props) => <Description {...props}/>)`
  color: var(--neutrals-500);
`;
