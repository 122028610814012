import { Theme } from '@sweb-front/styles';
import { FocusEventHandler, MouseEventHandler, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  CrossCircleWrapper,
  ErrorMessageWrapper,
  StyledContainer,
  StyledDesktopSelectIconContainer,
  StyledDesktopSelectLabel,
  StyledLabel,
  StyledOption,
  StyledOptions,
  StyledSelectComponent,
  StyledSelectContainer,
  StyledSelectContent,
} from './Style';

type SelectOption = {
  value: string;
  label: string;
};

export type DesktopSelectProps = {
  id?: string;
  label?: string;
  value?: string;
  options: SelectOption[];
  placeholder?: string;
  errorMessage?: string;
  onChange: (value: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  touched?: boolean;
  onClose?: () => void;
};

const DesktopSelect = ({
  id,
  options = [],
  label,
  placeholder,
  onChange,
  onClose,
  errorMessage,
  value,
  touched = false,
}: DesktopSelectProps) => {
  const theme = useTheme() as Theme;
  const [opened, setOpened] = useState(false);
  const selectedLabel = options.find(({ value: v }) => value === v)?.label;
  const isDirty = touched || value !== undefined;
  const isError = errorMessage !== undefined && isDirty;
  const isValid = errorMessage === undefined && isDirty;

  const toogleMenuOpened = () => setOpened((v) => !v);
  const onSelectClick: MouseEventHandler = () => toogleMenuOpened();

  const onOptionClick = (e: MouseEvent, v: string) => {
    e.preventDefault();
    if (value === v) {
      toogleMenuOpened();
      return;
    }
    onChange(v);
    if (onChange) {
      onChange(v);
    }
    toogleMenuOpened();
  };

  return (
    <StyledContainer
      role="listbox"
      onBlur={() => {
        setOpened(false);
        onClose();
      }}
      id={id}
      data-testid={id}
      isError={isError}
    >
      <StyledLabel>{label}</StyledLabel>
      <StyledSelectContainer>
        <StyledSelectComponent
          opened={opened}
          tabIndex={0}
          onClick={onSelectClick}
          type="button"
          isError={isError}
          isValid={isValid}
          isDirty={isDirty}
        >
          <StyledSelectContent>
            <StyledDesktopSelectLabel>
              {selectedLabel ?? placeholder}
            </StyledDesktopSelectLabel>
            <StyledDesktopSelectIconContainer>
              {isValid ? (
                <CrossCircleWrapper fill={theme.vendor.colors.primary} />
              ) : (
                <></>
              )}
            </StyledDesktopSelectIconContainer>
          </StyledSelectContent>
        </StyledSelectComponent>
        <StyledOptions opened={opened} id={`options_${id}`}>
          {options.map((option, index) => (
            <StyledOption
              key={`${option}${index + 1}`}
              value={option.value}
              onMouseDown={(e) =>
                onOptionClick(e as unknown as MouseEvent, option.value)
              }
            >
              {option.label}
            </StyledOption>
          ))}
        </StyledOptions>
      </StyledSelectContainer>
      {isError && (
        <ErrorMessageWrapper id="errorMessage">
          {errorMessage}
        </ErrorMessageWrapper>
      )}
    </StyledContainer>
  );
};
export default DesktopSelect;
