import {
  WrapperRadioLabel,
  WrapperRadioButton,
  StyledRadioButton,
  StyledCheckmark,
  StyledRadioLabel,
} from './styles';

export interface IRadioButtonProps {
  children: React.ReactNode;
  id: string;
  name: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e) => void;
  className?: string;
  isValid?: boolean;
  readOnly?: boolean;
}

const RadioButton = ({
  id,
  name,
  value,
  checked = false,
  disabled = false,
  children,
  onChange,
  className = '',
  isValid = true,
  readOnly,
}: IRadioButtonProps): React.ReactElement => (
  <WrapperRadioLabel
    checked={checked}
    disabled={disabled}
    $isClickable={!!onChange}
    className={className}
  >
    <WrapperRadioButton>
      <StyledRadioButton
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
      />
      <StyledCheckmark
        checked={checked}
        disabled={disabled}
        $isValid={isValid}
      />
    </WrapperRadioButton>
    <StyledRadioLabel disabled={disabled}>{children}</StyledRadioLabel>
  </WrapperRadioLabel>
);

export default RadioButton;
