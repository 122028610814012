import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState as IParameterState } from './parametersTypes';
import { IParametersArgs } from '@sweb-front/types';

const getInitialState = () => {
  return { state: {}, params: {} } as {
    state: IParameterState;
    params?: IParametersArgs;
  };
};

export const parametersSlice = createSlice({
  name: 'parametersSlice',
  initialState: getInitialState(),
  reducers: {
    clear(parametersState) {
      parametersState.state = getInitialState().state;
    },
    fetchWay(parametersState, data: PayloadAction<IParameterState>) {
      parametersState.state = data.payload;
    },
    setParams(parametersState, data: PayloadAction<IParametersArgs>) {
      parametersState.params = data.payload;
    },
  },
});

export const selectDisplays = (parametersState: {
  parameters: {
    state: IParameterState;
  };
}) => parametersState.parameters.state.displays;

export const selectParams = (parametersState: {
  parameters: {
    state: IParameterState;
    params?: IParametersArgs;
  };
}) => parametersState.parameters.params;

export const selectTheme = (parametersState: {
  parameters: {
    state: IParameterState;
  };
}) => parametersState.parameters.state.wayDesign;

export type { IParameterState };
export const { clear, fetchWay, setParams } = parametersSlice.actions;
export default parametersSlice.reducer;
