export type BackIconProps = {
  fill?: string;
};

const BackIcon = ({ fill = 'currentColor' }: BackIconProps) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7.732 12l9.058-8.809a.684.684 0 000-.987.732.732 0 00-1.015 0L6.21 11.507a.684.684 0 000 .986l9.565 9.303a.732.732 0 001.015 0 .684.684 0 000-.987L7.732 12z"
      />
    </svg>
  );
};

export default BackIcon;
