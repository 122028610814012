const SvgChevronRight: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.268 12L7.21 3.191a.684.684 0 010-.987.732.732 0 011.015 0l9.565 9.303c.28.272.28.714 0 .986l-9.565 9.303a.732.732 0 01-1.015 0 .684.684 0 010-.987L16.268 12z"
    />
  </svg>
);

export default SvgChevronRight;
