import { IStep } from '@sweb-front/store';
import { IParametersArgs } from '@sweb-front/types';

export const mockFetchWayData = {
  data: {
    wayCd: 'VATRIBPNCM1',
    shopSign: 'PROBIKESHOP PRESTO',
    pjOpt: false,
    dataOpt: false,
    insuranceOpt: true,
    fullApiOpt: false,
    displays: [
      {
        displayCd: 'KYC',
        oderNumber: 1,
        fields:
          'KYCBLOC1,KYCBLOC2,KYCBLOC3,KYCNOMNAI,KYCPAYSNAI,KYCDPTNAI,KYCVILNAI,KYCDTNAI,KYCNATIO',
      },
      {
        displayCd: 'SOLVA',
        oderNumber: 2,
        fields: 'SOLVACT,SOLVREV,SOLVCHRGS',
      },
      {
        displayCd: 'ASSU',
        oderNumber: 3,
        fields: null,
      },
      {
        displayCd: 'CB',
        oderNumber: 4,
        fields: null,
      },
      {
        displayCd: 'UPPJ',
        oderNumber: 5,
        fields: null,
      },
      {
        displayCd: 'ESIGN',
        oderNumber: 6,
        fields: null,
      },
    ],
  },
};

export const mockOpportunityAnnul = {
  data: { ...mockFetchWayData.data, statusCd: 'ANUL' },
};
export const mockNavigation = {
  lastAppUrl: '/options',
  steps: [],
  typeWayCd: 'VATRIBPNCM1',
};

export const person = {
  address: {
    streetAddress: '14 rue Jean Jaures Beaujoire',
    additionalAddress: '44000',
    city: 'Cite',
    countryCd: 'US',
    useBo: true,
    zipCd: '123',
  },
  cellPhone: {
    phoneId: '1234598',
    phoneNb: '0734567345',
    useBo: true,
  },
  email: {
    address: '14 rue Jean Jaures Beaujoire',
    emailId: '12345',
    useBo: true,
  },
  personalInformation: {
    birthCity: 'PARIS',
    birthCityInseeCd: 'FRA',
    birthCountryIsoCd: 'FR',
    birthDepartment: 'FR',
    birthDt: '12/12/1987',
    birthName: 'John',
    firstName: 'Doe',
    name: 'John',
    nationalityIsoCd: 'FR',
    title: 'MR',
  },
  personId: '12323454546',
};

export const mockOpportunityPP = {
  data: {
    planB: false,
    opportunityIdExt:
      'KypCgmXPZ66Za5i_yuofDfUvrSCSrHjybEnYDB5CcOX0ZUdk3QZUVrJjDw==',
    testModeBo: false,
    distributionChannelCd: 'WM',
    statusCd: 'ENCO',
    distributor: {
      distributorNb: '1000899856',
      companyCd: '100',
      countryCd: 'FRA',
    },
    persons: [person],
    project: {
      loan: {
        loanId: null,
        financialProduct: {
          productTypeCd: null,
          productCd: null,
          productAlphaCd: null,
          productCategoryCd: null,
        },
        financialDetails: {
          overdraftAmt: 2000.99,
          term: 10,
          tncRt: null,
          monthlyPaymentWithoutInsuranceAmt: null,
        },
        insurances: null,
        commitmentLevel: null,
      },
      goods: [
        {
          goodCd: '662',
          goodPriceAmt: 2000.99,
        },
      ],
    },
    offers: [
      {
        offerId: '73a780e7-747f-44bc-acf1-38aa4b06bd74',
        loans: [
          {
            loanId: '3cb96efe-b713-40fa-bdbe-ed67a9b5000a',
            typeCd: 'PRI',
            financialProduct: {
              productTypeCd: 'C',
              productCd: '0001',
              productAlphaCd: 'VAT',
              productCategoryCd: null,
            },
            financialDetails: {
              overdraftAmt: 2000.99,
              term: 10,
              tncRt: 7.63,
              monthlyPaymentWithoutInsuranceAmt: 200.1,
            },
            insurances: null,
            commitmentLevel: null,
          },
        ],
      },
    ],
    recap: {
      products: [
        {
          tradeIn: {
            total: 400.0,
            nbOfMonths: 6,
            amountPerMonth: 76.67,
            totalToPay: 460,
            interestsRate: 0,
            interests: 0,
          },
        },
      ],
    },
    routingContexts: [
      {
        httpVerb: 'GET',
        contextUse: 'CANCEL',
        url: 'https://www.probikeshop.fr/apps/store/financing_CANCEL?application=P_APP_2211000001',
      },
      {
        httpVerb: 'GET',
        contextUse: 'DONE',
        url: 'https://www.probikeshop.fr/apps/store/financing_LATER?application=P_APP_2211000001',
      },
      {
        httpVerb: 'GET',
        contextUse: 'ERROR',
        url: 'https://www.probikeshop.fr/apps/store/financing_ERROR?application=P_APP_2211000001',
      },
      {
        httpVerb: 'GET',
        contextUse: 'KO',
        url: 'https://www.probikeshop.fr/apps/store/financing_DONE?application=P_APP_2211000001',
      },
      {
        httpVerb: 'GET',
        contextUse: 'LATER',
        url: 'https://www.probikeshop.fr/apps/store/financing_KO?application=P_APP_2211000001',
      },
    ],
  },
} as any;

export const mockOpportunity = { ...mockOpportunityPP };

export const regexp = new RegExp('/to/(.*)', 'i');

export const mockSteps: IStep[] = [
  {
    id: 1,
    step: 1,
    stepTitle: 'Vos informations',
    isSubStep: false,
    label: 'Informations',
    paths: ['/informations', '/'],
    components: 'Information',
  },
  {
    id: 2,
    step: 2,
    stepTitle: 'Vos revenus et vos charges',
    isSubStep: true,
    label: 'incomesAndExpenses.stepTitle',
    paths: ['/revenus-charges'],
    components: 'IncomesExpenses',
  },
  {
    id: 3,
    step: 3,
    stepTitle: 'Votre Assurance Emprunteur facultative',
    isSubStep: false,
    label: 'Options',
    paths: ['/options'],
    components: 'Options',
  },
  {
    id: 4,
    step: 4,
    stepTitle: 'Vos informations bancaires',
    isSubStep: false,
    label: 'Coordonnées CB',
    paths: ['/informations-bancaires'],
    components: 'CardInformation',
  },
  {
    id: 5,
    step: 5,
    stepTitle: "Votre justificatif d'identité",
    isSubStep: false,
    label: 'Pièces justificatives',
    paths: ['/pieces-justificatives'],
    components: 'ProfessionalSituation',
  },
  {
    id: 6,
    step: 6,
    stepTitle: 'Signature éléctronique',
    isSubStep: false,
    label: 'E-Signature',
    paths: ['/e-signatures'],
    components: 'ESignature',
  },
] as IStep[];

export const parametrageArgs: IParametersArgs = {
  distributorNb: '19008985',
  overdraft: '2000.99',
  productType: 'C',
  bag: 'MONO',
  knowCustomer: true,
  existRib: false,
  easyPayment: false,
  channel: 'WEB',
};

export const tradeIngoodCd: string = '628';
