import { axiosInstance } from '@sweb-front/services';
import { ISelectCombo } from '@sweb-front/types';
import { concantenedObjectWithComma, isStringEmpty } from '@sweb-front/utils';
import { formatNumber } from '@vat/utils';
import { AxiosResponse } from 'axios';
import { GenderInputDS } from '@sweb-front/components';
import { RefObject } from 'react';
import { sha256 } from 'js-sha256';
import { IOpportunityState } from '@sweb-front/store';

export const pad = (s: number) => {
  return s < 10 ? `0${s}` : s;
};

export const getMatchedItem = (
  value: string,
  propertyToMatch: string,
  items: ISelectCombo[]
): ISelectCombo | undefined => {
  if (!value || value === '' || value === null) {
    return undefined;
  }

  return (items ?? []).find(
    (item) =>
      item[propertyToMatch].toUpperCase() === (value ?? '').toUpperCase()
  );
};

export const scrollToFirstInvalidElement = (
  ref?: RefObject<HTMLElement | GenderInputDS>
) => {
  const firstElement = Array.from(ref?.current?.children ?? []).find(
    (element) => element.getAttribute('aria-invalid') === 'true'
  );

  if (firstElement) {
    window.scroll({
      top: window.scrollY + firstElement.getBoundingClientRect().top,
    });
  }
};

export const isAutocompleteInvalid = (
  value: string | undefined,
  record: Record<string, string>
) => {
  return (
    isStringEmpty(value) ||
    (!isStringEmpty(value) &&
      value !==
        concantenedObjectWithComma({
          localite: record?.localite,
          codePostal: record?.codePostal,
          pays: record?.pays,
        }))
  );
};

export const isInvalidBirthName = (value: string | null | undefined) => {
  return (value ?? '')?.trim().length < 2 || (value ?? '')?.trim().length > 30;
};

export const customValidityPattern = (pattern: string, errorMessage: string) =>
  JSON.stringify([
    {
      regexp: pattern,
      errorMessage,
    },
  ]);

export const fetchData = async (
  url: string,
  urlParams: Record<string, unknown>,
  callback?: (args: ISelectCombo[]) => void,
  errorCallback?: (err) => void
) => {
  try {
    const response = await axiosInstance().get(url, urlParams);
    callback?.(response.data);
    return response.data;
  } catch (err) {
    return errorCallback?.(err);
  }
};

export const postData = async (
  url: string,
  params: unknown,
  callback?: (res: AxiosResponse<unknown>) => void,
  errorCallback?: (res: number) => void
) => {
  try {
    const response = await axiosInstance().post(url, params);
    callback?.(response);
    return response;
  } catch (errCode) {
    return errorCallback?.(errCode as number);
  }
};

export const roundValue = (input: string) => {
  let res: string | undefined = input;
  const [entiere, decimal, error] = input.replace(' ', '').split(',');

  if (error) {
    return res;
  }
  if (decimal && parseInt(decimal, 10) < 50 && /^[0-9]{1,2}$/.test(decimal)) {
    res = formatNumber(`${parseInt(entiere, 10)}`);
  }
  if (decimal && parseInt(decimal, 10) >= 50 && /^[0-9]{1,2}$/.test(decimal)) {
    if (['99999', '99 999'].includes(entiere)) {
      res = formatNumber(entiere);
    } else {
      res = formatNumber(`${parseInt(entiere, 10) + 1}`);
    }
  }

  return res;
};

export const isInputValidToPattern = (
  input: string | null | undefined,
  pattern: string
) => {
  const regexp = new RegExp(pattern);
  return regexp.test(input ?? '');
};

export const arrayRange = (start: number, stop: number, step: number) => {
  if (start > stop || start < 0 || stop < 0) {
    return [];
  }
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );
};

export const formatToBnpString = (
  value: string | undefined,
  maxChar: number
) => {
  const regexp = new RegExp("[^a-zA-Zzàâçéèêîôùû'´ëä -]", 'gi');
  const res = (value ?? '').replace(regexp, '');
  return res.substring(0, maxChar);
};

export const sanitizeStringForNumber = (str: string, maxLength?: number) =>
  str.replace(/[^0-9.]/g, '').substring(0, maxLength || undefined);

export const formatNumberWithComma = (nbr?: number) =>
  nbr
    ? nbr.toLocaleString('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : 0;

export const dateOf16 = (dateString) => {
  const dob = new Date(dateString);
  return new Date(dob.getFullYear() + 16, dob.getMonth(), dob.getDate());
};

export const setIdHashed = (opportunity: IOpportunityState) => {
  const stringToHash: string = removeSpecialCharacters(
    replaceAccents(
      opportunity.person.personalInformation.firstName +
        opportunity.person.personalInformation.name +
        opportunity.opportunityIdExt
    )
  ).toLowerCase();
  return sha256(stringToHash);
};

export function replaceAccents(string: string) {
  if (string.search(/[\xC0-\xFF]/g) > -1) {
    string = string
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF0]/g, 'd')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFD\xFF]/g, 'y');
  }

  return string;
}

export function removeSpecialCharacters(string: string) {
  return string.replace(/[^0-9a-zA-Z]/g, '');
}

export function scrollToErrorMessage() {
  const firstErrorElement = document.querySelector('#errorMessage');
  const parentElement = firstErrorElement?.parentElement;
  if (parentElement) {
    parentElement.scrollIntoView({ behavior: 'smooth' });
  }
}
