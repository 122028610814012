import { ReactNode, useEffect, useRef, useState } from 'react';
import { ScrollContainerDiv, ScrollIndicator } from './style';
import { CSSTransition } from 'react-transition-group';

export type ScrollContainerProps = {
  isScrollable?: boolean;
  children?: ReactNode;
  id?: string;
  className?: string;
};

const ScrollContainer = ({
  isScrollable,
  children,
  id,
  className,
}: ScrollContainerProps) => {
  const container = useRef(null);
  const [displayed, setDisplayed] = useState(true);
  const scrollIndicator = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (
        container.current.scrollHeight - container.current.scrollTop ===
        container.current.clientHeight
      ) {
        setDisplayed(false);
      } else {
        setDisplayed(true);
      }
    };
    if (isScrollable) {
      container.current.addEventListener('scroll', handleScroll);
    } else setDisplayed(false);
    return () => {
      if (isScrollable)
        container.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ScrollContainerDiv className={className} ref={container} id={id}>
      {children}
      <CSSTransition
        in={displayed}
        nodeRef={scrollIndicator}
        timeout={275}
        classNames="scroll-indicator"
      >
        <ScrollIndicator ref={scrollIndicator} />
      </CSSTransition>
    </ScrollContainerDiv>
  );
};

export default ScrollContainer;
