import { useTranslation } from 'react-i18next';
import GenericInsuranceContainer from '../../GenericInsuranceContainer';
import { IInsuranceCmpProps } from '../OptionalInsuranceCard';
import useConstants from '../useConstants';

const InsuranceDim = ({
  onSelectOption,
  onReturnClick,
  value,
  priceDetails,
}: IInsuranceCmpProps) => {
  const { t } = useTranslation();

  const { documents, offerDetails, options } = useConstants(
    priceDetails,
    false,
    ['DIMC']
  );

  return (
    <GenericInsuranceContainer
      offerTitle={t('insurance.details.dimTitle')}
      offerDetails={offerDetails}
      options={options}
      documents={documents}
      value={value}
      onReturnClick={onReturnClick}
      onSelectOption={() => onSelectOption(value)}
    />
  );
};

export default InsuranceDim;
