/*
 * Messages modal d'erreur
 *
 *
 */

export const TITLE = 'Une erreur est survenue';
export const REFUSE_CONTACT =
  "Merci de contacter votre centre de relation clientèle ou retourner à l'accueil.";
export const REQUESTID_HANDLER = 'code erreur : ';
export const BACK_BUTTON = "Retourner à l'accueil";
