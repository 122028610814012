import { NumericList } from '@sweb-front/components';
import { useTranslation } from 'react-i18next';
import { selectNavigation, useAppSelector } from '@sweb-front/store';
import { WaitingIconPage, Button } from '@vat/components';
import {
  CustomizedValidateButtonWrapper,
  DocumentInPersonalAccountParagraph,
  NumericListWrapper,
  Paragraph,
  PrintAndDownloadParagraph,
  PrintAndDownloadParagraphBold,
  SectionWrapper,
  SeparatorWrapper,
  SVGIconWrapper,
  TitleCmp,
} from './styles';
import useWaitingResponse from './useWaitingResponse';

const WaitingResponse = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigation = useAppSelector(selectNavigation);
  const { onContractClick, deliveryMode } = useWaitingResponse(
    opportunity,
    parameters,
    navigation
  );
  const { wayType } = parameters;

  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <WaitingIconPage />
      </SVGIconWrapper>
      <TitleCmp>{t('responses.waitingResponse.congratulations')}</TitleCmp>
      <Paragraph>{t('responses.waitingResponse.documentReceived')}</Paragraph>
      <Paragraph marginTop="3.2rem">
        {t('responses.waitingResponse.nextStep')}
      </Paragraph>
      <NumericListWrapper>
        <SeparatorWrapper>
          <NumericList
            marginTopTitle="0rem"
            marginTopDescription="0.8rem"
            backgroundColor="#F2F3F3"
            listIndex="1"
            title={t('responses.waitingResponse.step1.title')}
            description={t('responses.waitingResponse.step1.description')}
          />
        </SeparatorWrapper>
        <SeparatorWrapper>
          <NumericList
            marginTopTitle="0rem"
            marginTopDescription="0.8rem"
            backgroundColor="#F2F3F3"
            listIndex="2"
            title={t('responses.waitingResponse.step2.title')}
            description={`${t(
              'responses.waitingResponse.step2.description'
            )} ${t(
              `responses.waitingResponse.step2.timeOfResponse.${deliveryMode}`
            )}`}
          />
        </SeparatorWrapper>
        <SeparatorWrapper>
          <NumericList
            marginTopTitle="0rem"
            marginTopDescription="0.8rem"
            backgroundColor="#F2F3F3"
            listIndex="3"
            title={t('responses.waitingResponse.step3.title')}
            description={t('responses.waitingResponse.step3.description', {
              enseigne: parameters.shopSign,
            })}
          />
        </SeparatorWrapper>
      </NumericListWrapper>
      <PrintAndDownloadParagraph marginTop="3.2rem">
        {t('responses.waitingResponse.downloadDocuments1')}
        <PrintAndDownloadParagraphBold>
          {t('responses.waitingResponse.downloadDocumentsBold')}
        </PrintAndDownloadParagraphBold>
        {t('responses.waitingResponse.downloadDocuments2')}
      </PrintAndDownloadParagraph>
      <CustomizedValidateButtonWrapper>
        <Button
          id="contract-download-button-waiting-response"
          level="primary"
          type="button"
          onClick={onContractClick}
        >
          {t('responses.buttons.contractArea')}
        </Button>
      </CustomizedValidateButtonWrapper>
      <DocumentInPersonalAccountParagraph marginTop="3.2rem">
        {t('responses.waitingResponse.documentInPersonalAccount')}
      </DocumentInPersonalAccountParagraph>
      {wayType && (
        <Paragraph marginTop="1.6rem">
          {t(`responses.waitingResponse.${wayType}.getContractCopy`)}
        </Paragraph>
      )}
    </SectionWrapper>
  );
};

export default WaitingResponse;
