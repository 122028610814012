import styled from 'styled-components';
import { DESKTOP, TAB } from '../../utils/css-declinaisons';
import { CloseIcon } from '../Icons';

export const Background = styled.div<{
  isVisible: boolean;
}>`
  background-color: rgba(220, 222, 224, 0.41);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  z-index: 100;
  overflow: auto;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div<{
  isFullScreen?: boolean;
}>`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: inherit;
  @media screen and ${TAB} {
    width: ${({ isFullScreen }) =>
      isFullScreen ? 'calc(100% - 30.6rem)' : 'none'};
  }
  padding: 1.6rem 0;
  @media screen and ${DESKTOP} {
    width: 49.6rem;
  }
`;

export const StyledWrapper = styled.div<{
  isMobile?: boolean;
  isFullScreen?: boolean;
  lastYPosition?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: ${({ isFullScreen }) => (isFullScreen ? 'none' : '.4rem')};
  background: ${({ theme }) => theme.variables.colors.chartColors.white};
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  flex-direction: column;
  margin: 0;
  width: ${({ isFullScreen }) =>
    isFullScreen ? '100vw' : 'calc(100% - 3.2rem)'};
  height: ${({ isFullScreen }) => (isFullScreen ? '100vh' : 'auto')};
  box-shadow: ${({ theme }) => theme.variables.colors.chartColors.grey_200} 0px
    2px 10px 0px;
  @media screen and ${TAB} {
    width: ${({ isFullScreen }) => (isFullScreen ? '100vw' : '49.6rem')};
    margin: 0;
  }
  @media screen and ${DESKTOP} {
    margin: 0;
    width: ${({ isFullScreen }) => (isFullScreen ? '100vw' : '49.6rem;')};
    ${({ isFullScreen }) => isFullScreen && 'align-items: center;'};
  }
`;

export const ButtonClose = styled.button<{
  isDisabled?: boolean;
}>`
  border: none;
  background-color: transparent;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const ButtonWrapper = styled.div<{
  isFullScreen?: boolean;
}>`
  self-align: end;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem 1.6rem 0 0;
  @media screen and ${DESKTOP} {
    ${({ isFullScreen }) => isFullScreen && 'margin-left: 56rem;'}
  }
`;

export const ButtonPositionWrapper = styled.div<{
  isFullScreen?: boolean;
}>`
  right: 0;
  z-index: 50;
  background-color: ${({ theme }) => theme.variables.colors.chartColors.white};
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
  font-family: 'Open Sans Bold';
  border: 0.1rem solid ${({ theme }) => theme.vendor.colors.light};
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: ${({ theme }) => theme.variables.colors.chartColors.grey_200} 0px
    2px 10px 0px;
  &:hover {
    box-shadow: ${({ theme }) => theme.variables.colors.chartColors.grey_400}
      0px 2px 10px 0px;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)<{ isDisabled?: boolean }>`
  color: ${({ theme, isDisabled }) =>
    isDisabled
      ? theme.variables.colors.chartColors.grey_100
      : theme.variables.colors.chartColors.dark_700};
  width: 2.4rem;
`;
