import { InsuranceResponse } from '@vat/types';
import { AxiosResponse } from 'axios';
import axiosInstance from './httpCommon';

const getInsurance = async (
  loanId: string,
  onFailure: (error: { message: string; data: unknown }) => void
): Promise<InsuranceResponse | void> => {
  return axiosInstance()
    .get(`/vendors/opportunities/v1/insurance?loanId=${loanId}`)
    .then((response: AxiosResponse<InsuranceResponse>) => {
      const insurances = response.data;
      return Promise.resolve(insurances);
    })
    .catch((err) => {
      onFailure({
        message: err?.response?.message ?? `Error encountered. ${err}`,
        data: err?.response?.data,
      });
    });
};

export default getInsurance;
