import { selectConfiguration, useAppSelector } from '@sweb-front/store';
import { useRedirection, useTracker } from '@sweb-front/hooks';
import { GenericError } from '@sweb-front/components';

const ErrorComponent = (): React.ReactElement => {
  const { redirectToPopHome } = useRedirection();
  const trackEvent = useTracker();
  const configuration = useAppSelector(selectConfiguration);

  return (
    <GenericError
      requestId={configuration?.requestId}
      redirectToPopHome={redirectToPopHome}
      trackEvent={trackEvent}
    />
  );
};

export default ErrorComponent;
