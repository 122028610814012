import { useUrlWebMarchand } from '@sweb-front/hooks';
import { isStringEmpty } from '@sweb-front/utils';
import { ERRORPAGE, REFUSEDREPONSE, WAITINGRESPONSE } from '@vat/configuration';
import { useState, useCallback, useEffect } from 'react';

const useRedirectionComponent = (
  delay: number,
  isErrorOccuredInPage: boolean,
  currentPage = ''
) => {
  const [timer, setTimer] = useState(0);
  const { getUrlDone, getUrlError, getUrlKO, getUrlLater } =
    useUrlWebMarchand();
  const onBackToWebMerchant = useCallback(() => {
    let url = getUrlDone();
    if (isErrorOccuredInPage || currentPage === ERRORPAGE) {
      url = getUrlError();
    } else if (currentPage === REFUSEDREPONSE) {
      url = getUrlKO();
    } else if (currentPage === WAITINGRESPONSE) {
      url = getUrlLater();
    }
    if (!isStringEmpty(url)) {
      window.location.href = url;
    }
  }, []);

  useEffect(() => {
    const timeout = setInterval(() => {
      setTimer((prv) => prv + 100);
    }, 100);
    if (timer === delay * 1000) {
      onBackToWebMerchant();
      clearInterval(timeout);
    }
    return () => {
      clearInterval(timeout);
    };
  }, [timer]);

  const progressPercentage = (timer / (delay * 1000)) * 100;

  return {
    progressPercentage,
    onBackToWebMerchant,
  };
};

export default useRedirectionComponent;
