import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseNotificationCardDS extends HTMLElement {
  severity?: string;
  icon?: string;
  constructor() {
    super();
  }
}

export const BaseNotificationCard = createComponent(React, 'base-notification-card', BaseNotificationCardDS, {});
