import styled from 'styled-components';

export interface ITextBoldProps {
  text: string[];
  textBold: string;
}

const common = `
color: var(--neutrals-500);
font-family: 'Open Sans';
line-height: 2.4rem;
font-size: 1.6rem;`;

const TextStyled = styled.span`
  ${common}
`;
const TextBoldStyled = styled.span`
  ${common}
  font-family: 'Open Sans Bold';
`;

const TextWrapper = styled.div`
  width: 95%;
`;

const TextWithBold: React.FC<ITextBoldProps> = ({
  text,
  textBold,
}: ITextBoldProps) => {
  return (
    <TextWrapper>
      <TextStyled>{text[0]}</TextStyled>
      <TextBoldStyled> {textBold} </TextBoldStyled>
      <TextStyled> {text[1]}</TextStyled>
    </TextWrapper>
  );
};

export default TextWithBold;
