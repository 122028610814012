import { DESKTOP, TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const HousingEntryDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0;
  @media screen and ${TAB} {
    max-width: 30rem;
  }
`;

export const HousingStatusChoiceWrapper = styled.div`
  text-align: center;

  & selected-button {
    margin: 0.4rem;
    font-family: 'Open Sans';
    font-size: 1.6rem;
    width: 47%;
    max-width: 20rem;
    height: 5.6rem;
    padding: 2rem 0.8rem;
    color: var(--neutrals-500);
  }

  @media screen and ${TAB} {
    max-width: 50rem;
    & selected-button {
      margin: 0.8rem 1.8rem;
      width: 14rem;
      height: 5.6rem;
    }
    & selected-button:nth-child(1),
    selected-button:nth-child(3n),
    selected-button:nth-child(4) {
      margin-left: 0.8rem;
      margin-right: 0;
    }
  }
  @media screen and ${DESKTOP} {
    & selected-button {
      margin: 0.8rem 2.7rem;
    }
    & selected-button:nth-child(1),
    selected-button:nth-child(3n),
    selected-button:nth-child(4) {
      margin-left: 0.8rem;
      margin-right: 0;
    }
  }
`;
