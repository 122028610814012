const createHTMLElement = (
  type: string,
  attributes: any,
  parent?: HTMLElement
) => {
  const element = document.createElement(type);
  Object.entries(attributes).forEach(([attributeName, value]) => {
    element.setAttribute(attributeName, value as any);
  });

  if (parent) {
    parent.appendChild(element);
  } else {
    document.body.append(element);
  }

  return element;
};

export default createHTMLElement;
