import {
  AsterikNotes,
  ButtonLink,
  CardWithHeader,
  Documents,
  GenderInput,
  Modal,
  Section,
} from '@sweb-front/components';
import {
  isOpenBankingResultOk,
  selectNavigation,
  useAppSelector,
} from '@sweb-front/store';
import { ButtonWrapper, StyledButtonLoader } from '@sweb-front/styles';
import { isStringEmpty } from '@sweb-front/utils';
import { InsuranceDeath, InsuranceDim, InsuranceDimc } from '@vat/containers';
import { StyledValidateButtonWrapper, useAppOptions } from '@vat/utils';
import { GenderInputDS } from '@sweb-front/components';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  AsteriskWrapper,
  CustomButtonActionWrapper,
  CustomStyledBackButtonWrapper,
  InputWrapper,
  ModalOptionWrapper,
  StyledLoader,
  AssuranceAdhesion,
  DocumentsWrapper,
  ErrorMessageWrapper,
} from './styles';
import useOptions from './useOptions';
import { IPriceDetails } from 'src/containers/insuranceDetails/items/OptionalInsuranceCard';
import { CookiesCheckbox } from '@vat/components';

export const getPriceDetailsByOption = (
  option: string,
  options = [] as IPriceDetails[]
): IPriceDetails => {
  return (
    options.find((detail) => detail.assuKey === option) ?? ({} as IPriceDetails)
  );
};

const SANSASSURANCE = 'Sans assurance';
const AERRNUMBER = '08 20 22 61 02';

const Options = () => {
  const assuranceState = useAppSelector((state) => state.assurance.state);
  const opportunityState = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigation = useAppSelector(selectNavigation);
  const isObOk = isOpenBankingResultOk(navigation);
  const { t } = useTranslation();
  const { setAppOptions } = useAppOptions();
  const assuRef = useRef<GenderInputDS>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const {
    assurances,
    isShown,
    detailToShow,
    priceDetails,
    isActionLoading,
    values,
    isValidButtonDisabled,
    showConsentmentError,
    isPageLoading,
    onChangeConsentement,
    onOptionChange,
    onOpenInsuranceDetails,
    onSelectOption,
    onToogleModal,
    submitClick,
    onStepBack,
  } = useOptions(opportunityState, parameters.wayCd, formRef, assuRef);

  useEffect(() => {
    setAppOptions({
      isMounted: assurances.length > 0 && !isPageLoading,
    });
  }, [assurances, isPageLoading]);

  return assurances.length > 0 && !isPageLoading ? (
    <Section
      title={t('insurance.title')}
      description={t('insurance.description')}
      level={2}
    >
      <form ref={formRef}>
        <InputWrapper id="option" aria-invalid={isStringEmpty(values.selected)}>
          <GenderInput
            id="input-option"
            value={values.selected}
            error-message={t('incomesAndExpenses.form.errors.option')}
            ref={assuRef}
          >
            {assurances.map((insurance, index) => (
              <CardWithHeader
                key={`${insurance.categoryCd}${index + 1}`}
                cardKey={insurance?.optionCd ?? 'NO_ASSU'}
                title={insurance.optionCd}
                subtitle={
                  insurance.optionCd
                    ? t('insurance.form.subNameWithAstrick', {
                        subName: insurance.optionCd,
                      })
                    : undefined
                }
                description={
                  insurance?.optionCd !== SANSASSURANCE
                    ? t('insurance.descriptionOption')
                    : t('insurance.noAssurance')
                }
                price={insurance?.insuranceMonthlyPaymentAmt?.toString()}
                linkText={t('insurance.detailsOption')}
                isRecommended={index === 0}
                recommandedTitle={
                  index === 0 ? t('insurance.form.recommendedOffre') : ''
                }
                onSelectedChange={onOptionChange}
                selected={values.selected === insurance?.optionCd}
                onLinkClick={onOpenInsuranceDetails}
              />
            ))}
          </GenderInput>
        </InputWrapper>
      </form>
      <DocumentsWrapper>
        <Documents
          title={t('insurance.form.documentsTitle')}
          files={assuranceState?.files}
        />
      </DocumentsWrapper>

      {!isStringEmpty(values.selected) && (
        <>
          {values.selected !== SANSASSURANCE && (
            <>
              <InputWrapper>
                <CookiesCheckbox
                  id="consentAssu"
                  name="consentAssu"
                  label={t('insurance.adhesion.adhesionconsentAssu')}
                  state={!!values.consentAssu}
                  onChange={(val) => {
                    onChangeConsentement('consentAssu', val);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <CookiesCheckbox
                  id="consent"
                  name="consent"
                  label={t('insurance.adhesion.adhesionConsent')}
                  state={!!values.consent}
                  onChange={(val) => {
                    onChangeConsentement('consent', val);
                  }}
                />
              </InputWrapper>
            </>
          )}
          {values.selected === SANSASSURANCE && (
            <>
              <InputWrapper>
                <CookiesCheckbox
                  id="nonConsentAssu"
                  name="nonConsentAssu"
                  label={t('insurance.adhesion.adhesionconsentAssu')}
                  state={!!values.nonConsentAssu}
                  onChange={(val) => {
                    onChangeConsentement('nonConsentAssu', val);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <CookiesCheckbox
                  id="nonConsent"
                  name="nonConsent"
                  labelWithElement={
                    <Trans
                      i18nKey="insurance.adhesion.adhesioNonConsent"
                      components={{
                        aeraslink: (
                          <a className="assurance" href="#assurance-adhesion">
                            AERAS
                          </a>
                        ),
                      }}
                    />
                  }
                  state={!!values.nonConsent}
                  onChange={(val) => {
                    onChangeConsentement('nonConsent', val);
                  }}
                />
              </InputWrapper>
            </>
          )}
          {showConsentmentError && (
            <ErrorMessageWrapper>
              {t('insurance.error.consentmentDeux')}
            </ErrorMessageWrapper>
          )}
        </>
      )}
      <CustomButtonActionWrapper>
        <StyledValidateButtonWrapper
          $isDisabled={isValidButtonDisabled}
          id="options-validate-button"
          onClick={submitClick}
        >
          {isActionLoading ? (
            <StyledButtonLoader
              isLoading
              isTextInline
              isHideBackground={false}
            />
          ) : (
            t('common.validate')
          )}
        </StyledValidateButtonWrapper>
        {!isObOk && (
          <CustomStyledBackButtonWrapper>
            <ButtonWrapper className="back-button-wrapper">
              <ButtonLink
                label={t('common.back')}
                onClick={onStepBack}
                id="options-back-button"
              />
            </ButtonWrapper>
          </CustomStyledBackButtonWrapper>
        )}
      </CustomButtonActionWrapper>
      <AssuranceAdhesion id="assurance-adhesion">
        <Trans
          i18nKey="insurance.adhesion.explanation.message"
          components={{
            aerasNumber: (
              <a href={`tel:+33${AERRNUMBER.substring(1).replace(/ /gi, '')}`}>
                {AERRNUMBER}
              </a>
            ),
          }}
        />
      </AssuranceAdhesion>
      <AsteriskWrapper>
        <AsterikNotes astrickLetters={assuranceState?.astrickLetters} />
      </AsteriskWrapper>
      <Modal
        isVisible={isShown}
        handleCloseModal={onToogleModal}
        isFullScreen
        isClosable
      >
        <ModalOptionWrapper>
          {detailToShow === 'DIMC' && (
            <InsuranceDimc
              onSelectOption={onSelectOption}
              value="DIMC"
              onReturnClick={onToogleModal}
              priceDetails={getPriceDetailsByOption('DIMC', priceDetails)}
            />
          )}
          {detailToShow === 'DIM' && (
            <InsuranceDim
              onSelectOption={onSelectOption}
              value="DIM"
              onReturnClick={onToogleModal}
              priceDetails={getPriceDetailsByOption('DIM', priceDetails)}
            />
          )}
          {detailToShow === 'D' && (
            <InsuranceDeath
              onSelectOption={onSelectOption}
              value="D"
              onReturnClick={onToogleModal}
              priceDetails={getPriceDetailsByOption('D', priceDetails)}
            />
          )}
        </ModalOptionWrapper>
      </Modal>
    </Section>
  ) : (
    <StyledLoader isLoading isTextInline primaryText={t('common.loading')} />
  );
};

export default Options;
