import styled from 'styled-components';

const CardItemContentWrapper = styled.div`
  display: block;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};

  & div {
    padding: 0;
  }

  & div.title {
    text-transform: capitalize;
    color: #697077;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`;

export const ValueWrapper = styled.div`
  color: var(--neutrals-500);
  font-family: 'Open Sans Bold';
  margin: 0 0 0.4rem 0;
  font-size: 1.4rem;
  line-height: 2rem;
`;

export default CardItemContentWrapper;
