import { NumericInput, Replacement } from '../DesignSystem';

export type NumberInputProps = {
  id: string;
  value?: string;
  onChange: (value?: string) => void;
  errorMessage?: string;
  label: string;
  placeholder: string;
  onBlur?: () => void;
  onFocus?: () => void;
  isInvalid?: boolean;
  replacement: string | Replacement;
  maxlength?: number;
  withReset?: boolean | null;
};

export const NumberInput = ({
  id,
  value,
  onChange,
  errorMessage,
  label,
  placeholder,
  onBlur,
  onFocus,
  isInvalid,
  replacement,
  maxlength,
  withReset = null,
}: NumberInputProps) => {
  const computeOnChange = (e: Event) => {
    const v = (e.target as unknown as { value?: string }).value;

    onChange(v);
    if (onFocus) {
      onFocus();
    }
    if (onBlur) {
      onBlur();
    }
  };
  return (
    <NumericInput
      id={id}
      type="text"
      mobileType="number"
      label={label}
      placeholder={placeholder}
      onLetterChanged={computeOnChange}
      onInputChange={computeOnChange}
      value={value ? String(value) : ''}
      onBlur={onBlur}
      onFocus={onFocus}
      customValidityPattern={JSON.stringify(
        isInvalid
          ? [
              {
                regexp: '.+',
                errorMessage,
              },
            ]
          : []
      )}
      replacement={replacement}
      maxlength={maxlength}
      with-round={true}
      can-delete={true}
      with-reset={withReset || true}
    />
  );
};
