import { IPersonService } from '@vat/types';
import axiosInstance from './httpCommon';
import {
  getUpdatePersonPersonalInformation,
  IDataUpdate,
} from './helpers/servicePersonsHelper';

type Return = {
  status: number;
} | void;

export const updatePerson = (
  data: IPersonService[],
  onFailure: (error: { message: string; data: unknown }) => void
): Promise<Return> => {
  const dataInUpdatePerson: IDataUpdate[] = [];
  data.forEach((person) => {
    const dataIn: IDataUpdate = {
      personalInformation: {},
      relation: { roleCd: person.role },
    };
    dataIn.personalInformation = getUpdatePersonPersonalInformation(person);
    dataInUpdatePerson.push(dataIn);
  });
  return axiosInstance()
    .post(`/persons`, dataInUpdatePerson)
    .then((response) => Promise.resolve(response))
    .catch((err) => {
      onFailure({
        message: err?.response?.message ?? `Error encountered. ${err}`,
        data: err?.response?.data,
      });
    });
};

export default updatePerson;
