import { MouseEventHandler } from 'react';
import { useTheme } from 'styled-components';
import { StyledButtonLoader, Theme } from '@sweb-front/styles';
import { Button } from '../Button';
import { ButtonActionWrapper, ButtonLabel } from './styles';
import { BackIcon } from '../Icons';
import { SpanLabel, ValidateLabelWrapper } from 'src/libs/styles/globalStyles';

export type NavigationButtonsProps = {
  id?: string;
  validateLabel: string;
  backLabel: string;
  validateAction: MouseEventHandler<HTMLButtonElement>;
  backAction?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  withoutReturnButton?: boolean;
  isLoading?: boolean;
};

const NavigationButtons = ({
  id,
  validateLabel,
  validateAction,
  backLabel,
  backAction,
  isDisabled = false,
  withoutReturnButton = false,
  isLoading = false,
}: NavigationButtonsProps) => {
  const theme = useTheme() as Theme;
  return (
    <ButtonActionWrapper id={id}>
      <Button
        id="nav-validate-button"
        level="primary"
        onClick={validateAction}
        isDisabled={isDisabled}
      >
        <ButtonLabel fontSize="1.8rem" action="validate">
          <StyledButtonLoader
            isLoading={isLoading}
            isTextInline
            isHideBackground={false}
          />
          <ValidateLabelWrapper>
            <SpanLabel>{validateLabel}</SpanLabel>
          </ValidateLabelWrapper>
        </ButtonLabel>
      </Button>
      {!withoutReturnButton && (
        <Button
          id="nav-back-button"
          level="ghost"
          onClick={backAction}
          buttonType="none"
        >
          <BackIcon fill={theme.vendor.colors.primary} />
          <ButtonLabel fontSize="1.6rem" action="back">
            {backLabel}
          </ButtonLabel>
        </Button>
      )}
    </ButtonActionWrapper>
  );
};

export default NavigationButtons;
