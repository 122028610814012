export const Check = () => {
  return (
    <>
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>835B793F-78E9-4617-A219-71E5ABE4B6B6</title>
        <g
          id="Formulaire"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="15--Formulaire"
            transform="translate(-178.000000, -667.000000)"
            fill="#28883C"
          >
            <g id="bloc-charge" transform="translate(16.000000, 385.000000)">
              <g
                id="pension-alimentaire/-frais-de-garde"
                transform="translate(0.000000, 214.000000)"
              >
                <g
                  id="Input/Amount/Default"
                  transform="translate(0.000000, 54.000000)"
                >
                  <g
                    id="Ic/24px/Action/check2-Copy-9"
                    transform="translate(162.000000, 14.000000)"
                  >
                    <path
                      d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M14.6439394,6.26780122 C14.3509096,5.97504473 13.8760359,5.97526637 13.5832794,6.26829625 L8.99979155,11.8034315 L6.75536921,9.55915746 C6.46246632,9.26627391 5.98759258,9.2662896 5.69470904,9.55919249 C5.40182549,9.85209538 5.40184118,10.3269691 5.69474407,10.6198527 L8.46974407,13.3946693 C8.76273679,13.6876427 9.23778791,13.687524 9.53063418,13.3944043 L14.6444345,7.32846128 C14.937191,7.03543139 14.9369693,6.56055771 14.6439394,6.26780122 Z"
                      id="check-circle-fill"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
