import { ProfessionalForm } from '@vat/containers';
import { useTranslation } from 'react-i18next';
import MainSectionWrapper from './styles';
import { useEffect } from 'react';
import { useAppOptions } from '@vat/utils';
import { usePageRedirection } from '@sweb-front/hooks';
import { SOLVA_PRO } from '@vat/configuration';

const ProfessionalSituation = () => {
  const { t } = useTranslation();
  const { setAppOptions } = useAppOptions();
  const { isPageLoading } = usePageRedirection(SOLVA_PRO);

  useEffect(() => {
    setAppOptions({
      isMounted: !isPageLoading,
    });
  }, [isPageLoading]);

  return (
    !isPageLoading && (
      <MainSectionWrapper title={t('professionalSituation.title')}>
        <ProfessionalForm />
      </MainSectionWrapper>
    )
  );
};

export default ProfessionalSituation;
