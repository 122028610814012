import { Section, BaseSpinner } from '@sweb-front/components';
import { LoadingWrapper } from '@sweb-front/styles';

import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import {
  PageLoadingContext,
  useCloseOpportunity,
  useManagingExternalApp,
} from '@vat/utils';
import { Monext3DsResponse } from '@sweb-front/types';
import {
  getActiveStep,
  selectNavigation,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { SpinnerLabel, SpinnerLongText } from './styles';

import {
  DOAUTHORIZATION,
  ERRORKEY,
  ERRORPAGE,
  THREEDS,
} from '@vat/configuration';
import { useNavigate } from 'react-router-dom';

const Redirection = () => {
  const { t } = useTranslation();
  const updatePageIsLoading = useContext(PageLoadingContext);
  const externalAppSteps = useAppSelector(selectNavigation);
  const externalActiveState = getActiveStep(externalAppSteps?.steps);
  const { finalizeLoanRequesting } = useManagingExternalApp();
  const monext3dsResponse =
    externalActiveState?.params as unknown as Monext3DsResponse;
  const dispatch = useAppDispatch();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const navigate = useNavigate();

  useEffect(() => {
    // CAS APRES 3DS
    if (
      externalActiveState?.externalAppName === 'authorization' &&
      monext3dsResponse?.threeDsRef
    ) {
      finalizeLoanRequesting(monext3dsResponse);
    } else if (externalActiveState?.externalAppName === 'authorization') {
      localStorage.setItem(ERRORKEY, '400');
      dispatch(
        updateParcoursNavigation({
          name: THREEDS,
          actionPageDone: false,
          params: {
            hasError: true,
          },
        })
      );
      updateAbortErrorMessage(
        ` Service payment/doAuthorization n'est pas lancée car 3DS n'a pas renseigné de paramètre threeDsRef dans son URL de retour ${Object.entries(
          monext3dsResponse ?? {}
        )
          .map(([value, key]) => `${value}: ${key}`)
          .join('; ')}`
      );
      navigate(ERRORPAGE);
    } else if (externalActiveState?.externalAppName === '3ds') {
      if (
        externalActiveState?.params?.sign ===
        externalActiveState?.params?.storedSign
      ) {
        dispatch(
          updateSteps({
            externalAppName: '3ds',
            isSeDone: true,
          })
        );
        dispatch(
          updateParcoursNavigation({
            name: THREEDS,
            actionPageDone: true,
          })
        );
        dispatch(
          updateParcoursNavigation({
            name: DOAUTHORIZATION,
            actionPageDone: false,
            loaded: true,
            locked: true,
            params: {
              virtual: true,
            },
          })
        );
        dispatch(
          updateSteps({
            params: externalActiveState?.params,
            externalAppName: 'authorization',
            isSeDone: false,
          })
        );
      }
    }
    updatePageIsLoading(false);
  }, []);

  return (
    <Section>
      <LoadingWrapper>
        <BaseSpinner size="large" color="green" />
        <SpinnerLabel>{t('waitingPage.label')}</SpinnerLabel>
        <SpinnerLongText>{t('waitingPage.longText')}</SpinnerLongText>
      </LoadingWrapper>
    </Section>
  );
};

export default Redirection;
