import styled from 'styled-components';
import { EditIcon } from '../Icons';

export const EditableInputEditButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0 1.2rem 0 0;
  align-items: center;
  font-family: 'Open Sans Bold';
  cursor: pointer;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const EditableInputEditIcon = styled(EditIcon)`
  height: 2.4rem;
`;

export const EditableInputEditText = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  font-family: 'Open Sans Bold';
  font-size: 1.4rem;
  line-height: 1.6rem;
`;
