const RefusedIconPage = () => {
  return (
    <svg
      id="refusedIconPage"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33319 32.0002C5.33319 46.7278 17.2723 58.6668 31.9999 58.6668C46.7275 58.6668 58.6665 46.7278 58.6665 32.0002C58.6665 17.2726 46.7275 5.3335 31.9999 5.3335C17.2723 5.3335 5.33319 17.2726 5.33319 32.0002ZM54.9456 32.0002C54.9456 44.6727 44.6724 54.9459 31.9999 54.9459C19.3273 54.9459 9.05412 44.6727 9.05412 32.0002C9.05412 19.3276 19.3273 9.05443 31.9999 9.05443C44.6724 9.05443 54.9456 19.3276 54.9456 32.0002ZM40.7573 23.2428C40.0307 22.5162 38.8527 22.5162 38.1262 23.2428L31.9999 29.3691L25.8735 23.2428C25.147 22.5162 23.969 22.5162 23.2425 23.2428C22.5159 23.9693 22.5159 25.1473 23.2425 25.8738L29.3688 32.0002L23.2425 38.1265C22.5159 38.853 22.5159 40.031 23.2425 40.7576C23.969 41.4841 25.147 41.4841 25.8735 40.7576L31.9999 34.6313L38.1262 40.7576C38.8527 41.4841 40.0307 41.4841 40.7573 40.7576C41.4838 40.031 41.4838 38.853 40.7573 38.1265L34.631 32.0002L40.7573 25.8738C41.4838 25.1473 41.4838 23.9693 40.7573 23.2428Z"
        fill="#4B4F54"
      />
    </svg>
  );
};

export default RefusedIconPage;
