import AdobeTagManager, {
  Context,
  IContext as IAdobeTagManagerContext,
} from './AdobeTagManager';

export type IContext = IAdobeTagManagerContext;

export { Context };

export default AdobeTagManager;
