const SvgActivity: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.266 2.095l9.582 7.916a.412.412 0 01.152.32v11.25a.413.413 0 01-.415.419H2.423a.417.417 0 01-.418-.417L2 8.297c0-.11.045-.218.123-.296l5.832-5.832a.415.415 0 01.587 0l1.404 1.404 1.79-1.478a.416.416 0 01.53 0zm-4.017.958L2.835 8.468l.005 1.863v10.834h2.495v-6.989c0-.23.187-.417.418-.417h5c.23 0 .416.186.416.417v6.989h2.496V8.47l-1.057-1.057-.023-.028-4.336-4.332zm3.755-.094l-1.462 1.207 2.392 2.392A2.086 2.086 0 0116.166 8.3a2.085 2.085 0 01-1.668 2.04l-.001 3.418h2.504c.23 0 .417.187.417.418l-.001 6.989h3.751V10.531l-9.164-7.572zm4.58 11.635l-2.087-.001v6.572h2.086v-6.571zm-6.25 0H6.168v6.571h4.167v-6.571zM8.252 7.463c1.15 0 2.084.934 2.084 2.084S9.4 11.63 8.25 11.63a2.086 2.086 0 01-2.084-2.083c0-1.15.935-2.084 2.084-2.084zm0 .834a1.25 1.25 0 000 2.499 1.25 1.25 0 000-2.499zm5.831-1.249c-.191 0-.375.042-.54.12l.834.835a.415.415 0 01.124.294v1.179a1.251 1.251 0 00-.418-2.428z"
    />
  </svg>
);

export default SvgActivity;
