import {
  useBreakpoints,
  useLoadingHook,
  useManagingStep,
} from '@sweb-front/hooks';
import {
  IOpportunityState,
  setPerson,
  updateParcoursNavigation,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { findPriOverdraftAmount, trackEvent } from '@sweb-front/utils';
import { ErrorContext } from '@vat/utils';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SECTORCODES, SECTORLIST, SOLVA_PRO } from '@vat/configuration';
import { useFormik } from 'formik';
import {
  getDateInDateObjectFormat,
  getDateInStringFormat,
} from 'src/utils/formatters';
import { z } from 'zod';
import { dateOf16, postData, setIdHashed } from '../utils';

export interface SectorList {
  value: string;
  label: string;
}

const getEmploymentDt = (employmentDt: Date) => {
  const date = getDateInStringFormat(employmentDt);
  if (!date) {
    return '';
  }
  const dtList = `01/${date}`.split('/');
  return `${dtList[2]}-${dtList[1]}-${dtList[0]}`;
};

const useProfessionalForm = (opportunity: IOpportunityState) => {
  const { isDesktop } = useBreakpoints();
  const { goBackToStep, goToNextStep } = useManagingStep();
  const dispatch = useAppDispatch();
  const { person } = opportunity;
  const { t } = useTranslation();
  const updateError = useContext(ErrorContext);
  const [sectors, setSectors] = useState<SectorList[]>([]);
  const parameters = useAppSelector((state) => state.parameters.state);
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();

  const initialValues = {
    employmentDt: getDateInDateObjectFormat(person?.profession?.employmentDt),
    activitySector: person?.profession?.ihmSector,
  } as const;

  const schema = z.object({
    activitySector: z.string({
      required_error: t('basicInformations.errors.activitySectors'),
      invalid_type_error: t('basicInformations.errors.activitySectors'),
    }),
    employmentDt: z
      .date({
        errorMap: (issue, ctx) => {
          switch (issue.code) {
            case 'invalid_date':
              return {
                message: t('basicInformations.errors.employmentDt.format'),
              };
            default:
              return {
                message:
                  ctx.defaultError === 'Required'
                    ? t('basicInformations.errors.employmentDt.required')
                    : t('basicInformations.errors.employmentDt.format'),
              };
          }
        },
      })
      .superRefine((value, ctx) => {
        const date = new Date(value);
        const birthDate = new Date(
          opportunity?.person?.personalInformation.birthDt
        );
        if (date) {
          const regExp = new RegExp('^(0[1-9]|1[0-2])/\\d{4}$');
          const currentYear = new Date().getFullYear();
          const year = date.getFullYear();

          if (
            !regExp.test(getDateInStringFormat(date)) ||
            !(year >= 1900 && year <= currentYear)
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('basicInformations.errors.employmentDt.format'),
            });
          }
          if (year < dateOf16(birthDate).getFullYear()) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('basicInformations.errors.employmentDt.youngerThan16'),
            });
          }
        }
      }),
  });

  const initialTouched = Object.entries(initialValues)
    .map(([key, value]) =>
      value !== undefined && value !== '' ? key : undefined
    )
    .filter((item) => item !== undefined)
    .reduce(
      (acc, item) => (item !== undefined ? { ...acc, [item]: true } : acc),
      {}
    );

  const validate = (values: unknown) => {
    const result = schema.safeParse(values);
    if (result.success === true) {
      return;
    }
    const errors = result.error.errors.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.path[0]]: curr.message,
      };
    }, {});
    return errors;
  };

  const onSubmit = (values: InformationFormSchema) => {
    const personalInformation = {
      ...person,
      profession: {
        ...person?.profession,
        ihmSector: values.activitySector,
        employmentDt: getEmploymentDt(values.employmentDt),
      },
    };
    updateIsActionLoading(true);
    postData(
      'vendors/opportunities/v1/opportunity',
      {
        persons: [personalInformation],
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: SOLVA_PRO,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        updateIsActionLoading(false);
        dispatch(setPerson(personalInformation));
        goToNextStep();
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: SOLVA_PRO,
            loaded: true,
            actionPageDone: true,
            disabled: false,
            params: {
              hasError: true,
            },
          })
        );
        updateIsActionLoading(false);
        updateError();
      }
    );
  };

  type InformationFormSchema = z.infer<typeof schema>;

  const formik = useFormik<Partial<InformationFormSchema>>({
    initialValues,
    initialTouched,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit,
  });

  useEffect(() => {
    setSectors(
      SECTORLIST.map((sector) => {
        const value = SECTORCODES[sector];
        const label = t(`basicInformations.sectorList.${sector}`);
        return { value, label };
      })
    );
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
    const isMono: boolean = opportunity?.bagType === 'MONO';
    trackEvent({
      event: 'module_interaction',
      site: 'Ecommerce',
      workflow: parameters.wayCd,
      pageName: 'E-Commerce : Formulaire Solva : Situation professionnelle',
      environment: (window as unknown as WindowWithEnv)._env_?.env,
      visitorStatus: 'non-logged',
      opportunityIdHashed: setIdHashed(opportunity),
      ContributorCode: opportunity.distributor.distributorNb,
      Amount: (isMono
        ? financialDetails?.overdraftAmt
        : findPriOverdraftAmount(opportunity?.offers[0].loans)
      )?.toString(),
      Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
      Term: isMono ? financialDetails?.term?.toString() : undefined,
      MonthlyPayment: isMono
        ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
        : undefined,
      pathType: isMono ? 'mono panier' : 'multi panier',
    });
  }, []);

  const isDirty =
    formik.values.activitySector !== undefined &&
    formik.values.employmentDt !== undefined;

  return {
    isDirty,
    formik,
    sectors,
    isDesktop,
    goBackToStep,
    isActionLoading,
  };
};

export default useProfessionalForm;
