import colors from './colors';
import layout from './layout';
import fonts from './fonts';
import * as themes from './themes';
import { THEME } from '@vat/configuration';
import { themeName } from '@sweb-front/types';

const variables = {
  colors,
  layout,
  fonts,
};

export type Theme = {
  themeName?: themeName;
  variables: typeof variables;
  vendor: themes.ThemeVendor;
};

export type ThemeNames = keyof typeof themes;

export const getTheme = (): Theme => {
  const themeName = (localStorage.getItem(THEME) || 'CETELEM') as themeName;
  return {
    themeName,
    variables,
    vendor: themes[themeName],
  };
};

export const getThemeDemat = () => {
  const theme = getTheme();
  return {
    leftLogo: `${window.location.origin}${window.location.pathname}logos/${theme.vendor.logo}.png`,
  };
};
