import styled from 'styled-components';

export const CardContainer = styled.div<{
  type?: string;
  borderColor?: string;
}>`
  border-radius: 0.6rem;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: auto;
  border-width: ${(props) => (props.type === 'border' ? '0 0 0 1rem' : '')};
  border-color: ${(props) => `var(--${props.borderColor})`};
  border-style: ${(props) => (props.type === 'border' ? 'solid' : 'none')};
`;
