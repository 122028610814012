import { TStep } from './Header';

const useHeader = (currentStep: number, steps: TStep[]) => {
  const progressPercentage = (currentStep / steps?.length) * 100;
  return {
    progressPercentage,
  };
};

export default useHeader;
