import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseCardSeparatorDS extends HTMLElement {
  constructor() {
    super();
  }
}

export const BaseCardSeparator = createComponent(React, 'base-card-separator', BaseCardSeparatorDS, {});
