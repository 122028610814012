import { useManagingStep } from '@sweb-front/hooks';
import { useTranslation } from 'react-i18next';

const ESignature = () => {
  const { goBackToStep } = useManagingStep();
  const { t } = useTranslation();

  return (
    <>
      <div>{t('esignature.title')}</div>
      <div
        style={{
          display: 'flex',
          marginTop: '16px',
          justifyContent: 'center',
          alignContent: 'center',
          textAlign: 'center',
        }}
      >
        <button-link label={t('common.back')} onClick={goBackToStep} />
      </div>
    </>
  );
};

export default ESignature;
