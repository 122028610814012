import { CONTEXT_ECONTRAT } from '@sweb-front/services';
import { IRoutingContext } from '@sweb-front/types';
import { findPriOverdraftAmount, trackEvent } from '@sweb-front/utils';
import { IFinancialDetails } from '@sweb-front/types';
import { setIdHashed } from '../../containers/forms/utils';
import { useCallback, useContext, useEffect } from 'react';
import { PageLoadingContext } from '@vat/utils';

const useApprovedPayment = (opportunity, parameters, navigation) => {
  const { routingContexts } = navigation;
  const updatePageIsLoading = useContext(PageLoadingContext);

  const onContractClick = useCallback(() => {
    if (Array.isArray(routingContexts)) {
      const linkContract: IRoutingContext | undefined = routingContexts?.find(
        (routingContext: IRoutingContext) =>
          routingContext.contextUse === CONTEXT_ECONTRAT
      );
      if (linkContract) {
        window.open(linkContract.url, '_blank');
      }
    }
  }, [routingContexts]);

  useEffect(() => {
    updatePageIsLoading(false);
    let financialDetails = {} as IFinancialDetails;

    if (opportunity && opportunity.offers?.length > 0) {
      financialDetails = opportunity.offers[0].loans[0].financialDetails;
    }

    if (opportunity.opportunityIdExt && parameters.wayCd) {
      const isMono: boolean = opportunity?.bagType === 'MONO';
      trackEvent({
        event: 'module_interaction',
        pageName: 'E-Commerce : FV',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        // eslint-disable-next-line no-underscore-dangle
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        Amount: (isMono
          ? financialDetails?.overdraftAmt
          : findPriOverdraftAmount(opportunity.offers[0].loans)
        )?.toString(),
        Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
        Term: isMono ? financialDetails?.term?.toString() : undefined,
        MonthlyPayment: isMono
          ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
          : undefined,
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: opportunity.distributor?.distributorNb ?? '',
        pathType: isMono ? 'mono panier' : 'multi panier',
      });
    }
  }, [opportunity.opportunityIdExt, parameters.wayCd]);

  return {
    onContractClick,
  };
};

export default useApprovedPayment;
