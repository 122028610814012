const SvgClose: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    viewBox="-6.5 -6.5 36 36"
    className={className}
    stroke="#292c2e"
    strokeWidth={2}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.317 3.226L12 10.909l7.683-7.683a.771.771 0 111.091 1.09L13.091 12l7.683 7.683a.771.771 0 11-1.09 1.091L12 13.091l-7.683 7.683a.771.771 0 01-1.091-1.09L10.909 12 3.226 4.317a.771.771 0 111.09-1.091z"
    />
  </svg>
);

export default SvgClose;
