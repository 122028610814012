import { useBreakpoints } from '@sweb-front/hooks';
import { axiosInstance } from '@sweb-front/services';
import { IFileDownloader } from '@sweb-front/types';
import { useRef, useState } from 'react';
import { FileDownloadWrapper, StyledLoader } from './styles';

interface IFileProps {
  file: IFileDownloader;
}

const useReadFile = (url: string, fileName?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const readFile = async () => {
    setIsLoading(true);
    const response = await axiosInstance().get(url);
    const base64File = response.data?.content;
    const pdfNewTab = window.open('');
    pdfNewTab?.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${base64File}'></iframe>`
    );
    setIsLoading(false);
  };

  const downLoadFile = async () => {
    setIsLoading(true);
    const response = await axiosInstance().get(url);
    const base64File = response.data?.content;
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/pdf;base64,${base64File}`;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsLoading(false);
  };
  return { isLoading, readFile, downLoadFile };
};

const FileDownload = (props: IFileProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const { file } = props;
  const { isDesktop } = useBreakpoints();

  const { isLoading, readFile, downLoadFile } = useReadFile(
    `vendors/opportunities/v1/cos/${file.link}`,
    file.fileName
  );

  return (
    <>
      <FileDownloadWrapper
        ref={anchorRef}
        onClick={() => {
          if (!isLoading) {
            if (isDesktop) readFile();
            else downLoadFile();
          }
        }}
        isLoading={isLoading}
      >
        <base-icon icon="contract" />
        {file.label}
        <StyledLoader isLoading={isLoading} isTextInline />
      </FileDownloadWrapper>
    </>
  );
};

export default FileDownload;
