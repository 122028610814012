import { IUrlData } from '@sweb-front/types';
import { isStringEmpty } from '@sweb-front/utils';
import createHTMLElement from './createHtmlComponent';

export interface IForm {
  id: string;
  target: string;
  name: string;
}

const createAndSubmitForm = (
  url: string,
  method: string,
  formParams: IForm,
  params: IUrlData[],
  callBack?: () => void
) => {
  const form = createHTMLElement('form', {
    method,
    id: formParams.id ?? 'tmp_form',
    action: url,
    name: formParams.name ?? 'tmp_form',
    target: formParams.target ?? '_parent',
    'accept-charset': 'utf-8',
  }) as HTMLFormElement;

  params.forEach((param) => {
    createHTMLElement(
      'input',
      {
        key: param.field,
        type: 'hidden',
        name: param.field,
        value: param.value,
      },
      form
    );
  });
  callBack?.();
  if (!isStringEmpty(url)) {
    form.submit();
  }
  form.remove();
};

export default createAndSubmitForm;
