import { ThemeProvider } from 'styled-components';
import { getTheme } from '@sweb-front/styles';
import {} from 'hoist-non-react-statics';

export interface IThemeProps {
  children?: React.ReactElement;
  theme?: {
    variables: unknown;
    vendor: unknown;
  };
}

const Theme = ({ children, theme }: IThemeProps): React.ReactElement => {
  const themeGlobal = getTheme();
  const assignTheme = { ...themeGlobal, ...theme };
  return <ThemeProvider theme={assignTheme}>{children}</ThemeProvider>;
};
export default Theme;
