import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IState,
  IIdentity,
  IBankDetails,
  IAddress,
  IContactInformation,
  ICustomerAccountData,
} from './personTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    setPersonIdentity(personState, action: PayloadAction<IIdentity>) {
      personState.state.identity = { ...action.payload };
    },
    setPersonBankInfo(personState, action: PayloadAction<IBankDetails>) {
      personState.state.bankInformation = { ...action.payload };
    },
    setPersonAddressInfo(personState, action: PayloadAction<IAddress>) {
      personState.state.billingAddressInformation = { ...action.payload };
    },
    setPersonContactInfo(
      personState,
      action: PayloadAction<IContactInformation>
    ) {
      personState.state.contactInformation = { ...action.payload };
    },
    setPersonAccountData(
      personState,
      action: PayloadAction<ICustomerAccountData>
    ) {
      personState.state.customerAccountData = { ...action.payload };
    },
    setOcrKo(personState, action: PayloadAction<string>) {
      personState.state.ocrKo = action.payload;
    },
    setCustomerAccountStore(
      personState,
      action: PayloadAction<ICustomerAccountData>
    ) {
      personState.state.customerAccountStore = { ...action.payload };
    },
    setDepartmentChanged(personState, action: PayloadAction<boolean>) {
      personState.state.isDepartmentChanged = action.payload;
    },
    setCityChanged(personState, action: PayloadAction<boolean>) {
      personState.state.isCityChanged = action.payload;
    },
    updatePerson(personState, action: PayloadAction<Partial<IState>>) {
      personState.state = { ...personState?.state, ...action.payload };
    },
    clear(personState) {
      personState.state = getInitialState().state;
    },
  },
});

export const selectPerson = (personState: { person: { state: IState } }) =>
  personState.person.state;

export const {
  setPersonIdentity,
  setPersonBankInfo,
  setPersonAddressInfo,
  setPersonContactInfo,
  setPersonAccountData,
  setOcrKo,
  setCustomerAccountStore,
  updatePerson,
  clear,
} = personSlice.actions;

export default personSlice.reducer;
