import styled from 'styled-components';
import { IBubbleInfoProps } from './BubbleInfo';

const getTransformValue = (id: string) => {
  return id === 'bubbleinfo-income-activity'
    ? 'rotate(-90deg) translate(3rem, 0)'
    : 'rotate(-90deg) translate(3rem, -23.5rem)';
};

const getPosition = (id: string) => {
  return id === 'bubbleinfo-income-activity' ? 'fixed' : '';
};

export const BubbleInfoWrapper = styled.div<{
  id?: string;
  showBubbleAfter?: boolean;
}>`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: ${({ id }) =>
    id === 'bubbleinfo-income-activity' || id === 'bubbleinfo-refused-page'
      ? '1.6rem'
      : '1.8rem'};
  color: var(--neutrals-700);
  background-color: var(--blue-100);
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
  border-radius: 0.4rem;
  ${({ id, showBubbleAfter }) => {
    return `${
      showBubbleAfter
        ? `
            &:after {
              content: '';
              position: ${getPosition(id)};
              transform: ${getTransformValue(id)};
              height: 0px;
              width: 0px;
              border-top: ${
                id === 'bubbleinfo-income-activity'
                  ? 'solid 1.2rem transparent'
                  : 'solid 0.8rem transparent'
              };
              border-left: ${
                id === 'bubbleinfo-income-activity'
                  ? 'solid 1.2rem var(--blue-100);'
                  : 'solid 0.8rem var(--blue-100);'
              };
              border-bottom: ${
                id === 'bubbleinfo-income-activity'
                  ? 'solid 1.2rem transparent'
                  : 'solid 0.8rem transparent'
              };
            }
        `
        : ''
    }`;
  }}
`;

export const BubbleInfoTextWrapper = styled.div``;
export const SvgIconWrapper = styled.div`
  flex: none;
`;

export const BubbleInfoTitleWrapper = styled.div`
  margin: 0;
  line-height: 2rem;

  &.bubbleinfo-income-activity span.light-title,
  &.bubbleinfo-refused-page span.light-title {
    color: var(--blue-500);
  }

  &.bubbleinfo-refused-page span.light-title {
    display: block;
    text-align: left;
  }

  & span.light-title {
    font-family: 'Open Sans';
  }

  & span.strong-title {
    font-family: 'Open Sans bold';
  }
`;

export const BubbleInfoListWrapper = styled.ul<IBubbleInfoProps>`
  list-style-type: ${(props: IBubbleInfoProps) => {
    if (!props.marker || props?.marker === '') {
      return 'none';
    }
    if (props?.isCustomedMarker) {
      return `'${props.marker}'`;
    }
    return props.marker;
  }};
  padding: 0;
  margin: 0;

  & li {
    color: var(--neutrals-700);
    line-height: 2rem;
    margin-left: ${(props: IBubbleInfoProps) => {
      if (!props.marker || props?.marker?.trim() === '') {
        return 0;
      }
      return props.isCustomedMarker ? '0.8rem' : '1.4rem';
    }};
  }

  & li:before {
    content: '';
    padding-left: ${(props: IBubbleInfoProps) => {
      if (!props.marker || props?.marker?.trim() === '') {
        return 0;
      }
      return props.isCustomedMarker ? '0.6rem' : '0';
    }}
`;
