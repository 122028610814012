export type CheckMarkIconHollowProps = {
  color?: string;
};

const CheckMarkIconHollow = ({ color }: CheckMarkIconHollowProps) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>E838DF00-3682-482A-BA9B-BB987F119A95@1,5x</title>
      <g
        id="📱Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Pop-in-&gt;-Cmt-ca-marche"
          transform="translate(-42.000000, -258.000000)"
          fill={color}
        >
          <g
            id="Cards/Collapse/Default"
            transform="translate(16.000000, 240.000000)"
          >
            <g id="Group" transform="translate(24.000000, 16.000000)">
              <g
                id="Icon/24px/User-Interface/Info-outlines"
                transform="translate(2.000000, 2.000000)"
              >
                <path
                  d="M10,-2.22044605e-16 C15.5228476,-2.22044605e-16 20,4.47715252 20,10 C20,15.5228476 15.5228476,20 10,20 C4.47715252,20 -2.22044605e-16,15.5228476 -2.22044605e-16,10 C-2.22044605e-16,4.47715252 4.47715252,-2.22044605e-16 10,-2.22044605e-16 Z M10,1.25000001 C5.16750846,1.25000001 1.25000001,5.16750846 1.25000001,10 C1.25000001,14.8324916 5.16750846,18.75 10,18.75 C14.8324916,18.75 18.75,14.8324916 18.75,10 C18.75,5.16750846 14.8324916,1.25000001 10,1.25000001 Z M14.6439394,6.26780122 C14.9369693,6.56055771 14.937191,7.03543139 14.6444345,7.32846128 L9.53063418,13.3944043 C9.23778791,13.687524 8.76273679,13.6876427 8.46974407,13.3946693 L5.69474407,10.6198527 C5.40184118,10.3269691 5.40182549,9.85209538 5.69470904,9.55919249 C5.98759258,9.2662896 6.46246632,9.26627391 6.75536921,9.55915746 L8.99979155,11.8034315 L13.5832794,6.26829625 C13.8760359,5.97526637 14.3509096,5.97504473 14.6439394,6.26780122 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckMarkIconHollow;
