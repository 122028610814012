import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseCardDS extends HTMLElement {
  constructor() {
    super();
  }
}

export const BaseCard = createComponent(React, 'base-card', BaseCardDS, {});
