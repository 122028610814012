import { DESKTOP } from '@sweb-front/utils';
import styled from 'styled-components';

export const MainWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;

  & ul {
    margin: 0;
    margin-top: 1.6rem;
    padding: 0 0 0 1.6rem;
  }

  & a {
    text-decoration: none;
    color: var(--neutrals-500);
    font-family: 'Open Sans Bold';
  }

  & strong {
    font-family: 'Open Sans Bold';
  }

  & p {
    margin-bottom: 0;
    flex-grow: 1;
  }

  @media screen and ${DESKTOP} {
    margin: 1rem 6rem;
  }
`;
export const LogoWrapper = styled.div`
  margin: 0;
  padding: 0;
  justify-content: start;
  & img {
    width: 16rem;
  }
`;
export const ParagraphWrapper = styled.div``;
export const FirstParagraphWrapper = styled(ParagraphWrapper)`
  & ul {
    padding-left: 1.2rem;
  }
  & ul li {
    margin-bottom: 1.6rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
export const ParagraphTelWrapper = styled(ParagraphWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  & p {
    text-align: left;
  }

  & img {
    margin-top: 1.2rem;
    self-align: center;
  }
`;
export const ListParagraphWrapper = styled(ParagraphWrapper)`
  & ul {
    padding-left: 1.2rem;
  }
`;
export const ConnectParagraphWrapper = styled(ParagraphWrapper)`
  margin: 1.6rem 0;
  text-align: center;
`;
export const CenterParagraphWrapper = styled.div`
  & ul {
    padding-left: 0;
  }
  & ul li {
    list-style-position: inside;
    text-align: center;
    padding: O;
    margin: 0;
  }
`;
export const ImgWrapper = styled.div`
  display: flex;
  margin: 3.2rem 0 1.6rem 0.4rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  & img {
    width: 14.8rem;
  }
  & img.google-play {
    width: 15.4rem;
  }
`;
export const SeparatorWrapper = styled.div`
  height: 0.4rem;
  background: var(--neutrals-400);
  margin: 0 auto;
  margin-bottom: 1.6rem;
  self-align: center;
  width: 60%;
`;
export const FooterParagraph = styled.div`
  font-size: 0.8rem;
  margin-bottom: 1.6rem;
`;
export const SVGIconWrapper = styled.div`
  display: flex;
  margin: 0.8rem 0 1.6rem 0;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
  & base-icon {
    width: 7.2rem;
    height: 7.2rem;
    --color-icon: var(--neutrals-400);
  }
`;
