import {
  IOnpenBankingResultResponse,
  IOnpenBankingUrlResponse,
} from '@sweb-front/types';
import axiosInstance from './httpCommon';

export const getOnpenBankingUrl = async (
  returnUrl: string,
  successCallback?: (response: IOnpenBankingUrlResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const returnUrlParam = `returnUrl=${returnUrl}`;
    const response = await axiosInstance().get(
      `/open-banking/v1/init?${returnUrlParam}`
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
    throw new Error(errCode);
  }
};

export const getResultOpenBanking = async (
  successCallback?: (response: IOnpenBankingResultResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const response = await axiosInstance().get(`/open-banking/v1/result`);
    successCallback?.(response?.data);
    return response?.data;
  } catch (errCode) {
    errorCallback?.(errCode as number);
    throw new Error(errCode);
  }
};
