import { IFileDownloader } from '@sweb-front/types';
import FileDownload from '../FileDowload';
import Title from '../Title';
import './Documents.css';

export interface IDocumentsProps {
  title?: string;
  files?: IFileDownloader[];
}
const Documents: React.FC<IDocumentsProps> = ({
  title,
  files,
}: IDocumentsProps) => (
  <>
    <Title level={3} className="greenTitle">
      {title}
    </Title>
    {files?.map((file, index) => (
      <FileDownload file={file} key={`file_${index + 1}`} />
    ))}
  </>
);

export default Documents;
