import { styled } from 'styled-components';

export const AutoCompleteFieldWrapper = styled.div<{
  $valid?: boolean;
  $error?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;

  .label-wrapper {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    color: var(--neutrals-500);
  }

  .icon-container {
    flex: 1;
    position: relative;
    margin-top: 0.8rem;

    input {
      border: ${(props) => {
        if (props?.$valid === true) {
          return '1px solid var(--main-green-500)';
        }
        if (props?.$error === true) {
          return '1px solid var(--red-400)';
        }
        return '1px solid var(--neutrals-500)';
      }};
      border-radius: 0.4rem;
      font-size: 1.6rem;
      padding: 0.7rem 4.8rem;
      line-height: 3.2rem;
      outline: none;
      width: 100%;
      text-overflow: ellipsis;
      font-family: inherit;
    }

    input:focus {
      border: 0.1rem solid var(--main-green-500);
    }

    .icon-search {
      position: absolute;
      top: 1.2rem;
      left: 1.6rem;
      --color-icon: var(--neutrals-500);
      --icon-width: 2.4rem;
      --icon-height: 2.4rem;
    }

    .icon-state {
      position: absolute;
      top: 1.2rem;
      right: 1rem;
      --color-icon: green;
      --icon-width: 2.4rem;
      --icon-height: 2.4rem;
      &.success {
        --color-icon: var(--main-green-500);
      }
      &.error {
        --color-icon: var(--red-400);
      }
    }
  }

  .autocomplete-other-cmp {
    padding: 0.8rem 0 0.8rem 0rem;
    display: flex;
    font-family: 'Open Sans';
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-decoration: underline;
    color: var(--main-green-500);
    cursor: pointer;
    &:hover {
      background: var(--main-green-50);
    }
  }

  .content-result {
    position: absolute;
    width: 99.8%;
    top: 8.4rem;
    z-index: 10;
    box-shadow: 0rem 0rem 0.4rem 0.2rem var(--neutrals-100);
    overflow: inherit;
    min-height: 4.2rem;
    border-radius: 0.2rem;
    background: #fff;

    .content-result-items {
      max-height: 19.4rem;
      overflow-y: scroll;
    }

    .autocomplete-other-cmp {
      padding: 0.8rem 0 0.8rem 1.6rem;
      display: flex;
      font-family: 'Open Sans';
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-decoration: underline;
      color: var(--main-green-500);
      cursor: pointer;
      &:hover {
        background: var(--main-green-50);
      }
    }

    .content-item {
      padding: 0.8rem 0 0.8rem 1.6rem;
      width: 100%;
      border-bottom: 0.1rem solid var(--neutrals-100);
      color: var(--neutrals-500);
      cursor: pointer;
      position: relative;
      z-index: 10;

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        background: var(--main-green-50);
      }

      strong {
        font-family: 'Open Sans Bold';
      }
    }

    .content-item .autocomplete-main-item {
      position: relative;
      display: block;
      font-family: 'Open Sans';
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .autocomplete-error {
    margin-top: 0.4rem;
    font-size: 1.4rem;
    color: var(--red-400);
    line-height: 20px;
    text-align: justify;
  }
`;

export const ImgWrapper = styled.div`
  display: inline-flex;
  margin: 2rem 0 0.8rem 0;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
`;
