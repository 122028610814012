import styled from 'styled-components';
export const InformationBubbleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.6rem 0;
  gap: 1rem;
  border-radius: 0.4rem;
  padding: 1.6rem;
`;

export const SVGIconWrapper = styled.div`
  height: 100%;
  &svg {
    height: 3rem;
    width: 3rem;
  }
`;
