import { CustomizedCard, CheckedListItem } from '@sweb-front/components';
import OptionalDocumentCard, {
  IOptionDocument,
} from './items/OptionalDocumentCard';
import OptionalInsuranceCard, {
  IOptionLabel,
} from './items/OptionalInsuranceCard';
import ReturnButton from './items/ReturnButton';
import SelectButton from './items/SelectButton';
import WarrantiesText from './items/WarrantiesText';

import {
  SectionWrapper,
  TitleWrapper,
  InsuranceOfferCardWrapper,
} from './items/styles';

export interface IOfferDetailCardContent {
  title: string;
  description: string;
}

export interface IOfferDetailCard {
  bannerTitle?: string;
  cardDescription: string;
  contents: IOfferDetailCardContent[];
}

export interface IOptionsCost {
  title: string;
  options: IOptionLabel[];
}

export interface IOptionsDocument {
  title: string;
  documents: IOptionDocument[];
}

export interface IGenericInsuranceContainerProps {
  offerTitle: string;
  offerDetails: IOfferDetailCard;
  value: string;
  options: IOptionsCost;
  documents: IOptionsDocument;
  onSelectOption: (value?: string) => void;
  onReturnClick: () => void;
}

const GenericInsuranceContainer = ({
  offerTitle,
  offerDetails,
  onSelectOption,
  onReturnClick,
  options,
  documents,
  value,
}: IGenericInsuranceContainerProps) => {
  return (
    <SectionWrapper>
      <TitleWrapper level={2}>{offerTitle}</TitleWrapper>
      <InsuranceOfferCardWrapper>
        <CustomizedCard
          bannerTitle={offerDetails.bannerTitle}
          cardDescription={offerDetails.cardDescription}
        >
          {(offerDetails?.contents ?? []).map((content, index: number) => (
            <CheckedListItem
              className="checked-list-item"
              key={`ckditem-${index + 1}`}
              title={content.title}
              description={content.description}
            />
          ))}
        </CustomizedCard>
      </InsuranceOfferCardWrapper>
      <OptionalInsuranceCard
        contentTitle={options.title}
        options={options.options}
      />
      <OptionalDocumentCard
        contentTitle={documents.title}
        documents={documents.documents}
      />
      <SelectButton onSelectOption={() => onSelectOption(value)} />
      <ReturnButton onReturnClick={onReturnClick} />
      <WarrantiesText />
    </SectionWrapper>
  );
};

export default GenericInsuranceContainer;
