import styled from 'styled-components';

const SubtitleWrapper = styled.h2`
  color: ${({ theme }) => theme.vendor.colors.title};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h2};
  font-family: 'Ubuntu Bold';
  letter-spacing: 0;
  margin: 0;
`;

export default SubtitleWrapper;
