export type ChevronRightIconProps = {
  className?: string;
  fill?: string;
};

const ChevronRightIcon = ({
  className,
  fill = '#4B4F54',
}: ChevronRightIconProps) => {
  return (
    <svg viewBox="0 0 24 24" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23495 19.7389C8.08453 19.5722 8 19.3458 8 19.1097C8 18.8736 8.08453 18.6472 8.23495 18.4805L14.0681 11.9998L8.23495 5.5191C7.92224 5.17158 7.92224 4.60815 8.23495 4.26064C8.54765 3.91313 9.05464 3.91313 9.36734 4.26064L15.7651 11.3705C15.9155 11.5373 16 11.7637 16 11.9998C16 12.2359 15.9155 12.4623 15.7651 12.629L9.36734 19.7389C9.21731 19.9061 9.01359 20 8.80114 20C8.5887 20 8.38498 19.9061 8.23495 19.7389Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChevronRightIcon;
