import { NumberInput } from '@sweb-front/components';
import { useAppSelector } from '@sweb-front/store';
import {
  BubbleInfo,
  InputNumber,
  NavigationButtons,
  RadioGroup,
} from '@vat/components';
import { MONTHS_MAPPING, SOLVALPAGE } from '@vat/configuration';
import { setAllFieldsTouched } from '@vat/utils';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import {
  FormWrapper,
  IncomeDetailsInfoWrapper,
  IncomeWrapper,
  NavigationButtonsWrapper,
} from './styles';
import useIncomeExpensesLongForm from './useIncomesExpensesLongForm';

const VALIDATE_ON_BLUR = true;
const VALIDATE_ON_CHANGE = true;
const replacement = JSON.stringify([
  { regexp: '[^0-9,.]', replacement: '' },
  { regexp: '[.]{1,}', replacement: ',' },
  { regexp: '[,]{1,}', replacement: ',' },
]);

export interface IIncomeExpensesFormProps {
  fields: string[];
}

const IncomesExpensesLongForm = ({ fields }: IIncomeExpensesFormProps) => {
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const expensesDetailsRef = useRef(null);
  const incomeDetailsRef = useRef(null);
  const { t } = useTranslation();
  const {
    formik,
    showBubbleInfo,
    showBaseMonthIncome,
    isDirty,
    isRentOrCreditError,
    isOngoingDebtFocused,
    isOngoingDebtError,
    showExpensesBubbleInfo,
    isChildSupportError,
    goBackToStep,
    onIncomeFocus,
    onExpensesFocus,
    onExpensesBlur,
    onRentOrCreditFocus,
    onOngoingDebtFocus,
    onHideOutgoingDebt,
    onIncomeChange,
    onIncomeBlur,
    onChildSupportFocus,
    onChangeRentOrCredit,
    onChangeOngoingDebt,
    onChangeChildSupport,
    isActionLoading,
  } = useIncomeExpensesLongForm(opportunity, fields || [], {
    validateOnBlur: VALIDATE_ON_BLUR,
    validateOnChange: VALIDATE_ON_CHANGE,
  });
  return (
    <FormWrapper>
      <div>
        <InputNumber
          id="income"
          errorMessage={formik.errors.income}
          key={`income-${formik.touched.income}`}
          label={t(
            'incomeAndExpensesLong.form.fields.monthlyIncomeAndExpenses'
          )}
          placeholder={t(
            'incomeAndExpensesLong.form.placeholder.monthlyIncomeAndExpenses'
          )}
          value={
            formik.values.income ? String(formik.values.income) : undefined
          }
          afterText="€ / mois"
          onChange={onIncomeChange}
          maxLength={5}
          onFocus={onIncomeFocus}
          onBlur={onIncomeBlur}
          touched={formik.touched.income}
          withResetButton
          isInteger
        />
        <CSSTransition
          in={showBubbleInfo}
          nodeRef={incomeDetailsRef}
          timeout={225}
          unmountOnExit
          classNames="animation"
        >
          <IncomeDetailsInfoWrapper ref={incomeDetailsRef}>
            <BubbleInfo
              id="bubbleinfo-income"
              title={t('incomeAndExpensesLong.form.bubbleInfos.report')}
              bubbleItems={[
                t('incomeAndExpensesLong.form.bubbleInfos.incomeAndBonus'),
                t('incomeAndExpensesLong.form.bubbleInfos.retirements'),
                t('incomeAndExpensesLong.form.bubbleInfos.freelanceincomes'),
                t('incomeAndExpensesLong.form.bubbleInfos.pensions'),
              ]}
              marker="default"
              isCustomedMarker={false}
            />
          </IncomeDetailsInfoWrapper>
        </CSSTransition>
      </div>
      {showBaseMonthIncome && (
        <RadioGroup
          id="monthNumberBaseCalculation"
          key={`monthNumberBaseCalculation-error_${
            formik.errors.monthNumberBaseCalculation !== undefined
          }`}
          errorMessage={formik.errors.monthNumberBaseCalculation}
          touched={formik.touched.monthlyExpenses}
          label={t(
            'incomeAndExpensesLong.form.fields.monthNumberBaseCalculation'
          )}
          buttonWidth="4.8rem"
          rowGap="1.2rem"
          wrapOnMobile={false}
          options={MONTHS_MAPPING}
          onChange={({ value }) =>
            formik.setFieldValue('monthNumberBaseCalculation', Number(value))
          }
          value={String(formik.values.monthNumberBaseCalculation)}
        />
      )}
      {!fields.includes(SOLVALPAGE.SOLVCHRGS) && (
        <IncomeWrapper
          id="rentOrCredit-wrapper"
          aria-invalid={isRentOrCreditError}
          onFocus={onRentOrCreditFocus}
        >
          <NumberInput
            id="rentOrCredit"
            label={t('incomeAndExpensesLong.rentOrCredit.label')}
            placeholder={t('incomeAndExpensesLong.rentOrCredit.placeholder')}
            onChange={onChangeRentOrCredit}
            value={formik.values.rentOrCredit}
            errorMessage={formik.errors.rentOrCredit}
            isInvalid={isRentOrCreditError}
            onFocus={onRentOrCreditFocus}
            replacement={replacement}
            maxlength={5}
            with-reset
          />
        </IncomeWrapper>
      )}
      {!fields.includes(SOLVALPAGE.SOLVCHRGS) && (
        <IncomeWrapper
          id="ongoingDebt-wrapper"
          aria-invalid={isOngoingDebtError}
        >
          <NumberInput
            id="ongoingDebt"
            label={t('incomeAndExpensesLong.ongoingDebt.label')}
            placeholder={t('incomeAndExpensesLong.ongoingDebt.placeholder')}
            onChange={onChangeOngoingDebt}
            value={formik.values.ongoingDebt}
            errorMessage={formik.errors.ongoingDebt}
            isInvalid={isOngoingDebtError}
            onBlur={onHideOutgoingDebt}
            onFocus={onOngoingDebtFocus}
            replacement={replacement}
            maxlength={5}
            with-reset
          />
          {isOngoingDebtFocused ? (
            <IncomeDetailsInfoWrapper>
              <BubbleInfo
                id="bubbleinfo-income"
                title={t('incomeAndExpensesLong.ongoingDebt.tooltip.title')}
                bubbleItems={[
                  t('incomeAndExpensesLong.ongoingDebt.tooltip.car'),
                  t('incomeAndExpensesLong.ongoingDebt.tooltip.secondaryHome'),
                  t('incomeAndExpensesLong.ongoingDebt.tooltip.consumption'),
                ]}
                marker="default"
                isCustomedMarker={false}
              />
            </IncomeDetailsInfoWrapper>
          ) : (
            <></>
          )}
        </IncomeWrapper>
      )}
      {!fields.includes(SOLVALPAGE.SOLVCHRGS) && (
        <IncomeWrapper
          id="childSupport-wrapper"
          aria-invalid={isChildSupportError}
        >
          <NumberInput
            id="childSupport"
            label={t('incomeAndExpensesLong.childSupport.label')}
            placeholder={t('incomeAndExpensesLong.childSupport.placeholder')}
            onChange={onChangeChildSupport}
            value={formik.values.childSupport}
            errorMessage={formik.errors.childSupport}
            isInvalid={isChildSupportError}
            onFocus={onChildSupportFocus}
            replacement={replacement}
            maxlength={5}
            with-reset
          />
        </IncomeWrapper>
      )}
      {fields.includes(SOLVALPAGE.SOLVCHRGS) && (
        <div>
          <InputNumber
            id="monthlyExpenses"
            key={`monthlyExpenses-${formik.touched.monthlyExpenses}`}
            errorMessage={formik.errors.monthlyExpenses}
            label={t('incomeAndExpensesLong.form.fields.monthlyExpenses')}
            placeholder={t(
              'incomeAndExpensesLong.form.placeholder.monthlyExpenses'
            )}
            afterText="€ / mois"
            value={
              formik.values.monthlyExpenses !== undefined
                ? String(formik.values.monthlyExpenses)
                : undefined
            }
            onChange={(value) => formik.setFieldValue('monthlyExpenses', value)}
            maxLength={5}
            onFocus={onExpensesFocus}
            onBlur={onExpensesBlur}
            touched={formik.touched.monthlyExpenses}
            withResetButton
            isInteger
          />
          <CSSTransition
            in={showExpensesBubbleInfo}
            nodeRef={expensesDetailsRef}
            timeout={225}
            unmountOnExit
            classNames="animation"
          >
            <IncomeDetailsInfoWrapper ref={expensesDetailsRef}>
              <BubbleInfo
                id="bubbleinfo-income"
                title={`${t('incomeAndExpensesLong.form.bubbleInfos.report')}`}
                bubbleItems={[
                  t(
                    'incomeAndExpensesLong.form.bubbleInfos.rentOrMonthlyMortagePayement'
                  ),
                  t(
                    'incomeAndExpensesLong.form.bubbleInfos.loanInProgressNotRealEstate'
                  ),
                  t('incomeAndExpensesLong.form.bubbleInfos.careExpenses'),
                ]}
                marker="default"
                isCustomedMarker={false}
              />
            </IncomeDetailsInfoWrapper>
          </CSSTransition>
        </div>
      )}
      <NavigationButtonsWrapper data-testid="navigation-buttons">
        <NavigationButtons
          id="navigation-buttons"
          key={`nav-buttons-${
            isDirty && formik.isValid ? 'isValid' : 'isInvalid'
          }`}
          validateLabel={t('common.validate')}
          isLoading={isActionLoading}
          validateAction={() => {
            setAllFieldsTouched(formik.values, formik.setTouched);
            formik.handleSubmit();
          }}
          backLabel={t('common.back')}
          backAction={goBackToStep}
          isDisabled={!isDirty || !formik.isValid}
        />
      </NavigationButtonsWrapper>
    </FormWrapper>
  );
};

export default IncomesExpensesLongForm;
