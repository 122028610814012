import { CSSProperties } from 'styled-components';
import { MouseEventHandler } from 'react';
import { StyledButton, StyledButtonContainer } from './styles';
import { useBreakpoints } from '@sweb-front/hooks';

export type ButtonProps = {
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
  isDisabled?: boolean;
  isBold?: boolean;
  isRounded?: boolean;
  backgroundColor?: string;
  buttonType?: 'filled' | 'border' | 'none';
  height?: string;
  width?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
  level?: string;
  inactive?: boolean;
};

const Button = ({
  id,
  children,
  isDisabled = false,
  isBold = true,
  isRounded = true,
  buttonType = 'filled',
  height,
  width,
  inactive = false,
  type = 'button',
  onClick,
  backgroundColor,
  level,
  style,
}: ButtonProps) => {
  const { isMobile } = useBreakpoints();
  return (
    <StyledButton
      id={id}
      isDisabled={isDisabled}
      height={height}
      width={width}
      type={type}
      isBold={isBold}
      backgroundColor={backgroundColor}
      isRounded={isRounded}
      buttonType={buttonType}
      onClick={onClick ?? undefined}
      level={level}
      style={style}
      isMobile={isMobile}
      inactive={inactive}
    >
      {Array.isArray(children) ? (
        children.map((child, i) => (
          <StyledButtonContainer key={`child-${i}`}>
            {child}
          </StyledButtonContainer>
        ))
      ) : (
        <StyledButtonContainer>{children}</StyledButtonContainer>
      )}
    </StyledButton>
  );
};
export default Button;
