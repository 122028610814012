import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  & button-link {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: 'Open Sans Bold';
  }
`;

export const NextButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  flex-grow: 1;
  width: 100%;
`;

export const ValidateButtonWrapper = styled(NextButtonWrapper)`
  padding: 1.6rem 0;
  & base-button {
    padding: 1.4rem 0;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-family: 'Open Sans Bold';
    --color-button: var(--neutrals-0);
    background: ${({ children }: any) => {
      return children?.props?.disabled
        ? 'var(--neutrals-300)'
        : 'var(--main-green-500)';
    }};
    border: 1px solid
      ${({ children }: any) => {
        return children?.props?.disabled
          ? 'var(--neutrals-300)'
          : 'var(--main-green-500)';
      }};
  }
`;
