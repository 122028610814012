import { createComponent } from '@lit-labs/react';
import React from 'react';
import { CustomValidity, Replacement } from '.';

export class ComboInputDS extends HTMLElement {
  label?: string;

  required?: boolean;

  items?: string;

  filteredItems?: unknown[];

  class?: string;

  placeholder?: string;

  value: unknown;

  replacement?: string | Replacement[];

  shadow?: boolean | null;

  maxLength?: string;

  pattern?: string;

  selected?: boolean;

  field?: string;

  disabled?: boolean | null;

  customValidityPattern?: string | CustomValidity[];

  status?: 'invalid' | 'valid' | 'pending' | 'none';

  invalid?: boolean | null;

  valid?: boolean | null;

  multi?: boolean | null;

  filtration?: ({ displayedLabel }: { displayedLabel: string }) => boolean;

  constructor() {
    super();
  }
}

export const ComboInput = createComponent(React, 'combo-input', ComboInputDS, {
  onSelectedList: 'selected-list',
  onListBlur: 'element-blured',
  onInputChange: 'input-changed',
  onFocus: 'element-focused',
  onLetterChanged: 'letter-changed',
  onItemsChanged: 'items-changed',
});
