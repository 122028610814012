import styled from 'styled-components';
import { LoaderIcon } from '../Icons';

export const WrapperLoader = styled.div<{
  isTextInline: boolean;
  isLoading: boolean;
}>`
  position: relative;
  display: ${({ isLoading }) => (!isLoading ? 'none' : '')};
  width: ${({ isLoading }) => (isLoading ? '' : '100%')};
  height: ${({ isLoading }) => (isLoading ? '' : '100%')};
`;

export const Background = styled.div<{
  $isLoading: boolean;
  $isTextInline: boolean;
  $isHideBackground: boolean;
}>`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: ${({ $isTextInline }) => ($isTextInline ? 'row' : 'column')};
  background-color: ${({ $isHideBackground }) =>
    $isHideBackground ? 'rgba(255, 255, 255, 0.9)' : 'inherit'};

  opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0')};

  transition: opacity 0.2s;

  pointer-events: ${({ $isLoading }) => ($isLoading ? 'auto' : 'none')};
`;

export const WrapperLoaderIcon = styled(LoaderIcon)<{
  isBig: boolean;
  animationDuration: number;
}>`
  width: ${({ isBig }) => (isBig ? '4.7rem' : '3.5rem')};
  color: ${({ theme }) => theme.vendor.colors.primary};

  animation: infinite-rotate
    ${({ animationDuration }) => `${animationDuration}s`} linear infinite;
  /* animation-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1); */

  /* Standard syntax */
  @keyframes infinite-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const PrimaryText = styled.div`
  font-size: 1.8rem;
  font-family: 'Open Sans Bold';
  text-align: center;
  color: var(--neutrals-700);
`;

export const SecondaryText = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: var(--neutrals-500);
`;
