import { useTranslation } from 'react-i18next';
import GenericInsuranceContainer from '../../GenericInsuranceContainer';

import { IInsuranceCmpProps } from '../OptionalInsuranceCard';
import useConstants from '../useConstants';

const InsuranceDimc = ({
  onSelectOption,
  onReturnClick,
  value,
  priceDetails,
}: IInsuranceCmpProps) => {
  const { t } = useTranslation();
  const { documents, offerDetails, options } = useConstants(priceDetails, true);

  return (
    <GenericInsuranceContainer
      offerTitle={t('insurance.details.dimcTitle')}
      offerDetails={offerDetails}
      documents={documents}
      options={options}
      value={value}
      onReturnClick={onReturnClick}
      onSelectOption={() => onSelectOption(value)}
    />
  );
};

export default InsuranceDimc;
