import { IAstrickNote } from '@sweb-front/types';
import { ASTRICK_NOTES } from '@sweb-front/services';
import AsterikWrapper, { AsteriksWrapper } from './styles';

interface IAstrickNotesProps {
  astrickLetters?: string[];
}

const AsterikNote: React.FC<IAstrickNote> = ({
  reference,
  meaning,
}: IAstrickNote) => {
  return (
    <AsterikWrapper>
      * {reference} : {meaning}
    </AsterikWrapper>
  );
};

export const AsterikNotes: React.FC<IAstrickNotesProps> = ({
  astrickLetters,
}: IAstrickNotesProps) => {
  return (
    <AsteriksWrapper>
      {astrickLetters?.map((astrickLetter, index) => {
        const astrikNoteFromLetter: IAstrickNote | undefined =
          ASTRICK_NOTES.find(
            (astrickNote) => astrickNote.reference === astrickLetter
          );
        return (
          astrikNoteFromLetter && (
            <AsterikNote
              reference={astrikNoteFromLetter?.reference}
              meaning={astrikNoteFromLetter?.meaning}
              key={`ast_${index + 1}`}
            />
          )
        );
      })}
    </AsteriksWrapper>
  );
};
