export const getUrlRedirectToPopPage = (
  path: string,
  returnUrlBasename?: string
): string => {
  if (returnUrlBasename) {
    return `${returnUrlBasename}/${path}`;
  }
  return '#';
};

export const getAbsoluteUrlRedirectToPopPage = (
  path: string,
  returnUrlBasename?: string
): string => {
  if (returnUrlBasename) {
    const domain = new URL(returnUrlBasename);
    return `${domain.protocol}//${domain.hostname}:${domain.port}/${path}`;
  }
  return '#';
};

const redirecToPopPage = (path: string, isRelativePath: boolean): void => {
  // TODO : invalider la session BFF et revoker le token 2 APIM

  const url = isRelativePath
    ? getUrlRedirectToPopPage(path)
    : getAbsoluteUrlRedirectToPopPage(path);

  sessionStorage.clear();
  window.location.replace(url);
};

export const redirectToPopHome = (): void => {
  redirecToPopPage('accueil', true);
};

export const logout = (): void => {
  redirecToPopPage('c/portal/logout', false);
};
