import React, { CSSProperties, ReactElement } from 'react';
import { InformationBubbleWrapper, SVGIconWrapper } from './styles';
import { ActionWarningIcon } from '../Icons';

export interface InformationBubbleProps {
  children;
  style?: CSSProperties | null;
  icon?: ReactElement | null;
  className?: string;
}

const InformationBubble = ({
  children,
  style,
  icon,
  className,
}: InformationBubbleProps) => {
  return (
    <InformationBubbleWrapper className={className} style={style}>
      <SVGIconWrapper>
        {icon != null ? icon : <ActionWarningIcon />}
      </SVGIconWrapper>
      {children}
    </InformationBubbleWrapper>
  );
};

export default InformationBubble;
