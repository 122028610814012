import { TAB } from '@sweb-front/utils';
import styled from 'styled-components';

import { CheckIcon } from '../Icons';

export const BreadcrumbContainer = styled.div<{ nbOfSteps: number }>`
  width: 100%;
  position: relative;
  z-index: 40;
  display: grid;
  row-gap: 1rem;
  grid-template-columns: repeat(${({ nbOfSteps }) => nbOfSteps}, 1fr);
  place-items: center;
  &::after {
    position: relative;
    content: '';
    z-index: -1;
    height: 0.2rem;
    width: ${({ nbOfSteps }) => (nbOfSteps - 1) * 100}%;
    transform: translate(50%, -4.95rem);
    background-color: ${({ theme }) => theme.vendor.colors.stepline};
  }
  @media only screen and ${TAB} {
    &::after {
      transform: translate(50%, -5.3rem);
    }
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 90%;
  height: 85%;
  stroke: white;
`;

export const StyledIconContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  background-color: ${({ theme }) => theme.vendor.colors.primary};
  color: white;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  border: 0.2rem solid ${({ theme }) => theme.vendor.colors.primary};
  @media only screen and ${TAB} {
    width: 3.2rem;
    height: 3.2rem;
    font-size: 1.6rem;
  }
`;

export const SmallCircle = styled(StyledIconContainer)`
  border: none;
  background-color: ${({ theme }) => theme.vendor.colors.stepline};
  width: 0.8rem;
  height: 0.8rem;
  @media only screen and ${TAB} {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const Circle = styled(StyledIconContainer)`
  border-color: ${({ theme }) => theme.vendor.colors.primary};
  background-color: white;
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-size: 1.1rem;
  font-family: 'Open Sans Bold';
  @media only screen and ${TAB} {
    font-size: 1.6rem;
  }
`;

export const StepLabel = styled.span<{ isGreyed: boolean }>`
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme, isGreyed }) =>
    isGreyed ? theme.vendor.colors.stepline : theme.vendor.colors.primary};
`;
