import { useBreakpoints } from '@sweb-front/hooks';
import {
  BreadcrumbContainer,
  Circle,
  SmallCircle,
  StepLabel,
  StyledCheckIcon,
  StyledIconContainer,
} from './styles';

type Step = {
  label: string;
};

export type BreadcrumbProps = {
  steps: Step[];
  currentStep?: number;
};

export const Breadcrumb = ({ steps, currentStep = 1 }) => {
  const { isMobile } = useBreakpoints();
  return (
    <BreadcrumbContainer nbOfSteps={steps?.length}>
      {steps?.map((_, index) => {
        if (currentStep - 1 > index) {
          return (
            <StyledIconContainer
              className={
                index > 0 && index < steps.length - 1 ? `after-line` : ''
              }
              key={`breadcrumb-icon-${index}`}
            >
              <StyledCheckIcon />
            </StyledIconContainer>
          );
        }
        if (currentStep - 1 === index) {
          return (
            <Circle
              key={`breadcrumb-icon-${index}`}
              className={
                index > 0 && index < steps.length - 1 ? `after-line` : ''
              }
            >
              {currentStep}
            </Circle>
          );
        }
        return (
          <SmallCircle
            key={`breadcrumb-icon-${index}`}
            className={
              index > 0 && index < steps.length - 1 ? `after-line` : ''
            }
          />
        );
      })}
      {steps?.map((step, index) =>
        isMobile ? (
          index === currentStep - 1 ? (
            <StepLabel key={`breadcrumb-label-${index}`} isGreyed={false}>
              {step.label}
            </StepLabel>
          ) : (
            <div />
          )
        ) : (
          <StepLabel
            key={`breadcrumb-label-${index}`}
            isGreyed={index > currentStep - 1}
          >
            {step.label}
          </StepLabel>
        )
      )}
    </BreadcrumbContainer>
  );
};
