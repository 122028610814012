import { useEffect } from 'react';
import { Modal, Section } from '@sweb-front/components';
import { useTranslation } from 'react-i18next';
import { ModalWrapper, useAppOptions } from '@vat/utils';
import useLiveness from './useLiveness';
import {
  LogoMainWrapper,
  ButtonWrapper,
  ActionButtonWrapper,
  NonButtonWrapper,
  DescriptionWrapper,
} from './style';
import './liveness.css';
import { useAppSelector } from '@sweb-front/store';
import { StyledButtonLoader } from '@sweb-front/styles';
import { usePageRedirection } from '@sweb-front/hooks';
import { LIVENESS } from '@vat/configuration';

export interface ILivenessProps {
  token: string;
}

const Liveness = ({ token }: ILivenessProps) => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { person } = opportunity;
  const { setAppOptions } = useAppOptions();

  const parameters = useAppSelector((state) => state.parameters.state);
  const isVATRib = parameters?.wayCd?.toLowerCase().includes('rib');

  const { isPageLoading } = usePageRedirection(LIVENESS, {
    locked: true,
  });

  useEffect(() => {
    setAppOptions({
      isMounted: !isPageLoading,
    });
  }, [isPageLoading]);

  const {
    isLoading,
    isOrange,
    showModal,
    itemClicked,
    onLivenessAuthorized,
    onShowModalFn,
    onRefuseLiveness,
  } = useLiveness(
    token,
    person?.personId ?? '',
    isVATRib,
    opportunity,
    parameters
  );

  return (
    !isPageLoading && (
      <>
        <Section
          id="liveness-section"
          title={t('liveness.title')}
          description={t('liveness.description')}
        >
          <LogoMainWrapper>
            <img src="./assets/images/person-check.png" alt="person-check" />
          </LogoMainWrapper>
          <ActionButtonWrapper className="liveness-button-wrapper">
            <ButtonWrapper
              onClick={onLivenessAuthorized}
              id="begin-liveness-button"
            >
              {!showModal && isLoading && itemClicked === 'authorize' ? (
                <StyledButtonLoader
                  isLoading
                  isTextInline
                  isHideBackground={false}
                />
              ) : (
                t('liveness.buttons.checkIdentity')
              )}
            </ButtonWrapper>
            <NonButtonWrapper onClick={onShowModalFn}>
              {t('liveness.buttons.refuseChecking')}
            </NonButtonWrapper>
          </ActionButtonWrapper>
        </Section>
        {showModal && (
          <Modal
            isFullScreen={false}
            isVisible={showModal}
            handleCloseModal={onShowModalFn}
            isClosable
            isMobile
          >
            <ModalWrapper>
              <DescriptionWrapper>
                {t('livenessPop.warning')}
              </DescriptionWrapper>
              {isOrange && (
                <DescriptionWrapper>
                  {t('livenessPop.orangeWarning')}
                </DescriptionWrapper>
              )}
              <ActionButtonWrapper className="modal-button-actions">
                <ButtonWrapper
                  onClick={onLivenessAuthorized}
                  id="continue-verification-button"
                >
                  {showModal && isLoading && itemClicked === 'authorize' ? (
                    <StyledButtonLoader
                      isLoading
                      isTextInline
                      isHideBackground={false}
                    />
                  ) : (
                    t('liveness.buttons.continueChecking')
                  )}
                </ButtonWrapper>
                <NonButtonWrapper onClick={onRefuseLiveness}>
                  {showModal && isLoading && itemClicked === 'not-authorize' ? (
                    <StyledButtonLoader
                      isLoading
                      isTextInline
                      isHideBackground={false}
                    />
                  ) : (
                    t('liveness.buttons.cancelLoanRequest')
                  )}
                </NonButtonWrapper>
              </ActionButtonWrapper>
            </ModalWrapper>
          </Modal>
        )}
      </>
    )
  );
};

export default Liveness;
