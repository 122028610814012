import { Title } from '@sweb-front/components';
import { TAB, DESKTOP } from '@sweb-front/utils';
import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.8rem;
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  text-align: left;
  font-family: 'Open Sans';
  color: var(--neutrals-500);
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;

  span.expired-vatrib,
  span.expired-vatcb {
    margin-top: calc(-2.4rem + 16px);
  }

  span.error-vatrib,
  span.error-vatcb {
    margin-top: calc(-2.4rem + 16px);
  }

  @media screen and ${TAB} {
    text-align: center;
    margin: 0 2.5rem;
  }

  @media screen and ${DESKTOP} {
    text-align: center;
    margin: 0 6rem;
  }
`;

export const TitleCmp = styled((props) => <Title {...props} />)`
  padding: 0;
  margin: 0;
  margin-top: 4rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-family: 'Ubuntu Bold';
  @media screen and ${TAB} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;
