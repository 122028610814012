export type PricingModalities = 'Gratuite' | 'Payante';

export enum TypeOfOffer {
  GRATUIT = 'G',
  PAYANT = 'P',
  COMPTANT = 'comptant',
  CARTE_SECHE = 'carte seche',
}
export enum CardChoice {
  Classic = 'M',
  Gold = 'G',
  Standard = 'standard',
}

// Données contextuelles
export interface EventParams {
  event: string; // pageLoaded,
  stepNumber?: string; // stepNumber:1.2,	contexte page
  pageName?: string; // $pageName,	pageName:DDF_MAF ou DCM_MAF : Formulaire : Choix offre : Offres disponibles,	contexte page
  offersNumber?: number; // offersNumber:0,	Contexte UX
  displayTime?: number; // displayTime:12	Contexte UX
  cardChoice?: CardChoice; // $cardChoice,	cardChoice:standard,	contexte demande credit
  CreditType?: string; // $CreditType,	CreditType:CR,	contexte demande credit
  categorie?: string | null; // $categorie,	categorie:Menager,	contexte demande credit
  sousCategorie?: string | null; // $sousCategorie,	sousCategorie:Refrigerateur,	contexte demande credit
  dossierComplet?: 'oui' | 'non'; // $dossierComplet,	dossierComplet:non,	contexte demande credit
  Amount?: number; // $Amount,	Amount:4000.00,	contexte demande credit
  formName?: string; // $formName,	formName:DDF_MAF_CR ou DCM_MAF,	contexte demande credit
  rate?: number; // taux débiteur fixe du crédit
  term?: number; // durée du crédit en nombre de mois (ex: 6 mois)
  monthlyPayment?: number; // montant mensuel hors assurance
  simulationNumber?: number | null; // Place de la simulation sélectionnée
  simplifiedRun?: 'oui' | 'non'; // type du parcours (oui : parcours simplifié, non: parcours complet)
  simulatorInteraction?: string;
  pricingModalities?: PricingModalities; // 'Gratuite' ou 'Payante
  apport?: number;
  report?: number;
  processingTime?: number;
  retrievalResult?: string;
  completionArea?: string;
  completionTitleArea?: string;
  requestId?: string;
  typeOfError?: string;
  errorField?: string;

  // OCR
  fileType?: string; // type du fichier uploadé ou en erreur (ex: CNI, RIB, etc...)
  fileSize?: string; // taille du fichier uploadé ou en erreur
  fileFormat?: string; // format du fichier uploadé ou en erreur (ex: PDF, JPG, etc...)
  fileError?: string; // libellé de l'erreur du fichier uploadé (ex: pièce expirée, etc...)

  // FormIdentity
  prepopulatedFields?: number; // nombre de champs préremplis dans le FormIdentity après un OCR
  applicationScore?: string; // score du dossier

  // Insurance
  typeOfInsurance?: string;

  borrowerNumber?: string; // $borrower, Nombre d'emprunteurs : "1" ou "2"
  stakeholder?: string; // $stakeholder, Catégorie de visiteurs qui se rend sur le parcours

  // Modal
  modaleName?: string; // nom de la modale
}

// Données présentes dans chaque event presque systématiquement
export interface Event extends EventParams {
  site?: string; // $site,	site:DDF_MAF ou DCM_MAF,	contexte parcours
  environment: 'dev' | 'prod'; // $environment,	environment:prod,	contexte parcours
  visitorStatus: 'loggued' | 'non-loggued'; // $visitorStatus	visitorStatus:loggued	contexte parcours
  modeTest: 'oui' | 'non'; // $modeTest,	modeTest:oui,
  ContributorCode?: string; // $ContributorCode,	ContributorCode:1004697504,	contexte enseigne
  AtosId?: string; // $AtosId,	AtosId:344c3f5a9d06e76b48d9f2826,	contexte demande credit
}
