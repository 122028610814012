import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseRadioButtonDS extends HTMLElement {
  checked?: boolean | null;

  label?: string;

  class?: string;
}

export const BaseRadioButton = createComponent(
  React,
  'base-radio-button',
  BaseRadioButtonDS,
  {
    onChecked: 'checked',
  }
);
