import { ButtonLink, GenderInput, Input } from '@sweb-front/components';
import { ButtonWrapper, StyledButtonLoader } from '@sweb-front/styles';
import {
  ButtonActionWrapper,
  StyledBackButtonWrapper,
  StyledValidateButtonWrapper,
} from '@vat/utils';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@sweb-front/store';
import useHousingForm from './useHousingForm';
import { HousingEntryDateWrapper, HousingStatusChoiceWrapper } from './styles';

export const HOUSING_FORM_VALUES = [
  { label: 'Propriétaire avec prêt immo', value: 'A' },
  { label: 'Propriétaire sans prêt immo', value: 'P' },
  { label: 'Locataire', value: 'L' },
  { label: 'Logé(e) par la famille', value: 'F' },
  { label: "Logé(e) par l'employeur", value: 'E' },
  { label: 'Autre', value: 'D' },
];

const HousingForm = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const birthDate = opportunity.person?.personalInformation.birthDt;
  const {
    formik,
    isFormValid,
    isHousingEntryDtError,
    isHousingStatusCdError,
    housingEntryDtErrorMsg,
    submitButtonClass,
    isActionLoading,
    onHousingEntryDtChanged,
    onHousingEntryDtBlurred,
    onSubmit,
    goBackToStep,
  } = useHousingForm(
    opportunity,
    {
      validateOnBlur: true,
      validateOnChange: true,
    },
    birthDate
  );
  return (
    <form onSubmit={onSubmit}>
      <HousingStatusChoiceWrapper
        id="housingStatusCd-wrapper"
        aria-invalid={isHousingStatusCdError}
      >
        <GenderInput
          id="housingStatusCd"
          label={t('housing.housingStatusCd.label')}
          value={formik.values.housingStatusCd}
          error-message={formik.errors.housingStatusCd}
          invalid={isHousingStatusCdError || null}
          onSelectedElementChange={formik.handleChange}
          hasFocus
        >
          {HOUSING_FORM_VALUES.map(({ label, value }) => (
            <selected-button
              value={value}
              secondary="true"
              selected={
                Number(
                  formik.values.housingStatusCd?.replace(' ', '').trim()
                ) === Number(value)
              }
              key={`housing_status_${value}`}
            >
              {label}
            </selected-button>
          ))}
        </GenderInput>
      </HousingStatusChoiceWrapper>
      <HousingEntryDateWrapper
        id="housingEntryDt-wrapper"
        aria-invalid={isHousingEntryDtError}
      >
        <Input
          id="housingEntryDt"
          type="number"
          label={t('housing.housingEntryDt.label')}
          placeholder={t('housing.housingEntryDt.placeholder')}
          onChange={onHousingEntryDtChanged}
          onBlur={onHousingEntryDtBlurred}
          value={formik.values.housingEntryDt}
          errorMessage={housingEntryDtErrorMsg}
          width="9.4rem"
          maxLength={4}
          with-round
        />
      </HousingEntryDateWrapper>
      <ButtonActionWrapper>
        <StyledValidateButtonWrapper
          type="submit"
          $isDisabled={!isFormValid}
          id="solva-validate-button"
          className={submitButtonClass}
        >
          {isActionLoading ? (
            <StyledButtonLoader
              isLoading
              isTextInline
              isHideBackground={false}
            />
          ) : (
            t('common.validate')
          )}
        </StyledValidateButtonWrapper>
        <StyledBackButtonWrapper>
          <ButtonWrapper className="back-button-wrapper">
            <ButtonLink
              label={t('common.back')}
              onClick={goBackToStep}
              id="back-button"
            />
          </ButtonWrapper>
        </StyledBackButtonWrapper>
      </ButtonActionWrapper>
    </form>
  );
};
export default HousingForm;
