import styled from 'styled-components';

export const CheckedListItemWrapper = styled.div`
  display: flex;
`;

export const CheckedListItemTitleIconWrapper = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
`;

export const SVGIconWrapper = styled.div`
  & base-icon {
    margin-right: 0.8rem;
    margin-top: 0.4rem;
    --color-icon: var(--main-green-500);
  }
`;

export const ContentWrapper = styled.div``;

export const DescriptionWrapper = styled.div`
  font-family: 'Open Sans';
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  padding-bottom: 1.6rem;
`;

export const TitleWrapper = styled.div`
  font-family: 'Open Sans Bold';
  font-size: 1.6rem;
  margin-top: 0.4rem;
  line-height: 2.4rem;
  color: var(--main-green-500);
`;
