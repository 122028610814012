import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseButtonDS extends HTMLElement {
  clickable?: boolean;

  secondary?: boolean | null;

  disabled?: boolean | null;

  class?: string | null;

  icon?: string | null;
}

export const BaseButton = createComponent(
  React,
  'base-button',
  BaseButtonDS,
  {}
);
