const SvgAdvice: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.39 11.561c0-3.283 2.519-5.96 5.632-5.96 3.113 0 5.632 2.677 5.632 5.96 0 2.56-1.543 4.788-3.781 5.614v.62a.597.597 0 01-.591.603h-2.52a.597.597 0 01-.591-.603v-.62C7.933 16.349 6.39 14.12 6.39 11.56zm6.301 5.172c0-.272.179-.511.437-.582 1.954-.54 3.344-2.41 3.344-4.59 0-2.627-2.001-4.753-4.45-4.753-2.45 0-4.45 2.126-4.45 4.753 0 2.18 1.39 4.05 3.344 4.59.257.07.436.31.436.582v.459h1.339v-.459zm-.278 4.06c.326 0 .59.27.59.604a.597.597 0 01-.59.603h-.826a.597.597 0 01-.59-.603c0-.333.264-.603.59-.603h.826zm.87-1.8c.325 0 .59.27.59.603a.597.597 0 01-.59.603h-2.565a.597.597 0 01-.59-.603c0-.333.264-.603.59-.603h2.564zM11.43 2.603c0-.333.265-.603.59-.603.327 0 .592.27.592.603v1.8a.597.597 0 01-.591.604.597.597 0 01-.591-.603v-1.8zm8.978 8.355c.326 0 .591.27.591.603a.597.597 0 01-.59.603h-1.696a.597.597 0 01-.59-.603c0-.333.264-.603.59-.603h1.695zM3.591 12.164A.597.597 0 013 11.561c0-.333.265-.603.59-.603h1.696c.326 0 .59.27.59.603a.597.597 0 01-.59.603H3.59zm1.443-7.157a.612.612 0 01.016-.853.582.582 0 01.836.017l1.782 1.893a.612.612 0 01-.017.853.582.582 0 01-.836-.017L5.034 5.007zm13.08-.836c.226-.24.6-.248.836-.017.235.23.243.612.017.853L17.185 6.9a.582.582 0 01-.836.017.612.612 0 01-.016-.853l1.781-1.893z"
    />
  </svg>
);

export default SvgAdvice;
