import {
  useBreakpoints,
  useLoadingHook,
  useManagingStep,
  useUrlWebMarchand,
} from '@sweb-front/hooks';
import {
  IOpportunityState,
  IParameterState,
  IReferentielState,
  selectNavigation,
  selectSteps,
  setAddress,
  setAutocompletefieldValue,
  setCities,
  setCountries,
  setDepartements,
  setEmail,
  setEndParcours,
  setIsFilledWithAutoComplete,
  setNationalities,
  setPerson,
  setPhone,
  updateOpportunityStatus,
  updateParcoursNavigation,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import {
  ErrorContext,
  capitalize,
  nameRegex,
  phoneFormatter,
  useAppOptions,
  useCloseOpportunity,
} from '@vat/utils';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';

import { COUNTRY_ID } from '@sweb-front/services';
import { findPriOverdraftAmount, trackEvent } from '@sweb-front/utils';
import {
  INFORMATIONPAGE,
  INFORMATIONS,
  KYCEDITABLEFIELDS,
  OPPORTUNITYSTATUS,
  SECTORCODES,
  SECTORLIST,
} from '@vat/configuration';
import { sha256 } from 'js-sha256';
import {
  fetchData,
  getMatchedItem,
  pad,
  postData,
  removeSpecialCharacters,
  replaceAccents,
  setIdHashed,
} from '../utils';
import { CHANELTYPE, IOpportunity } from '@sweb-front/types';
import { isMobile as isMobileDevice } from 'react-device-detect';

const computeDate = (date: Date) => {
  const day = date.getDate();
  const computedDay = day < 10 ? `0${day}` : day;
  const month = date.getMonth() + 1;
  const computedMonth = month < 10 ? `0${month}` : month;
  return `${date.getFullYear()}-${computedMonth}-${computedDay}`;
};

const TITLE_VALUES = ['MME', 'MR'] as const;

const INSTANT_DATE = new Date(Date.now());
const MAX_DATE = (() => {
  const date = new Date(INSTANT_DATE);
  date.setFullYear(INSTANT_DATE.getFullYear() - 18);
  return date;
})();
const MIN_DATE = (() => {
  const date = new Date(INSTANT_DATE);
  date.setFullYear(INSTANT_DATE.getFullYear() - 100);
  date.setDate(date.getDate() - 1);
  return date;
})();

export type UseInformationFormOptions = {
  opportunity: IOpportunityState;
  referentiels: IReferentielState;
  parameters: IParameterState;
  fields;
};

export const useInformationForm = ({
  opportunity,
  referentiels,
  parameters,
  fields,
}: UseInformationFormOptions) => {
  const appNavUtils = useAppSelector(selectNavigation);
  const [showAutoCompletedField, setShowAutoCompletedField] = useState<boolean>(
    appNavUtils.isFilledWithAutoComplete ?? true
  );
  const [autocompleteValue, setAutocompleteValue] = useState<string>();
  const [selectedRecordAutocomplete, setSelectedRecordAutocomplete] =
    useState<Record<string, string>>();

  const { t } = useTranslation();
  const { isDesktop } = useBreakpoints();
  const { setAppOptions } = useAppOptions();
  const { goToNextStep, goToWebMarchand, goBackToStep } = useManagingStep();
  const dispatch = useAppDispatch();
  const { getUrlCancel } = useUrlWebMarchand();
  const updateError = useContext(ErrorContext);
  const [isPopUpLeaveOpened, setIsPopUpLeaveOpened] = useState(false);
  const [isPopUpEditOpened, setIsPopUpEditOpened] = useState(false);
  const { person } = opportunity;
  const location = useLocation();
  const [dopcOptin, setDopcOptin] = useState({
    optin: person?.cellPhone?.useBo ?? false, // we just need the cellPhone or email as they will have the same behaviour and value for the optin
    dopc: person?.address?.useBo ?? false, // dopc
  });
  const steps = useAppSelector(selectSteps);
  const { webAPageEvent } = useAppSelector((store) => store.navigation.state);
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const [isBirthNamaFocused, setIsBirthNameFocused] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentStep = (steps ?? []).findIndex((st) =>
    st.paths.includes(location.pathname)
  );

  const sectors = SECTORLIST.map((sector) => ({
    value: SECTORCODES[sector],
    label: t(`basicInformations.sectorList.${sector}`),
  }));

  const onUpdateOptin = (value) => {
    setDopcOptin((prev) => ({
      ...prev,
      optin: value,
    }));
    dispatch(
      setEmail({
        ...person.email,
        useBo: value,
      })
    );
    dispatch(
      setPhone({
        ...person.cellPhone,
        useBo: value,
      })
    );
  };

  const onUpdateDopc = (value) => {
    setDopcOptin((prev) => ({
      ...prev,
      dopc: value,
    }));
    dispatch(
      setAddress({
        ...person.address,
        useBo: value,
      })
    );
  };

  const computedPersonalInformation = {
    firstName: capitalize(person?.personalInformation.firstName) ?? '',
    lastName: capitalize(person?.personalInformation.name) ?? '',
    details: {
      personal: [
        {
          title: t('basicInformations.address'),
          description: capitalize(person?.address?.streetAddress ?? ''),
        },
        {
          title: t('basicInformations.postalCode'),
          description: person?.address?.zipCd,
        },
        {
          title: t('basicInformations.city'),
          description: capitalize(person?.address?.city ?? ''),
        },
        {
          title: t('basicInformations.country'),
          description: person?.address?.countryCd
            ? getMatchedItem(
                person?.address.countryCd,
                'test',
                referentiels?.countries
              )?.name ?? capitalize(person?.address.countryCd)
            : '',
        },
      ],
      content: [
        {
          title: t('basicInformations.email'),
          description: (person?.email?.address ?? '').toLocaleLowerCase(),
        },
        {
          title: t('basicInformations.phoneNumber'),
          description: phoneFormatter(person?.cellPhone?.phoneNb ?? ''),
        },
      ],
    },
  };

  const schema = z.object({
    title: z.enum(TITLE_VALUES, {
      required_error: t('basicInformations.errors.civility'),
    }),
    birthName: z
      .string({
        required_error: t('basicInformations.errors.birthName.required'),
      })
      .regex(nameRegex, t('basicInformations.errors.birthName.format'))
      .min(2, t('basicInformations.errors.birthName.size'))
      .max(30, t('basicInformations.errors.birthName.size')),
    birthDt: z
      .date({
        errorMap: (issue, ctx) => {
          switch (issue.code) {
            case 'invalid_date':
              return {
                message: t('basicInformations.errors.birthDt.incorrectDate'),
              };
            default:
              return {
                message:
                  ctx.defaultError === 'Required'
                    ? t('basicInformations.errors.birthDt.required')
                    : t('basicInformations.errors.incorrectDate'),
              };
          }
        },
      })
      .min(MIN_DATE, t('basicInformations.errors.birthDt.ageLimit100'))
      .max(MAX_DATE, t('basicInformations.errors.birthDt.lessThan18')),
    birthCountryIsoCd: z.string({
      required_error: t('basicInformations.errors.birthCountry'),
    }),
    birthDepartment: z
      .string({
        required_error: t('basicInformations.errors.birthDepartment'),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (formik.values.birthCountryIsoCd === 'FR' && value !== undefined)
          return;
        if (formik.values.birthCountryIsoCd !== 'FR') return;
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('basicInformations.errors.birthDepartment'),
        });
      }),
    birthCity: z
      .string({
        required_error: t('basicInformations.errors.birthCity.required'),
        invalid_type_error: t('basicInformations.errors.birthCity.format'),
      })
      .regex(nameRegex, t('basicInformations.errors.birthCity.format'))
      .min(2, t('basicInformations.errors.birthCity.minChar'))
      .max(30, t('basicInformations.errors.birthCity.maxChar')),
    birthCityInseeCd: z.string().optional(),
    nationalityIsoCd: z.string({
      required_error: t('basicInformations.errors.nationality'),
    }),
    activitySector: z
      .string({
        required_error: t('basicInformations.errors.activitySectors'),
        invalid_type_error: t('basicInformations.errors.activitySectors'),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (
          fields.includes(INFORMATIONPAGE.KYCSOLVACT) &&
          value === undefined
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('basicInformations.errors.activitySectors'),
          });
        }
      }),
    autoCompleteField: z.string().optional(),
  });

  type InformationFormSchema = z.infer<typeof schema>;

  const initialValues = {
    title: person?.personalInformation?.title as InformationFormSchema['title'],
    birthName: person?.personalInformation?.birthName || undefined,
    birthDt: person?.personalInformation?.birthDt
      ? new Date(person?.personalInformation?.birthDt)
      : undefined,
    birthCountryIsoCd: person?.personalInformation?.birthCountryIsoCd
      ? person?.personalInformation?.birthCountryIsoCd
      : undefined,
    birthDepartment: person?.personalInformation?.birthDepartment
      ? person?.personalInformation?.birthDepartment
      : undefined,
    birthCity: person?.personalInformation?.birthCity
      ? person?.personalInformation?.birthCity
      : undefined,
    birthCityInseeCd: person?.personalInformation?.birthCityInseeCd
      ? person?.personalInformation?.birthCityInseeCd
      : undefined,
    nationalityIsoCd: person?.personalInformation?.nationalityIsoCd
      ? person?.personalInformation?.nationalityIsoCd
      : 'FR',
    activitySector: person?.profession?.ihmSector,
    autoCompleteField:
      (appNavUtils?.autoCompleteBirthCityValue?.autocompleteValue as string) ??
      '',
  } as const;

  const initialTouched = Object.entries(initialValues)
    .map(([key, value]) =>
      value !== undefined && value !== '' ? key : undefined
    )
    .filter((item) => item !== undefined)
    .reduce(
      (acc, item) => (item !== undefined ? { ...acc, [item]: true } : acc),
      {}
    );

  const validate = (values: unknown) => {
    const result = schema.safeParse(values);
    if (result.success === true) {
      return;
    }
    const errors = result.error.errors.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.path[0]]: curr.message,
      };
    }, {});
    return errors;
  };

  const onSubmit = (values: InformationFormSchema) => {
    const birthDtXtn = `${new Date(
      person.personalInformation.birthDt
    ).getFullYear()}${pad(
      new Date(person.personalInformation.birthDt).getMonth() + 1
    )}${pad(new Date(person.personalInformation.birthDt).getDate())}`;
    // Here we are sure to get a valid birthDate so we add XTN call for userId to harmonized with the various
    // applications
    window.seapweb
      ?.setUserId(
        sha256(
          removeSpecialCharacters(
            replaceAccents(
              opportunity.person.personalInformation.name +
                opportunity.person.personalInformation.firstName +
                birthDtXtn
            )
          )
            .normalize('NFKD')
            .toLowerCase()
        )
      )
      .catch(() => {
        console.warn('seapweb::setUserId an error ocurred');
      });

    // Load the XTN method to retrieve the XTN eventId that are very important to them
    window.seapweb
      ?.closeToken(false)
      .then((eventId: string) => {
        // save to database
      })
      .catch(() => {
        console.warn(
          'seapweb::closeToken an error occured when generating the eventId !'
        );
      });

    const isBirthCountryFR = values.birthCountryIsoCd === 'FR';
    const updateOpportunity: IOpportunity = {
      persons: [
        {
          ...person,
          personalInformation: {
            ...person?.personalInformation,
            title: values.title,
            birthName: values.birthName,
            birthDt: computeDate(values.birthDt),
            birthCountryIsoCd: values.birthCountryIsoCd,
            birthDepartment: isBirthCountryFR
              ? values.birthDepartment
              : undefined,
            birthCity: values.birthCity,
            birthCityInseeCd: isBirthCountryFR
              ? values.birthCityInseeCd
              : undefined,
            nationalityIsoCd: values.nationalityIsoCd,
          },
          profession: {
            ...person?.profession,
            ihmSector: values.activitySector,
          },
        },
      ],
      isMobile: isMobileDevice,
    };

    dispatch(
      setAutocompletefieldValue({
        ...selectedRecordAutocomplete,
        autocompleteValue,
      })
    );
    updateIsActionLoading(true);
    postData(
      'vendors/opportunities/v1/opportunity',
      updateOpportunity,
      () => {
        dispatch(
          updateParcoursNavigation({
            name: INFORMATIONS,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        updateIsActionLoading(false);
        dispatch(setPerson(updateOpportunity.persons[0]));
        goToNextStep();
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: INFORMATIONS,
            loaded: true,
            actionPageDone: true,
            disabled: false,
            params: {
              hasError: true,
            },
          })
        );
        updateIsActionLoading(false);
        updateError();
      }
    );
  };

  const formik = useFormik<Partial<InformationFormSchema>>({
    initialValues,
    initialTouched,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit,
  });

  const fetchCountries = () =>
    fetchData(
      'localities/v1/countries',
      { headers: { countryID: COUNTRY_ID } },
      (data) => {
        dispatch(setCountries(data));
        dispatch(setNationalities(data));
      },
      () => {
        updateAbortErrorMessage(
          'kyc::fetchCountries an error occured when getting the countries'
        );
        updateError();
      }
    );

  const fetchDepartments = () =>
    fetchData(
      'localities/v1/departments',
      { headers: { countryID: COUNTRY_ID } },
      (data) => {
        dispatch(setDepartements(data));
      },
      () => {
        updateAbortErrorMessage(
          'kyc::fetchDepartments an error occured when getting the departments'
        );
        updateError();
      }
    );

  const fetchCities = (departementCode?: string) => {
    if (!departementCode) {
      dispatch(setCities([]));
      return;
    }
    fetchData(
      `localities/v1/${departementCode}/cities`,
      {
        headers: { countryID: COUNTRY_ID },
      },
      (data) => {
        dispatch(setCities(data));
      },
      () => {
        updateAbortErrorMessage(
          'kyc::getCities an error occured when getting the cities'
        );

        updateError();
      }
    );
  };

  const resetBirthCity = () => {
    formik.setFieldValue('birthCity', initialValues.birthCity);
    formik.setFieldValue('birthCityInseeCd', initialValues.birthCityInseeCd);
  };

  const setBirthDepartment = (value?: string) => {
    if (formik.values.birthDepartment !== value) {
      resetBirthCity();
    }
    formik.setFieldValue('birthDepartment', value);
    fetchCities(value);
  };

  const trackEventPageLoaded = () => {
    if (opportunity?.opportunityIdExt) {
      const isMono: boolean = opportunity?.bagType === 'MONO';
      const { financialDetails } = opportunity.offers[0].loans[0];
      trackEvent({
        event: webAPageEvent ?? 'pageLoaded',
        Telesales:
          (opportunity?.distributionChannelCD === CHANELTYPE.TEL)?.toString() ??
          'false',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        pageName: 'E-Commerce : Formulaire KYC',
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        ContributorCode: opportunity.distributor.distributorNb,
        Amount: (isMono
          ? financialDetails?.overdraftAmt
          : findPriOverdraftAmount(opportunity.offers[0].loans)
        )?.toString(),
        Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
        Term: isMono ? financialDetails?.term?.toString() : undefined,
        MonthlyPayment: (isMono
          ? financialDetails?.monthlyPaymentWithoutInsuranceAmt
          : undefined
        )?.toString(),
        opportunityIdHashed: setIdHashed(opportunity),
        pathType: isMono ? 'mono panier' : 'multi panier',
      });
    }
  };

  const onBlurAutocomplete = (e, rec, internalValue) => {
    if (internalValue === undefined) {
      setSelectedRecordAutocomplete(
        appNavUtils?.autoCompleteBirthCityValue
          ?.selectedRecordAutocomplete as Record<string, string>
      );
      setAutocompleteValue(
        appNavUtils?.autoCompleteBirthCityValue?.autocompleteValue as string
      );
    } else {
      setSelectedRecordAutocomplete(rec);
      setAutocompleteValue(internalValue);
    }
    formik.handleBlur(e);
  };

  const openPopUpLeave = () => {
    trackEvent({
      event: 'popinDisplay',
      modaleName: 'E-Commerce : Formulaire KYC : Retour',
    });
    setIsPopUpLeaveOpened(true);
  };

  const closePopUpLeave = () => {
    setIsPopUpLeaveOpened(false);
  };

  const openPopUpEdit = () => {
    trackEvent({
      event: 'popinDisplay',
      modaleName: 'E-Commerce : Formulaire KYC : Modifier',
    });
    setIsPopUpEditOpened(true);
    (document.body?.style as CSSStyleDeclaration).overflow = 'hidden';
  };

  const closePopUpEdit = () => {
    setIsPopUpEditOpened(false);
    (document.body?.style as CSSStyleDeclaration).overflow = 'scroll';
  };

  const returnToUrlWebMerchant = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    goToWebMarchand(
      getUrlCancel(),
      "du bouton modifier dans la vérification d'indentité",
      () => {
        dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.ANUL));
      },
      () => {
        dispatch(setEndParcours(true));
        updateError();
      }
    );
  };

  useEffect(() => {
    fetchCountries();
    fetchDepartments();
    fetchCities(formik.values.birthDepartment);
    trackEventPageLoaded();
    setAppOptions((state) => ({ ...state, isMounted: true }));
    return () => {
      setAppOptions((state) => ({ ...state, isMounted: false }));
    };
  }, []);

  const onChangeAutoCompletedField = async (res, concatenedRes) => {
    formik.setFieldValue(
      KYCEDITABLEFIELDS.BirthCityInseeCd,
      res?.codePays === 'FR' ? res?.codeInsee : ''
    );
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthDepartment, res?.province);
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCountry, res?.codePays);
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCity, res?.localite);
    setSelectedRecordAutocomplete(res);
    setAutocompleteValue(concatenedRes);
  };

  const onSuggestionAutoCompletedFieldClick = () => {
    setShowAutoCompletedField(false);
    dispatch(setIsFilledWithAutoComplete(false));
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCityInseeCd, '');
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthDepartment, '');
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCountry, '');
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCity, '');
  };

  return {
    formik,
    setBirthDepartment,
    isDesktop,
    resetBirthCity,
    t,
    isBirthCountryFR: formik.values.birthCountryIsoCd === 'FR',
    isPopUpEditOpened,
    autocompleteBirthCity: autocompleteValue,
    defaultAutocompleteValue: appNavUtils?.autoCompleteBirthCityValue
      ?.autocompleteValue as string,
    showAutoCompletedField,
    openPopUpEdit,
    closePopUpEdit,
    isPopUpLeaveOpened,
    openPopUpLeave,
    closePopUpLeave,
    returnToUrlWebMerchant,
    goBackToStep,
    currentStep,
    sectors,
    computedPersonalInformation,
    onUpdateOptin,
    onUpdateDopc,
    dopcOptin,
    isActionLoading,
    onChangeAutoCompletedField,
    onSuggestionAutoCompletedFieldClick,
    onBlurAutocomplete,
    setShowAutoCompletedField,
    isBirthNamaFocused,
    setIsBirthNameFocused,
  };
};

export default useInformationForm;
