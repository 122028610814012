import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  FocusEvent,
  useState,
  useRef,
  useMemo,
  KeyboardEvent,
} from 'react';
import { useTheme } from 'styled-components';
import { Theme } from '@sweb-front/styles';
import {
  IconWrapper,
  InputContainer,
  InputLabel,
  ComboInputWrapper,
  UnstyledInput,
  ErrorMessageWrapper,
  ChevronUpIconWrapper,
  InfoGlyphIconWrapper,
  CrossCircleWrapper,
  ComboInputRowCenterWrapper,
  ComboList,
  ComboListItem,
  ComboListItemText,
  CrossWrapper,
} from './styles';
import { CrossIcon } from '../Icons';

export type ComboInputProps = {
  id: string;
  label?: string;
  value?: string;
  autoComplete?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (value?: { value: string; label: string }) => void;
  onChangeEvent?: ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  touched?: boolean;
  options?: {
    value: string;
    label: string;
  }[];
};

export const ComboInput = ({
  id,
  label,
  value,
  autoComplete,
  disabled = false,
  placeholder,
  onChange,
  errorMessage,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  touched = false,
  options = [],
}: ComboInputProps) => {
  const theme = useTheme() as Theme;
  const [isDirty, setIsDirty] = useState(
    touched || (typeof value !== 'undefined' && value !== '')
  );
  const [isOpened, setIsOpened] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [currentlyHoveredItemIndex, setCurrentlyHoveredItemIndex] = useState<
    number | undefined
  >(undefined);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState(
    filteredOptions.find((option) => option.value === value)?.label ?? ''
  );
  const selected = useMemo(() => {
    const foundValue = filteredOptions.find(
      (option) => option.value === value
    )?.label;
    if (foundValue) {
      setInputValue(foundValue);
    }
    return foundValue;
  }, [value]);

  const inputRef = useRef<HTMLInputElement>(null);
  const itemsContainerRef = useRef<HTMLUListElement>(null);

  const onIconWrapperClick = () => {
    setIsOpened(!isOpened);
    setIsFocused(!isFocused);
    inputRef.current?.focus();
  };

  const computeNewHoverItemIndex = (key: string, index?: number) => {
    if (key === 'ArrowDown') {
      if (index === undefined) {
        return 0;
      }
      if (index === filteredOptions.length - 1) {
        return index;
      }
      return index + 1;
    }
    if (index === undefined) {
      return index;
    }
    if (index === 0) {
      return index;
    }
    return index - 1;
  };

  const updateCurrentlyHoveredItemIndex = (key: 'ArrowUp' | 'ArrowDown') => {
    const newHoveredItemIndex = computeNewHoverItemIndex(
      key,
      currentlyHoveredItemIndex
    );
    if (newHoveredItemIndex) {
      itemsContainerRef.current?.childNodes
        ?.item(newHoveredItemIndex)
        ?.parentElement?.scrollTo({
          behavior: 'smooth',
          top: newHoveredItemIndex * 40 - 40,
        });
    }
    setCurrentlyHoveredItemIndex(newHoveredItemIndex);
  };

  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isOpened && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
      updateCurrentlyHoveredItemIndex(e.key);
      return;
    }
    if (!isOpened && e.key === 'ArrowDown') {
      setIsOpened(true);
    }
    if (e.key === 'Enter' && onChange) {
      onChange(
        currentlyHoveredItemIndex !== undefined
          ? filteredOptions[currentlyHoveredItemIndex]
          : undefined
      );
      setIsOpened(false);
      return;
    }
    if (!onChange || e.key !== 'Backspace' || selected === undefined) {
      return;
    }
    onChange(undefined);
  };

  const onInputFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchFieldValue = e.target.value;
    setInputValue(searchFieldValue);
    const valueInOptions = filteredOptions.find(
      (option) => option.label.toLowerCase() === searchFieldValue.toLowerCase()
    );
    if (valueInOptions && onChange) {
      onChange(valueInOptions);
    }
    setFilteredOptions(
      searchFieldValue !== ''
        ? options.filter((option) =>
            option.label.toLowerCase().match(searchFieldValue.toLowerCase())
          )
        : options
    );
  };

  const closeComboList = () => {
    setIsDirty(true);
    setIsFocused(false);
    setIsOpened(false);
    inputRef.current?.blur();
  };

  const onItemClick = (option: { value: string; label: string }) => {
    if (onChange) {
      onChange(option);
    }
    setFilteredOptions(options);
    closeComboList();
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocusProp) {
      onFocusProp(e);
    }
    inputRef.current?.focus();
    setIsOpened(true);
  };
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      closeComboList();
      if (onBlurProp) {
        onBlurProp(e);
      }
    }, 250);
  };
  const onResetClick = () => {
    if (onChange) {
      onChange(undefined);
      setInputValue('');
    }
  };

  const isError = errorMessage !== undefined && isDirty;
  const isValid = errorMessage === undefined && isDirty;

  return (
    <ComboInputWrapper>
      <InputLabel>{label}</InputLabel>
      <InputContainer
        disabled={disabled}
        isError={isError}
        isValid={isValid}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <UnstyledInput
          id={id}
          name={id}
          type="text"
          disabled={disabled}
          value={selected ?? inputValue}
          autoComplete={autoComplete}
          onChange={onInputFieldChange}
          onKeyDown={onInputKeyDown}
          placeholder={placeholder}
          ref={inputRef}
        />
        <IconWrapper onClick={onIconWrapperClick} disabled={disabled}>
          <ComboInputRowCenterWrapper>
            <ChevronUpIconWrapper isOpened={isOpened} />
            {isFocused ? (
              <CrossWrapper
                tabIndex={-1}
                type="button"
                onClick={() => onResetClick()}
              >
                <CrossIcon fill={theme.vendor.colors.border} />
              </CrossWrapper>
            ) : isDirty ? (
              errorMessage ? (
                <InfoGlyphIconWrapper fill={theme.vendor.colors.error} />
              ) : (
                <CrossCircleWrapper fill={theme.vendor.colors.primary} />
              )
            ) : (
              <></>
            )}
          </ComboInputRowCenterWrapper>
        </IconWrapper>
        <ComboList
          tabIndex={-1}
          isOpened={isOpened}
          isError={isError}
          isValid={isValid}
          filteredOptionsLength={filteredOptions.length}
          ref={itemsContainerRef}
        >
          {filteredOptions.map((option, index) => (
            <ComboListItem
              key={`${id}-item-${option.value}-${option.label}`}
              tabIndex={-1}
              onClick={() => onItemClick(option)}
              onMouseOver={() => setCurrentlyHoveredItemIndex(undefined)}
              hovered={index === currentlyHoveredItemIndex}
            >
              <ComboListItemText>{option.label}</ComboListItemText>
            </ComboListItem>
          ))}
        </ComboList>
      </InputContainer>
      {isError && !disabled && (
        <ErrorMessageWrapper id="errorMessage">
          {errorMessage}
        </ErrorMessageWrapper>
      )}
    </ComboInputWrapper>
  );
};
