import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import EditButton, { IEditButtonProps } from '../EditButton';

export interface IEditableInputProps {
  value: string;
  label: string;
  editButtonProps: IEditButtonProps;
  disabled?: boolean | null;
  readonly?: boolean | null;
  yAxis?: string;
  id?: string;
}

const WrapperDiv = styled.div`
  position: relative;
`;
const WrapperEditButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;
const EditableInput: React.FC<IEditableInputProps> = ({
  yAxis,
  value,
  label,
  editButtonProps,
  disabled,
  readonly,
  id,
}: IEditableInputProps) => {
  const { t } = useTranslation();
  return (
    <WrapperDiv>
      <base-input
        id={id}
        label={label}
        value={value}
        selected
        readonly={readonly}
        disabled={disabled}
      />
      <WrapperEditButton>
        <EditButton
          yAxis={yAxis}
          href={editButtonProps?.href || '#'}
          onButtonClick={editButtonProps?.onButtonClick}
        >
          {t('common.edit')}
        </EditButton>
      </WrapperEditButton>
    </WrapperDiv>
  );
};

export default EditableInput;
