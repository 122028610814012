import { createComponent } from '@lit-labs/react';
import React from 'react';

export class ButtonLinkDS extends HTMLElement {
  icon?: string;
  label?: string;
  class?: string | null;
  inverse?: boolean;
  constructor() {
    super();
  }
}

export const ButtonLink = createComponent(React, 'button-link', ButtonLinkDS, {});
