import {
  getActiveStep,
  IOpportunityState,
  selectNavigation,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { getOnpenBankingUrl } from '@vat/services';
import { useNavigate } from 'react-router-dom';
import { OBCONNEXIONERROR, OPENBANKING } from '@vat/configuration';
import { useMemo } from 'react';
import { isStringEmpty } from '@sweb-front/utils';

const useOpenBankingRedirect = (opportunity?: IOpportunityState) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigation = useAppSelector(selectNavigation);
  const activeStep = getActiveStep(navigation?.steps);

  const isOpenBankingActiveStep = useMemo(
    () =>
      activeStep?.externalAppName === 'openBanking' &&
      !isStringEmpty(activeStep?.externalUrl),
    [navigation, activeStep]
  );

  const waitingPageBeforeOpenBanking = async () => {
    const returnUrlOnpenBanking = encodeURIComponent(
      `${window.location.origin}?redirectFromOpenBanking`
    );
    dispatch(
      updateParcoursNavigation({
        name: OPENBANKING,
        loaded: true,
        locked: true,
      })
    );

    if (isOpenBankingActiveStep) {
      return;
    }

    dispatch(
      updateSteps({
        externalAppName: 'openBanking',
        isSeDone: false,
        returnUrl: returnUrlOnpenBanking,
      })
    );
    try {
      await getOnpenBankingUrl(
        returnUrlOnpenBanking,
        (response) => {
          if (response && response.tinkUrl) {
            dispatch(
              updateSteps({
                externalAppName: 'openBanking',
                externalUrl: response.tinkUrl,
              })
            );
            dispatch(
              updateParcoursNavigation({
                name: OPENBANKING,
                params: {
                  url: response.tinkUrl,
                },
              })
            );
            window.location.replace(response.tinkUrl);
          }
        },
        async () => {
          dispatch(
            updateParcoursNavigation({
              name: OPENBANKING,
              loaded: true,
              actionPageDone: true,
              params: {
                connexionObiTink: 'KO',
              },
            })
          );
          dispatch(
            updateSteps({
              externalAppName: 'openBanking',
              isSeDone: false,
              returnUrl: '',
            })
          );
          navigate(OBCONNEXIONERROR);
        }
      );
    } catch {
      dispatch(
        updateParcoursNavigation({
          name: OPENBANKING,
          loaded: true,
          actionPageDone: true,
          params: {
            connexionObiTink: 'KO',
          },
        })
      );
      dispatch(
        updateSteps({
          externalAppName: 'openBanking',
          isSeDone: false,
          returnUrl: '',
        })
      );
      navigate(OBCONNEXIONERROR);
    }
  };
  return {
    waitingPageBeforeOpenBanking,
  };
};

export default useOpenBankingRedirect;
