import { Breadcrumb } from '@sweb-front/components';
import styled from 'styled-components';

export const BreadcrumbWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 70.1rem;
`;

export const StyledBreadcrumb = styled((props) => <Breadcrumb {...props} />)`
  max-width: 70.1rem;
  flex-grow: 1;
`;
