import { pad } from '@vat/containers';
import dayjs from 'dayjs';
import { IRoutingContext } from '@sweb-front/types';

export const formatNumber = (
  num: number | string | undefined
): string | undefined => {
  if (num === undefined) return undefined;
  const parsed = Number.parseFloat(num.toString());
  if (Number.isNaN(parsed)) {
    return undefined;
  }

  if (parsed === 0) return parsed.toString();
  if (parsed % 1 === 0) {
    // pas de décimales
    return parsed.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '); // NOSONAR
  }
  return parsed
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '); // NOSONAR
};

// Convert 'yyyy-mm-dd' to 'dd/mm/yyyy'
export const convertDate = (inputFormat: string): string => {
  let checkDate;
  try {
    checkDate = new Date(inputFormat).toISOString();
  } catch (e) {
    throw new Error(`not a real date ${checkDate}`);
  }
  return inputFormat.split('-').reverse().join('/');
};

// Convert 'dd/mm/yyyy' to 'yyyy-mm-dd' or 'mm/yyyy' to 'yyyy-mm-dd'
export const convertDateReverse = (inputFormat, withDay) => {
  if (!withDay) {
    return dayjs(
      inputFormat.toString().replace(/ /g, ''),
      'MM/YYYY',
      true
    ).format('YYYY-MM-DD');
  }
  return dayjs(
    inputFormat.toString().replace(/ /g, ''),
    'DD/MM/YYYY',
    true
  ).format('YYYY-MM-DD');
};

export const removeSpace = (dataStr) => {
  return dataStr?.replace(/ /g, '');
};

// Convert 'yyyy-mm-dd' to 'yyyy-mm-ddT00:00:00.000Z'
export const convertDateForBFF = (inputFormat: string): string => {
  return `${inputFormat}T00:00:00.000Z`;
};

// Convert 'EXAMPLE' or 'example' to 'Example'
export const capitalize = (phrasing?: string) => {
  return phrasing
    ?.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Convert 'EXAMPLE' or 'example' to 'EXAMPLE'
export const wholeWordCapitalize = (phrasing: string | undefined) => {
  return (phrasing ?? '').toUpperCase();
};

// Convert '0606060606' to '06 06 06 06 06'
export const phoneFormatter = (phoneNb: string): string => {
  const newPhone = phoneNb.replace(/(.{2})/g, '$1 ');
  return newPhone.substring(0, 14);
};

export const updateRoutingContexts = (
  currentRoutingContext = [] as IRoutingContext[],
  updateRoutingContexts = [] as IRoutingContext[]
): IRoutingContext[] => {
  if (updateRoutingContexts?.length < 1) {
    return currentRoutingContext;
  }
  const newUrlToAddInContexts = [] as IRoutingContext[];
  newUrlToAddInContexts.push(
    ...updateRoutingContexts.filter(
      (rct) =>
        !currentRoutingContext
          .map((ccrt) => ccrt.contextUse)
          .includes(rct.contextUse)
    )
  );

  if (newUrlToAddInContexts.length > 0) {
    currentRoutingContext.push(...newUrlToAddInContexts);
  }

  return currentRoutingContext;
};

/**
 * Convert Date into date format
 * @param date The date object
 * Example : 'new Date()' to '09/2024'
 * @returns The formatted date
 */
export const getDateInStringFormat = (date: Date) => {
  const d = new Date(date);
  if (!d) {
    return '';
  }
  const month = pad(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();
  return `${month}/${year}`;
};

/**
 * Convert date format into Date object
 * @param date The date string
 * Example : '09/2024' to 'new Date()'
 * @returns The formatted date
 */
export const getDateInDateObjectFormat = (date: string) => {
  if (!date) {
    return;
  }
  const parts = date.split('-');
  if (parts.length === 3) {
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[0], 10);
    if (!isNaN(month) && !isNaN(year)) {
      return new Date(Date.UTC(year, month - 1, 1));
    }
  }
};
