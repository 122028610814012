import { ReactNode } from 'react';

import { RadioButtonWrapper } from './styles';

export interface IProps {
  title: string;
  subtitle?: string;
  price?: string;
  description?: ReactNode;
  categorie?: string;
  linkText?: string;
  cardKey?: string;
  isRecommended?: boolean;
  recommandedTitle?: string;
  selected?: boolean | null;
  value?: string;
  checked?: boolean;
  onSelectedChange?: (
    e: CustomEvent & {
      target: {
        value: any;
      };
    }
  ) => void;
  onLinkClick: (value?: string) => void;
}

const insuranceTitle = {
  D: 'Décès',
  DIM: 'Décès, Invalidité, Maladie',
  DIMC: 'Décès, Invalidité, Maladie, Chômage',
};
const NO_ASSU = 'Sans assurance';

export const CardWithHeader: React.FC<IProps> = ({
  cardKey,
  recommandedTitle,
  onSelectedChange,
  selected,
  title,
  subtitle,
  description,
  price,
  linkText,
  onLinkClick,
}: IProps) => (
  <RadioButtonWrapper
    value={cardKey}
    title={recommandedTitle}
    onSelectedChange={onSelectedChange}
    checked={selected ? true : undefined}
    tabIndex={0}
  >
    {title && <span slot="title">{insuranceTitle[title] ?? title}</span>}

    {cardKey !== NO_ASSU && (
      <div slot="acronym">
        {subtitle}
        {description && <span>{description}</span>}
      </div>
    )}

    {price && <span slot="pricetag">{price} € / mois</span>}
    {cardKey !== NO_ASSU && (
      <a slot="link" onClick={() => onLinkClick?.(title)}>
        {linkText}
      </a>
    )}
  </RadioButtonWrapper>
);
