import { useTranslation } from 'react-i18next';
import { WarrantiesWrapper } from './styles';

const WarrantiesText = () => {
  const { t } = useTranslation();
  return (
    <WarrantiesWrapper>
      <p>{t('insurance.details.warranties.explication')}</p>
      <p>
        <span>{t('insurance.details.warranties.assuranceExplication1')}</span>
        <span>{t('insurance.details.warranties.assuranceExplication2')}</span>
      </p>
      <p>{t('insurance.details.warranties.assuranceExplication3')}</p>
    </WarrantiesWrapper>
  );
};

export default WarrantiesText;
