import { styled } from 'styled-components';
import { DESKTOP, TAB, MOBILE } from '@sweb-front/utils';
import { CloseIcon } from '../Icons';

export const StyledCSSBackgroundTransition = styled.div`
  .modal-background-enter {
    background-color: rgba(0, 0, 0, 0);
    z-index: -1;
    display: flex;
  }
  .modal-background-enter-active {
    transition: background-color 50ms ease-in-out;
    background-color: rgba(0, 0, 0, 0.15);
  }
  .modal-background-enter-done {
    z-index: 49;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
  }
  .modal-background-exit {
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 49;
  }
  .modal-background-exit-active {
    transition: background-color 50ms ease-in-out;
    background-color: rgba(0, 0, 0, 0);
  }
  .modal-background-exit-done {
    z-index: -1;
    background-color: rgba(0, 0, 0, 0);
    display: none;
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 10rem;
  display: none;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`;

export const StyledCSSModalTransition = styled.div`
  .modal-enter {
    display: block;
    @media screen and ${MOBILE} {
      transform: translateY(100vh);
      z-index: 50;
    }
    @media screen and ${DESKTOP} and ${TAB} {
      opacity: 0;
    }
  }
  .modal-enter-active {
    @media screen and ${MOBILE} {
      transition: transform 250ms ease-in-out;
      transform: translateY(1vh);
    }

    @media screen and ${DESKTOP} and ${TAB} {
      transition: opacity 150ms ease-in-out;
      opacity: 100;
    }
  }
  .modal-enter-done {
    z-index: 50;
  }
  .modal-exit {
    @media screen and ${MOBILE} {
      transform: translateY(1vh);
    }
    z-index: 50;
    @media screen and ${DESKTOP} and ${TAB} {
      opacity: 100;
    }
  }
  .modal-exit-active {
    @media screen and ${MOBILE} {
      transition: transform 250ms ease-in-out;
      transform: translateY(100vh);
    }

    @media screen and ${DESKTOP} and ${TAB} {
      transition: opacity 150ms ease-in-out;
      opacity: 0;
    }
  }
  .modal-exit-done {
    display: none;
    z-index: -1;
  }
`;

export const ModalWrapper = styled.div<{ isOpened?: boolean }>`
  position: fixed;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #ffffff;
  padding-bottom: 1.6rem;
  @media screen and ${TAB} {
    width: 56%;
    bottom: auto;
    max-height: 80vh;
    overflow: hidden;
    z-index: 50;
    margin: 5rem 25vw;
  }
  @media screen and ${MOBILE} {
    border-radius: 1.6rem 1.6rem 0 0;
  }
  &.information-modal {
    @media screen and ${MOBILE} {
      border-top-left-radius: 1.6rem;
      border-top-right-radius: 1.6rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @media screen and ${TAB} {
      border-radius: 1.6rem;
    }
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.vendor.colors.border};
  width: 2.2rem;
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  flex-direction column;
  justify-content: end;
  padding: 1.6rem;
  &.information-modal {
    padding: 1.6rem;
  }
`;

export const StyledCloseButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border: 0.1rem solid ${({ theme }) => theme.vendor.colors.light};
  border-radius: 100%;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
`;
