import { useTranslation } from 'react-i18next';
import AutomaticAnalysysMethodCard from './items/AutomaticAnalysysMethodCard';
import StandardMethodCard from './items/StandardMethodCard';
import ModalOpenBankingChoise from './items/modal/ModalOpenBankingChoise';
import { CookiesCheckbox, NavigationButtons } from '@vat/components';
import { InputWrapper } from './style';
import useOpenBankingChoiceForm from './useOpenBankingChoiceForm';
import { selectSteps, useAppSelector } from '@sweb-front/store';
import AutomaticAnalysysMethodCardVatRib from './items/AutomaticAnalysysMethodCardVatRib';
import StandardMethodCardVatRib from './items/StandardMethodCardVatRib';
import { ModalLeave } from '@vat/layouts';
export interface IOpenBankingChoiceFormProps {
  isVATRib: boolean;
}

const OpenBankingChoiceForm = ({ isVATRib }: IOpenBankingChoiceFormProps) => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { person } = opportunity;
  const steps = useAppSelector(selectSteps);

  const {
    AUTOMATICMETHOD,
    STANDARDMETHOD,
    methodConsent,
    selectedMethod,
    handleSelectedMethod,
    openModal,
    closeModal,
    onUpdateMethodConsent,
    onSubmit,
    isFormValid,
    isActionLoading,
    showModal,
    showModalLeave,
    onShowModalLeaveFn,
  } = useOpenBankingChoiceForm(
    person?.personId ?? '',
    opportunity,
    steps ?? [],
    isVATRib
  );

  return (
    <>
      <form>
        {isVATRib ? (
          <AutomaticAnalysysMethodCardVatRib
            onSelect={handleSelectedMethod}
            isSelected={selectedMethod === AUTOMATICMETHOD}
            openModal={openModal}
          />
        ) : (
          <AutomaticAnalysysMethodCard
            onSelect={handleSelectedMethod}
            isSelected={selectedMethod === AUTOMATICMETHOD}
            openModal={openModal}
          />
        )}
        {isVATRib ? (
          <StandardMethodCardVatRib
            onSelect={handleSelectedMethod}
            isSelected={selectedMethod === STANDARDMETHOD}
          />
        ) : (
          <StandardMethodCard
            onSelect={handleSelectedMethod}
            isSelected={selectedMethod === STANDARDMETHOD}
          />
        )}

        {selectedMethod === AUTOMATICMETHOD && (
          <InputWrapper id={'wrapper-obsconsent'}>
            <CookiesCheckbox
              id="obConsent"
              name="obConsent"
              label={t('openBanking.automaticMethodMessage')}
              state={methodConsent}
              onChange={onUpdateMethodConsent}
            />
          </InputWrapper>
        )}

        <NavigationButtons
          id="ob-conitniue-button"
          validateLabel={t('common.validate')}
          isLoading={isActionLoading}
          validateAction={onSubmit}
          backLabel={t('common.back')}
          isDisabled={!isFormValid}
          withoutReturnButton
        />
        {showModal && <ModalOpenBankingChoise closeModal={closeModal} />}
      </form>
      {showModalLeave && <ModalLeave close={onShowModalLeaveFn} />}
    </>
  );
};

export default OpenBankingChoiceForm;
