import { Section, Loader } from '@sweb-front/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useManagingExternalApp } from '@vat/utils';
import { useNavigate } from 'react-router-dom';
import { LoadingWrapper } from '@sweb-front/styles';
import { INFORMATIONBANCAIRES } from '@vat/configuration';
import { usePageRedirection } from '@sweb-front/hooks';

const CardInformation = () => {
  const { t } = useTranslation();
  const { initMonext } = useManagingExternalApp();
  const history = useNavigate();

  const onClick = () => {
    history(-1);
  };

  const { isPageLoading } = usePageRedirection(INFORMATIONBANCAIRES, {
    disabled: true,
  });

  useEffect(() => {
    return () => {
      onClick();
    };
  }, []);

  useEffect(() => {
    if (isPageLoading) {
      return;
    }
    initMonext();
  }, [isPageLoading]);

  return (
    !isPageLoading && (
      <Section>
        <LoadingWrapper>
          <Loader
            isLoading
            primaryText={t('common.loader.shortText')}
            secondaryText={t('common.loader.longText')}
            isTextInline={false}
          />
        </LoadingWrapper>
      </Section>
    )
  );
};

export default CardInformation;
