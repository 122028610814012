import { useManagingStep, useUrlWebMarchand } from '@sweb-front/hooks';
import {
  setEndParcours,
  updateOpportunityStatus,
  useAppDispatch,
} from '@sweb-front/store';
import { ERRORPAGE, OPPORTUNITYSTATUS } from '@vat/configuration';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useModalLeave = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { goToWebMarchand } = useManagingStep();
  const { getUrlCancel } = useUrlWebMarchand();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const returnToUrlWebMerchant = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    goToWebMarchand(
      getUrlCancel(),
      'du bouton Retour',
      () => {
        dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.ANUL));
      },
      () => {
        dispatch(setEndParcours(true));
        navigate(ERRORPAGE);
      }
    );
  }, [isLoading]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    returnToUrlWebMerchant,
  };
};

export default useModalLeave;
