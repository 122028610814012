import { styled } from 'styled-components';

export const EducationTitle = styled.h2`
  margin: 0 0 0.8rem 0;
  font-family: 'Ubuntu Bold';
  color: var(--neutrals-700);
  font-size: 2.4rem;
  line-height: 3.2rem;
`;

export const EducationSection = styled.div`
  margin-bottom: 2.4rem;
`;
