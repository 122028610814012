import dayjs from 'dayjs';

const LocalStorageService = (() => {
  const STORAGE_PREFIX = '__sweb__';

  const setWithExpiry = (key, value) => {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value,
      expiry: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`,
    };
    localStorage.setItem(STORAGE_PREFIX + key, JSON.stringify(item));
  };

  const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(STORAGE_PREFIX + key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    const today = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;

    // compare the expiry time of the item with the current time
    if (dayjs(today).isAfter(item.expiry)) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(STORAGE_PREFIX + key);
      return null;
    }
    return item.value;
  };

  return {
    getWithExpiry,
    setWithExpiry,
  };
})();

export default LocalStorageService;
