import { Title, Description } from '@sweb-front/components';
import { useEffect } from 'react';
import { SectionWrapper, HeaderWrapper } from './styles';

export interface ISectionProps {
  id?: string;
  children: React.ReactNode;
  level?: 1 | 2;
  title?: string;
  description?: string;
}

const Section: React.FC<ISectionProps> = ({
  id,
  title,
  level = 2,
  description,
  children,
}: ISectionProps) => {
  // Get to the top of each page on arrival on the page
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <SectionWrapper id={id || 'main-section'}>
      <HeaderWrapper>
        {title && <Title level={level}>{title}</Title>}
        {description && <Description>{description}</Description>}
      </HeaderWrapper>
      {children}
    </SectionWrapper>
  );
};

export default Section;
