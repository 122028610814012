const LockerIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/24px/Action/lock-close24px</title>
    <g
      id="Icon/24px/Action/lock-close"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="LOCKED" fill="#4B4F54" fillRule="nonzero">
        <g id="Group" transform="translate(4.000000, 2.000000)">
          <path
            d="M8.04830918,11.01 C7.00483092,11.01 6.15458937,11.89 6.15458937,12.97 C6.15458937,13.84 6.71497585,14.58 7.47826087,14.83 L7.48792271,16.34 C7.48792271,16.67 7.74879227,16.94 8.06763285,16.94 C8.06763285,16.94 8.06763285,16.94 8.06763285,16.94 C8.38647343,16.94 8.647343,16.67 8.647343,16.34 L8.63768116,14.83 C9.39130435,14.57 9.94202899,13.85 9.94202899,12.98 C9.94202899,11.89 9.09178744,11.01 8.04830918,11.01 Z"
            id="Path"
          />
          <path
            d="M12.4154589,7.56 L12.4154589,4.14 C12.4154589,1.86 10.6183575,0 8.41545894,0 L7.56521739,0 C5.37198068,0 3.57487923,1.86 3.57487923,4.14 L3.57487923,7.56 C1.54589372,7.95 0,9.78 0,11.99 L0,15.49 C0,17.98 1.96135266,20 4.36714976,20 L11.6425121,20 C14.0483092,20 16,17.98 16,15.49 L16,11.99 C16,9.78 14.4541063,7.94 12.4154589,7.56 Z M7.57487923,1.2 L8.42512077,1.2 C9.99033816,1.2 11.2657005,2.52 11.2657005,4.14 L11.2657005,7.48 L4.73429952,7.48 L4.73429952,4.14 C4.73429952,2.52 6.00966184,1.2 7.57487923,1.2 Z M14.8405797,15.49 C14.8405797,17.32 13.4009662,18.8 11.6425121,18.8 L4.36714976,18.8 C2.59903382,18.8 1.16908213,17.31 1.16908213,15.49 L1.16908213,11.99 C1.16908213,10.45 2.20289855,9.15 3.58454106,8.79 L3.58454106,9.95 C3.58454106,10.28 3.84541063,10.55 4.16425121,10.55 C4.48309179,10.55 4.74396135,10.28 4.74396135,9.95 L4.74396135,8.68 L11.2657005,8.68 L11.2657005,9.95 C11.2657005,10.28 11.52657,10.55 11.8454106,10.55 C12.1642512,10.55 12.4251208,10.28 12.4251208,9.95 L12.4251208,8.79 C13.8067633,9.15 14.8405797,10.45 14.8405797,11.99 L14.8405797,15.49 L14.8405797,15.49 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LockerIcon;
