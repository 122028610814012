import styled from 'styled-components';

export const IconWithToolTip = styled.div`
  position: relative;
  display: inline-block;
  width: 4.8rem;
  height: 4.4rem;

  & span {
    width: 10rem;
    margin-left: -5rem;
    z-index: 1;
    visibility: hidden;
    background-color: black;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 50%;
    color: #ffffff;
    font-family: 'Open Sans';
    border-radius: 0.6rem;
    padding: 0.5rem 0;
  }

  & span::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  &:hover span {
    visibility: visible;
  }

  & img {
    border-radius: 0.8rem;
    width: 100%;
    height: 100%;
  }
`;

interface IProductIconProps {
  iconSrc: string;
  description: string;
  id?: string;
}

const ProductIcon = ({ iconSrc, description, id }: IProductIconProps) => {
  return (
    <IconWithToolTip id={id}>
      <img alt={description} src={iconSrc} />
      <span>{description}</span>
    </IconWithToolTip>
  );
};

export default ProductIcon;
