import styled from 'styled-components';
import { InfoOutlinesIcon } from '../Icons';

export const StyledWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const StyledInfoOutlinesIcon = styled(InfoOutlinesIcon)<{
  iconSize?: number;
}>`
  height: ${({ iconSize }) => `${iconSize !== 1.6 ? iconSize : 1.6}rem`};
  width: ${({ iconSize }) => `${iconSize !== 1.6 ? iconSize : 1.6}rem`};
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  cursor: pointer;
`;

export const TooltipContainer = styled.div<{
  iconSize: number;
  width: number;
}>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: ${({ width }) => `${width}rem`};
  right: ${({ width, iconSize }) => `-${(width - iconSize) / 2}rem`};
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  outline: none;
`;
