import { useAppSelector } from '@sweb-front/store';

import { useTranslation } from 'react-i18next';
import {
  DesktopSelect,
  SelectInputWithoutOption,
  NavigationButtons,
  RadioGroup,
  Modal as ModalKYC,
  useModal,
  InputDate,
} from '@vat/components';
import useProfessionalForm from './useProfessionalForm';
import {
  ActivitySectorContainer,
  FormWrapper,
  InputDateWrapper,
  ModalRadioGroupContainer,
} from './styles';
import { setAllFieldsTouched } from '@vat/utils';

const ProfessionalForm = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { isOpened, openModal, closeModal } = useModal();
  const { isDirty, formik, sectors, goBackToStep, isDesktop, isActionLoading } =
    useProfessionalForm(opportunity);

  return (
    <>
      <FormWrapper>
        <ActivitySectorContainer>
          {isDesktop ? (
            <DesktopSelect
              id="activitySector"
              label={t('basicInformations.activitySectors')}
              placeholder={t('basicInformations.placeHolder.activitySectors')}
              options={sectors}
              errorMessage={formik.errors.activitySector}
              value={formik.values.activitySector}
              onChange={(value) =>
                formik.setFieldValue('activitySector', value)
              }
              onClose={() => formik.setFieldTouched('activitySector')}
              touched={formik.touched.activitySector}
            />
          ) : (
            <SelectInputWithoutOption
              id="activitySector"
              onClick={() => openModal()}
              errorMessage={formik.errors.activitySector}
              label={t('basicInformations.activitySectors')}
              placeholder={t('basicInformations.placeHolder.activitySectors')}
              value={
                sectors.find(
                  ({ value }) => value === formik.values.activitySector
                )?.label
              }
              touched={formik.touched.activitySector}
            />
          )}
        </ActivitySectorContainer>
        <InputDateWrapper>
          <InputDate
            id="employmentDt"
            dayOptional={true}
            key={`employmentDt-touched:${formik.touched.employmentDt}`}
            label={t('professionalSituation.form.fields.employmentDt')}
            value={formik.values.employmentDt}
            onChange={(value) => formik.setFieldValue('employmentDt', value)}
            errorMessage={formik.errors.employmentDt}
            onBlur={formik.handleBlur}
            touched={formik.touched.employmentDt}
          />
        </InputDateWrapper>
        <NavigationButtons
          id="navigation-buttons"
          key={`nav-buttons-${
            isDirty && formik.isValid ? 'isValid' : 'isInvalid'
          }`}
          validateLabel={t('common.validate')}
          isLoading={isActionLoading}
          validateAction={() => {
            setAllFieldsTouched(formik.values, formik.setTouched);
            formik.handleSubmit();
          }}
          backLabel={t('common.back')}
          backAction={goBackToStep}
          isDisabled={!isDirty || !formik.isValid}
        />
      </FormWrapper>
      <ModalKYC
        isOpened={isOpened}
        closeModal={closeModal}
        onClose={() => formik.setFieldTouched('activitySector')}
      >
        <ModalRadioGroupContainer>
          <RadioGroup
            id="activitySector-radio-group"
            label={t('basicInformations.sectorList.fieldDescription')}
            title={t('basicInformations.sectorList.title')}
            options={sectors}
            onChange={({ value }) => {
              formik.setFieldValue('activitySector', value);
              closeModal();
            }}
            value={formik.values.activitySector}
            touched={formik.touched.activitySector}
            buttonWidth="100%"
            rowGap="1.6rem"
            wrapOnMobile={false}
          />
        </ModalRadioGroupContainer>
      </ModalKYC>
    </>
  );
};

export default ProfessionalForm;
