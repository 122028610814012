import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
} from 'react';

export type AppOptions = {
  isMounted: boolean;
};

export type AppOptionsContextType = {
  appOptions: AppOptions;
  setAppOptions: Dispatch<SetStateAction<AppOptions>>;
};

export const AppOptionsContext = createContext<
  AppOptionsContextType | undefined
>(undefined);

export const AppOptionsProvider = ({
  children,
  value: { appOptions, setAppOptions },
}: {
  children: ReactNode;
  value: AppOptionsContextType;
}) => {
  const state = useMemo(
    () => ({ appOptions, setAppOptions }),
    [appOptions, setAppOptions]
  );
  return (
    <AppOptionsContext.Provider value={state}>
      {children}
    </AppOptionsContext.Provider>
  );
};

export const useAppOptions = (): AppOptionsContextType => {
  const context = useContext<AppOptionsContextType | undefined>(
    AppOptionsContext
  );
  if (!context) {
    throw new Error('useAppOptions must be used within a AppOptionsProvider');
  }
  return context;
};
