import { ReactNode, RefObject } from 'react';
import { CardContainer } from './styles';

export type CardProps = {
  children: ReactNode;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null;
  type?: 'border' | 'normal';
  borderColor?: string;
  id?: string;
};

const Card = ({ children, ref, type, borderColor, id }: CardProps) => {
  return (
    <CardContainer id={id} type={type} ref={ref} borderColor={borderColor}>
      {children}
    </CardContainer>
  );
};

export default Card;
