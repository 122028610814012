const CurrencyIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <path
          d="M11.9997 2.1001C6.53301 2.1001 2.08301 6.5501 2.08301 12.0168C2.08301 17.4834 6.53301 21.9334 11.9997 21.9334C17.4663 21.9334 21.9163 17.4834 21.9163 12.0168C21.9163 6.5501 17.4663 2.1001 11.9997 2.1001ZM11.9997 20.6834C7.21634 20.6834 3.33301 16.8001 3.33301 12.0168C3.33301 7.23343 7.21634 3.3501 11.9997 3.3501C16.783 3.3501 20.6663 7.23343 20.6663 12.0168C20.6663 16.8001 16.783 20.6834 11.9997 20.6834Z"
          fill="#268038"
        />
        <path
          d="M12.3497 8.2168C13.1997 8.2168 13.9497 8.68346 14.3497 9.4668C14.5164 9.7668 14.8831 9.90013 15.1997 9.73346C15.4997 9.58347 15.6331 9.20013 15.4664 8.88346C14.8497 7.70013 13.6664 6.9668 12.3664 6.9668C10.5497 6.9668 9.04974 8.33346 8.84974 10.1001H7.68307C7.33307 10.1001 7.06641 10.3835 7.06641 10.7335C7.06641 11.0835 7.34974 11.3668 7.68307 11.3668H8.83307V12.6001H7.68307C7.33307 12.6001 7.06641 12.8835 7.06641 13.2335C7.06641 13.5835 7.34974 13.8668 7.68307 13.8668H8.84974C9.04974 15.6335 10.5497 17.0001 12.3664 17.0001C13.6664 17.0001 14.8664 16.2668 15.4664 15.0835C15.6164 14.7835 15.4997 14.4001 15.1997 14.2335C14.8997 14.0668 14.5164 14.2001 14.3497 14.5001C13.9497 15.2835 13.1997 15.7501 12.3497 15.7501C11.2331 15.7501 10.2831 14.9335 10.0997 13.8668H11.6997C12.0497 13.8668 12.3331 13.5835 12.3331 13.2335C12.3331 12.8835 12.0497 12.6001 11.6997 12.6001H10.0664V11.3668H11.6997C12.0497 11.3668 12.3331 11.0835 12.3331 10.7335C12.3331 10.3835 12.0497 10.1001 11.6997 10.1001H10.0997C10.2997 9.03346 11.2331 8.2168 12.3497 8.2168Z"
          fill="#268038"
        />
      </g>
    </svg>
  );
};

export default CurrencyIcon;
