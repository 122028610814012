import { ProgressBarComponent } from 'src/libs/components/RedirectionComponent/styles';
import { BreadcrumbWrapper } from './styles';
import useHeader from './useHeader';
import CetelemHeaderLogo from '../CetelemLogoHeader';

export type TStep = {
  name: string;
  step: number;
};

export type THeaderProps = {
  steps: TStep[];
  currentStep: number;
  withMargin?: boolean;
};

const Header = ({ currentStep, steps }: THeaderProps) => {
  const { progressPercentage } = useHeader(currentStep, steps);
  return (
    <>
      <CetelemHeaderLogo />
      <BreadcrumbWrapper id="app-header">
        <ProgressBarComponent
          progress={progressPercentage}
          progressColor={'#268038'}
        >
          <div></div>
        </ProgressBarComponent>
      </BreadcrumbWrapper>
    </>
  );
};

export default Header;
