import { Icons, Prompt } from '@sweb-front/components';
import { useEffect } from 'react';
import { EventParams } from '@sweb-front/types';
import { LOGGER } from '@sweb-front/utils';
import { useAdobeTagManager } from '@sweb-front/hooks';
import * as messages from './messages';

export interface GenericErrorProps {
  redirectToPopHome: () => void;
  trackEvent: (event: EventParams) => void;
  requestId?: string;
}

const GenericError = ({
  redirectToPopHome,
  trackEvent,
  requestId,
}: GenericErrorProps): React.ReactElement => {
  const usedRequestId = requestId ?? 'unknown';
  const { FormNameLabel, PageNamePreLabel } = useAdobeTagManager({
    LOGGER,
  });

  useEffect(() => {
    trackEvent({
      event: 'module_interaction',
      pageName: `${PageNamePreLabel} : Formulaire : Erreur technique `,
      formName: FormNameLabel,
      CreditType: process.env.REACT_APP_CREDIT_TYPE,
      typeOfError: usedRequestId, // donnée temporaire, TODO : récupérer le code erreur
    });
  }, []);

  return (
    <Prompt
      icon={<Icons.CloudIcon />}
      title={messages.TITLE}
      text={
        <>
          {messages.REFUSE_CONTACT}
          {usedRequestId && (
            <>
              <br />
              {messages.REQUESTID_HANDLER}
              {usedRequestId}
            </>
          )}
        </>
      }
      tertiaryButtonLabel={messages.BACK_BUTTON}
      onTertiaryButtonClick={redirectToPopHome}
    />
  );
};

export default GenericError;
