import {
  CONTEXT_CANCEL,
  CONTEXT_DONE,
  CONTEXT_ERROR,
  CONTEXT_KO,
  CONTEXT_LATER,
} from '@sweb-front/services';
import { selectNavigation, useAppSelector } from '@sweb-front/store';
import { IRoutingContext } from '@sweb-front/types';

const useUrlWebMarchand = () => {
  const routingContextState =
    useAppSelector(selectNavigation)?.routingContexts ?? [];
  const findRoutingByAction = (action: string) => {
    return routingContextState?.find(
      (routingContext: IRoutingContext) => routingContext.contextUse === action
    )?.url;
  };
  const getUrlDone = (): string | undefined => {
    return findRoutingByAction(CONTEXT_DONE);
  };
  const getUrlKO = (): string | undefined => {
    return findRoutingByAction(CONTEXT_KO);
  };
  const getUrlError = (): string | undefined => {
    return findRoutingByAction(CONTEXT_ERROR);
  };
  const getUrlLater = (): string | undefined => {
    return findRoutingByAction(CONTEXT_LATER);
  };
  const getUrlCancel = (): string | undefined => {
    return findRoutingByAction(CONTEXT_CANCEL);
  };

  return {
    getUrlCancel,
    getUrlDone,
    getUrlError,
    getUrlKO,
    getUrlLater,
  };
};

export default useUrlWebMarchand;
