import { createComponent } from '@lit-labs/react';
import React from 'react';

export class NumericStepperDS extends HTMLElement {
  value: unknown;
  constructor() {
    super();
  }
}

export const NumericStepper = createComponent(React, 'numeric-stepper', NumericStepperDS, {
  onStepperChanged: 'stepper-changed',
});
