import { DESKTOP, TAB } from '@sweb-front/utils';
import styled from 'styled-components';


const LogoBlock = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    row-gap: 0.1rem;
`;

export const PartnerLogo = styled.img`
    max-height: 40px;
    max-width: 120px;
    @media screen and ${TAB} {
        max-height: 35px;
    }
    @media screen and ${DESKTOP} {
        max-height: 35px;
    }
`;

export default LogoBlock;
