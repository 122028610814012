import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ASSURANCE_DOC_INFORMATION,
  ASSURANCE_FICHE_CONSEIL,
  ASSURANCE_NOTICE,
} from '@sweb-front/services';
import { IInsurance } from '@sweb-front/types';

import { IState } from './assuranceTypes';

const getInitialState = () => {
  return {
    state: {
      assuranceData: [],
      files: [
        {
          label: 'Document d’information',
          link: ASSURANCE_DOC_INFORMATION,
          fileName: 'document infos assurance.pdf',
        },
        {
          label: 'Fiche conseil',
          link: ASSURANCE_FICHE_CONSEIL,
          fileName: 'fiche conseil.pdf',
        },
        {
          label: 'Notice',
          link: ASSURANCE_NOTICE,
          fileName: 'notice assurance.pdf',
        },
      ],
    },
  } as { state: IState };
};

export const assuranceSlice = createSlice({
  name: 'assurance',
  initialState: getInitialState(),
  reducers: {
    clear(assuranceState) {
      assuranceState.state = getInitialState().state;
    },
    setAssurance(assuranceState, dataAssurance: PayloadAction<IInsurance[]>) {
      assuranceState.state.assuranceData = dataAssurance.payload;
      const letters: string[] = [];
      dataAssurance?.payload?.forEach(
        (data) => data?.optionCd && letters.push(...data.optionCd.split(''))
      );
      assuranceState.state.astrickLetters = [
        ...new Set(letters.map((item) => item)),
      ];
    },
  },
});
export const { clear, setAssurance } = assuranceSlice.actions;
export default assuranceSlice.reducer;
