import { SelectInputWithoutOption } from '@vat/components';
import CetelemHeaderLogo from '../CetelemLogoHeader';
import useEducation from './useEducation';
import { EducationSection, EducationTitle } from './styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@sweb-front/store';

const Education = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const { educationChoices, onChoiceClick } = useEducation(
    opportunity,
    parameters
  );
  return (
    <EducationSection>
      <CetelemHeaderLogo />
      <EducationTitle>{t('education.title')}</EducationTitle>
      {educationChoices.map((eduChoice, index) => {
        return (
          <SelectInputWithoutOption
            isBoldText
            key={index}
            id={`education_${eduChoice}`}
            onClick={() => onChoiceClick(eduChoice)}
            placeholder={t(`education.labelChoice.${eduChoice}`)}
          />
        );
      })}
    </EducationSection>
  );
};
export default Education;
