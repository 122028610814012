import {
  BaseCardWrapper,
  FileDownloadWrapper,
  OptionDocumentWrapper,
  StyledLoader,
  TitleBoldWrapper,
} from './styles';
import useOptionalDocumentCard from './useOptionalDocumentCard';

export interface IOptionDocument {
  label: string;
  link: string;
  fileName: string;
}

export interface IOptionalDocumentCardProps {
  contentTitle: string;
  documents: IOptionDocument[];
}

const OptionalDocumentCard = ({
  contentTitle,
  documents,
}: IOptionalDocumentCardProps) => {
  const { selected, isLoading, onClickOnLink, isDesktop } =
    useOptionalDocumentCard();
  return (
    <BaseCardWrapper>
      <base-card>
        {contentTitle && (
          <TitleBoldWrapper level={2}>{contentTitle}</TitleBoldWrapper>
        )}
        {documents.map((item) => (
          <base-card-row key={item.label}>
            <OptionDocumentWrapper>
              <FileDownloadWrapper
                id={item.link}
                isLoading={isLoading}
                name={item.fileName}
                onClick={onClickOnLink}
                rel="noreferrer"
              >
                {item.label}
              </FileDownloadWrapper>
              {isDesktop && (
                <base-icon
                  className="open-in-new"
                  id={`__${item.link}`}
                  icon="open-in-new"
                  viewBox="0 0 24 24"
                  onClick={!isLoading ? onClickOnLink : undefined}
                  name={item.fileName}
                />
              )}
              {item.link === selected && (
                <StyledLoader isLoading={isLoading} isTextInline />
              )}
            </OptionDocumentWrapper>
          </base-card-row>
        ))}
      </base-card>
    </BaseCardWrapper>
  );
};

export default OptionalDocumentCard;
