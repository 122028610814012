import React from 'react';
import { StyledH1, StyledH2, StyledH3 } from './styles';

export interface ITitleProps {
  children?: React.ReactNode;
  level?: number;
  className?: string;
}

const Title = ({
  children,
  level,
  className,
}: ITitleProps): React.ReactElement => {
  if (level === 2) {
    return <StyledH2 className={className}>{children}</StyledH2>;
  }
  if (level === 3) {
    return <StyledH3 className={className}>{children}</StyledH3>;
  }
  return <StyledH1 className={className}>{children}</StyledH1>;
};

Title.defaultProps = {
  level: 1,
};

export default Title;
