import styled from 'styled-components';

const FooterAppWrapper = styled.div`
  border-top: 1px solid var(--neutrals-50);
  padding: 1.6rem 1.6rem 0 1.6rem;
  color: #61676c;
  font-size: 1.2rem;
  line-height: 1.4rem;
`;

export default FooterAppWrapper;
