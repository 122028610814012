import { createComponent } from '@lit-labs/react';
import React from 'react';

export class GenderInputDS extends HTMLElement {
  class?: string;

  value: unknown;

  label?: string;

  showRequired?: boolean | null;

  showErrorMessage?: boolean | null;

  errorMessage?: string;

  invalid?: boolean | null;

  required?: boolean;

  hasFocus?: boolean | null;
}

export const GenderInput = createComponent(
  React,
  'gender-input',
  GenderInputDS,
  {
    onSelectedElementChange: 'selected-element-changed',
  }
);
