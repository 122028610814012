const BasketIcon = ({ color }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon/24px/Action/basket-224px</title>
      <g
        id="Icon/24px/Action/basket-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          transform="translate(4.000000, 2.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <path
            d="M13.0777901,20 L2.93548887,19.9945225 C2.11007891,20.0020541 1.31790237,19.6501216 0.760394574,19.032528 C0.202886774,18.4149344 -0.0690847921,17.5830317 0.0150161412,16.7511291 L1.00116741,6.96138056 C1.15309167,5.45231596 2.40104101,4.31435526 3.90332784,4.31435526 L11.9715916,4.31435526 C13.4542096,4.31435526 14.7001243,5.43519862 14.8696826,6.92235303 L15.981307,16.7408587 C16.074225,17.5542746 15.8178528,18.3745375 15.2766226,18.9921311 C14.7218277,19.6261573 13.9201559,19.9931531 13.0777901,20 Z M2.93141947,18.7292088 L13.0737207,18.7346863 C13.5552664,18.7305781 14.0184998,18.5190078 14.3379477,18.1540662 C14.6479003,17.8000796 14.7896511,17.3495512 14.7360706,16.885329 L13.6244462,7.06682337 C13.5274588,6.21917274 12.8166703,5.58035365 11.9715916,5.58035365 L3.90400607,5.58035365 C3.04671914,5.58035365 2.33525237,6.22944315 2.24843851,7.09010295 L1.26228724,16.8791668 C1.21413267,17.3605063 1.36470047,17.8226745 1.68753954,18.1800845 C2.00698744,18.5340711 2.44309147,18.7292088 2.91717657,18.7292088 C2.92192421,18.7292088 2.92667184,18.7292088 2.93141947,18.7292088 Z"
            id="Shape"
          ></path>
          <path
            d="M11.0220649,8.37458807 C10.6761659,8.37458807 10.3953773,8.09112493 10.3953773,7.74193122 L10.3940208,4.68545917 C10.3587527,2.62110809 10.1722385,1.26473015 7.99985717,1.26473015 C7.9862925,1.26473015 7.97272784,1.26473015 7.95916317,1.26473015 C5.77118244,1.2811628 5.59145061,2.71833458 5.57652947,4.7463969 C5.56635597,6.11236055 5.56771244,7.71454348 5.56771244,7.73097612 C5.56771244,8.08016984 5.28760207,8.36363297 4.94170307,8.36431766 C4.94170307,8.36431766 4.94170307,8.36431766 4.94102484,8.36431766 C4.59512584,8.36431766 4.31428298,8.08153922 4.31428298,7.73234551 C4.31428298,7.71591286 4.31298077,6.10893708 4.32383251,4.73749588 C4.33061484,3.8535565 4.33875364,2.75256926 4.77553591,1.80837684 C5.32490491,0.620433515 6.3931224,0.0117409414 7.95034614,0.000101150889 C9.51774337,-0.00879986537 10.6015602,0.569766192 11.1638157,1.72895239 C11.6134844,2.65602747 11.6324749,3.76933919 11.647396,4.66423367 L11.6487525,4.73270303 C11.6487525,4.7361265 11.6487525,4.74023466 11.6487525,4.74365813 L11.6487525,7.74193122 C11.6487525,8.09112493 11.3679639,8.37458807 11.0220649,8.37458807 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};
export default BasketIcon;
