import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEvent,
  FocusEvent,
  useRef,
} from 'react';
import { useTheme } from 'styled-components';
import { Theme } from '@sweb-front/styles';
import {
  CrossCircleWrapper,
  IconWrapper,
  InfoGlyphIconWrapper,
  InputContainer,
  InputLabel,
  InputNumberWrapper,
  UnstyledInput,
  ErrorMessageWrapper,
  InputAndResetContainer,
  AfterTextWrapper,
  AfterContainer,
} from './styles';
import { Button } from '../Button';

export type InputNumberProps = {
  id: string;
  className?: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  afterText?: string;
  onChange?: (value?: string) => void;
  onChangeEvent?: ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  touched?: boolean;
  isInteger?: boolean;
  maxLength?: number;
  withResetButton?: boolean;
  onReset?: () => void;
};

const isKeyNotAllowed = (isInteger: boolean, key: string) =>
  isInteger ? !!key.match(/([^0-9])+/) : !!key.match(/([^0-9,.])+/);
const isKeyCommaOrDot = (key: string) => !!key.match(/([,.])/);
const isValueContainsCommaOrDot = (value?: string) =>
  !!value?.match(/([,.]){1,}/);
const isCommaOrDotAndUnauthorized = (
  isInteger: boolean,
  key: string,
  value?: string
) => !isInteger && isKeyCommaOrDot(key) && isValueContainsCommaOrDot(value);

const InputNumber = ({
  id,
  className,
  label,
  value,
  disabled = false,
  placeholder,
  afterText,
  onChange: onChangeProp,
  onChangeEvent,
  errorMessage,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  touched = false,
  isInteger = false,
  maxLength,
  withResetButton = false,
  onReset: onResetProp,
}: InputNumberProps) => {
  const theme = useTheme() as Theme;

  const isDirty = touched || value !== undefined;

  const inputRef = useRef<HTMLInputElement>(null);

  const onInputContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onKeyPressSanitize = (
    e: KeyboardEvent<HTMLInputElement>,
    fieldValue?: string
  ) => {
    if (isCommaOrDotAndUnauthorized(isInteger, e.key, fieldValue)) {
      e.preventDefault();
      return;
    }
    if (isKeyNotAllowed(isInteger, e.key)) {
      e.preventDefault();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      maxLength &&
      e.target.value.length > maxLength &&
      String(value) &&
      e.target.value.length > String(value)?.length
    ) {
      return;
    }
    const newValue = (() => {
      if (e.target.value === '') {
        return undefined;
      }
      const valueAsNumber = Number(e.target.value);
      if (!Number.isNaN(valueAsNumber) && isInteger) {
        return String(Math.round(valueAsNumber));
      }
      return e.target.value.replace(',', '.');
    })();
    if (onChangeEvent) {
      onChangeEvent(e);
    }
    if (onChangeProp) {
      onChangeProp(newValue);
    }
  };
  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocusProp) {
      onFocusProp(e);
    }
  };
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlurProp) {
      onBlurProp(e);
    }
  };
  const onReset = () => {
    if (onChangeProp) {
      onChangeProp('0');
    }
    if (onResetProp) {
      onResetProp();
    }
  };

  const isError = errorMessage !== undefined && isDirty;
  const isValid = errorMessage === undefined && isDirty;

  return (
    <InputNumberWrapper id={id} className={className}>
      <InputLabel disabled={disabled}>{label}</InputLabel>
      <InputAndResetContainer resetSelected={value === '0'}>
        <InputContainer
          disabled={disabled}
          isError={isError}
          isValid={isValid}
          onClick={onInputContainerClick}
        >
          <UnstyledInput
            id={id}
            name={id}
            data-testid={id}
            role="textbox"
            type="text"
            inputMode="numeric"
            value={value === undefined ? '' : value.replace('.', ',')}
            onChange={onChange}
            onKeyPress={(e) =>
              onKeyPressSanitize(e, value ? String(value) : undefined)
            }
            disabled={disabled}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={inputRef}
          />
          <AfterContainer>
            {afterText && <AfterTextWrapper>{afterText}</AfterTextWrapper>}
            <IconWrapper>
              {isDirty ? (
                isError ? (
                  <InfoGlyphIconWrapper fill={theme.vendor.colors.error} />
                ) : (
                  <CrossCircleWrapper fill={theme.vendor.colors.primary} />
                )
              ) : (
                <></>
              )}
            </IconWrapper>
          </AfterContainer>
        </InputContainer>
        {withResetButton && (
          <Button
            buttonType="border"
            isRounded
            id={`${id}-reset`}
            type="reset"
            onClick={onReset}
            height="4.8rem"
            width="12rem"
            isBold={false}
          >
            <span>Aucun</span>
          </Button>
        )}
      </InputAndResetContainer>
      {isError && !disabled && (
        <ErrorMessageWrapper id="errorMessage">
          {errorMessage}
        </ErrorMessageWrapper>
      )}
    </InputNumberWrapper>
  );
};

export default InputNumber;
