import {
  CheckedListItemTitleIconWrapper,
  CheckedListItemWrapper,
  ContentWrapper,
  DescriptionWrapper,
  SVGIconWrapper,
  TitleWrapper,
} from './styles';

export interface ICheckedListItemProps {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
}

const CheckedListItem = ({
  icon,
  title,
  description,
  className,
}: ICheckedListItemProps) => {
  return (
    <CheckedListItemWrapper className={className}>
      <CheckedListItemTitleIconWrapper>
        <SVGIconWrapper>
          <base-icon icon={icon ?? 'check2-circle'} />
        </SVGIconWrapper>
        <ContentWrapper>
          <TitleWrapper>{title}</TitleWrapper>
          <DescriptionWrapper>{description}</DescriptionWrapper>
        </ContentWrapper>
      </CheckedListItemTitleIconWrapper>
    </CheckedListItemWrapper>
  );
};

export default CheckedListItem;
