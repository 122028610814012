const SvgCloud: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 53 46" className={className}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M29.021 5.726a16.126 16.126 0 00-13.375 7.541l-.084.136-.144-.029a12.477 12.477 0 00-2.247-.203C6.316 13.17.759 18.728.759 25.583S6.316 37.995 13.17 37.995H42.96c5.484 0 9.93-4.446 9.93-9.93l-.003-.221a9.935 9.935 0 00-7.66-9.448l-.166-.037-.021-.098c-1.65-7.252-8.136-12.537-15.733-12.537l-.286.002zm.286 2.48c6.714 0 12.403 4.88 13.474 11.433.089.546.528.967 1.077 1.033a7.449 7.449 0 01-.898 14.84H13.17c-5.483 0-9.929-4.445-9.929-9.929s4.446-9.93 9.93-9.93c.914 0 1.81.123 2.672.364a1.241 1.241 0 001.427-.608 13.649 13.649 0 0112.037-7.203z" />
      <path d="M45.806 1.122a1.241 1.241 0 011.832 1.672l-.077.084-42.2 42.2a1.241 1.241 0 01-1.833-1.671l.077-.083L45.806 1.122z" />
    </g>
  </svg>
);

export default SvgCloud;
