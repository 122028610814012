import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoutingContext } from '@sweb-front/types';
import {
  IAppStep,
  INavigationPageState,
  IState as INavigationState,
  IState,
} from './navigationType';

export const initialState = {
  state: {
    webAPageEvent: 'pageLoaded',
    isFilledWithAutoComplete: true,
  } as INavigationState,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
    setVatType: (navigation, data: PayloadAction<string>) => {
      navigation.state.vatType = data.payload;
    },
    setRightToEcard(navigation, data: PayloadAction<boolean>) {
      navigation.state.rightToEcard = data.payload;
    },
    setRoutingContexts(navigation, data: PayloadAction<IRoutingContext[]>) {
      navigation.state.routingContexts = data.payload;
    },
    setRandomSign(navigation, data: PayloadAction<string>) {
      navigation.state.randomSign = data.payload;
    },
    setEndParcours(navigation, data: PayloadAction<boolean>) {
      navigation.state.endParcours = data.payload;
    },
    setLastStep(navigation, data: PayloadAction<string>) {
      navigation.state.lastStep = data.payload;
    },
    setLastAppUrl(navigation, data: PayloadAction<string>) {
      navigation.state.lastAppUrl = data.payload;
    },
    updateOpportunityStatus(navigation, data: PayloadAction<string>) {
      navigation.state.opportunityStatus = data.payload;
    },
    setTypeWayCd(navigation, data: PayloadAction<string>) {
      navigation.state.typeWayCd = data.payload;
    },
    setWebAEventPage(navigation, data: PayloadAction<string>) {
      navigation.state.webAPageEvent = data.payload;
    },
    setIsFilledWithAutoComplete(navigation, data: PayloadAction<boolean>) {
      navigation.state.isFilledWithAutoComplete = data.payload;
    },
    setAutocompletefieldValue(
      navigation,
      data: PayloadAction<Record<string, unknown>>
    ) {
      navigation.state.autoCompleteBirthCityValue = data.payload;
    },
    setOpenbankingChoice(navigation, data: PayloadAction<string>) {
      navigation.state.openBankingChoice = data.payload;
    },
    updateParcoursNavigation(
      navigation,
      data: PayloadAction<INavigationPageState>
    ) {
      const index = navigation.state.appNavigationState?.findIndex(
        (st) => st.name === data.payload.name
      );

      if (index > -1) {
        navigation.state.appNavigationState[index] = {
          ...navigation.state.appNavigationState[index],
          ...data.payload,
          params: {
            ...(navigation.state.appNavigationState[index]?.params ?? {}),
            ...(data.payload?.params ?? {}),
          },
        };
      } else {
        navigation.state.appNavigationState = [
          ...(navigation.state.appNavigationState ?? []),
          data.payload,
        ];
      }
    },
    updateSteps(navigation, data: PayloadAction<IAppStep>) {
      const index = navigation.state.steps?.findIndex(
        (st) => st.externalAppName === data.payload.externalAppName
      );

      if (index > -1) {
        navigation.state.steps[index] = {
          ...navigation.state.steps[index],
          ...data.payload,
        };
      } else {
        navigation.state.steps = [
          ...(navigation.state.steps ?? []),
          data.payload,
        ];
      }
    },
  },
});

export const selectNavigation = (navigationState: {
  navigation: { state: INavigationState };
}) => navigationState.navigation.state;

export const isSeDoneThroughSteps = (steps: IAppStep[]): boolean => {
  return steps?.some((step) => step.isSeDone);
};

export const getActiveStep = (steps: IAppStep[]): IAppStep | undefined => {
  return (steps ?? []).find((step) => !step.isSeDone);
};

export const getStepByExternalAppName = (
  name: string,
  steps: IAppStep[]
): IAppStep | undefined => {
  return (steps ?? []).find((step) => step.externalAppName === name);
};

export const isOpenBankingResultOk = (appNav: IState): boolean => {
  return appNav?.steps?.some(
    (step) => step.externalAppName === 'openBanking' && step.resultOb === 'OK'
  );
};

export const {
  clear,
  setVatType,
  setLastStep,
  setLastAppUrl,
  setEndParcours,
  updateSteps,
  setRandomSign,
  setTypeWayCd,
  setRoutingContexts,
  setRightToEcard,
  updateOpportunityStatus,
  setIsFilledWithAutoComplete,
  setAutocompletefieldValue,
  setWebAEventPage,
  setOpenbankingChoice,
  updateParcoursNavigation,
} = navigationSlice.actions;

export type { INavigationState, IAppStep };

export default navigationSlice.reducer;
