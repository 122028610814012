import {
  ContentWrapper,
  DescriptionWrapper,
  NumberWrapper,
  NumericListWrapper,
  TitleWrapper,
} from './styles';

export interface INumericListProps {
  listIndex: string;
  title?: string;
  description?: string;
  id?: string;
  backgroundColor?: string;
  marginTopTitle?: string;
  marginTopDescription?: string;
}

const NumericList = ({
  listIndex,
  title,
  description,
  id,
  backgroundColor,
  marginTopTitle,
  marginTopDescription,
}: INumericListProps) => {
  return (
    <NumericListWrapper className="numeric-list" id={id}>
      <NumberWrapper backgroundColor={backgroundColor}>
        {listIndex}
      </NumberWrapper>
      <ContentWrapper>
        {title && (
          <TitleWrapper marginTop={marginTopTitle}>{title}</TitleWrapper>
        )}
        {description && (
          <DescriptionWrapper marginTop={marginTopDescription}>
            {description}
          </DescriptionWrapper>
        )}
      </ContentWrapper>
    </NumericListWrapper>
  );
};

export default NumericList;
