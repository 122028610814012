import { lazy } from 'react';

const VARSTORAGE = 'is-force-reload';
/**
 * The purpose of this method is to reload a page once if it didn't load the component, otherwise it will raise an error
 */
const lazyRetry = (cmpImport) =>
  lazy(async () => {
    const isPageReloaded = JSON.parse(
      localStorage.getItem(VARSTORAGE) ?? 'false'
    ) as boolean;

    try {
      const component = await cmpImport();
      localStorage.setItem(VARSTORAGE, 'false');
      return component;
    } catch (error) {
      if (!isPageReloaded) {
        localStorage.setItem(VARSTORAGE, 'true');
        return window.location.reload();
      }
      throw error;
    }
  });

export default lazyRetry;
