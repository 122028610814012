import { trackEvent } from '@sweb-front/utils';
import { ReactNode } from 'react';
import styled from 'styled-components';

export interface IEditButtonProps {
  yAxis?: string;
  children?: ReactNode;
  href?: string;
  target?: string;
  onButtonClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const EditButtonWrapper = styled.a`
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-family: 'Open Sans bold';
  display: flex;
  text-wrap: nowrap;
  padding-top: 0.8rem;
  transition-property: all;
  transition-duration: 300ms;
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin: 0 0.2rem 0 0;
  cursor: pointer;
  &:hover {
    color: #000000;
  }
  text-decoration: none;
`;

const SVGWrapper = styled.svg.attrs((props: any) => ({
  xmlns: props.xmlns,
  fill: props.fill,
  viewBox: props.viewBox,
  stroke: props.stroke,
  strokeWidth: props.strokeWidth,
}))`
  width: 2rem;
  height: 2rem;
`;

const trackEventEditButton = () => {
  trackEvent({ event: 'confirmExit' });
};

const EditButton: React.FC<IEditButtonProps> = ({
  children,
  href,
  yAxis = '-1',
  target,
  onButtonClick,
}: IEditButtonProps) => {
  return (
    <EditButtonWrapper
      href={href ?? undefined}
      onClick={(e) => {
        e.preventDefault();
        trackEventEditButton();
        onButtonClick?.();
      }}
      target={target}
    >
      {' '}
      <SVGWrapper
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox={`1 ${yAxis} 24 24`}
        stroke="currentColor"
        strokeWidth="2.5"
        width={24}
        height={24}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
        />
      </SVGWrapper>
      {children}
    </EditButtonWrapper>
  );
};

export default EditButton;
