import { TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const ButtonActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  @media screen and ${TAB} {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const ButtonLabel = styled.span<{ fontSize: string; action: string }>`
  display: ${({ action }) => (action === 'validate' ? 'flex' : '')};
  font-family: 'Open Sans Bold';
  font-size: ${({ fontSize }) => fontSize};
`;
