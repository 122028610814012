import { ReactElement } from 'react';
import { CustomedBubbleInfoWrapper, ImgWrapper } from './styles';

export interface ICustomedBubbleInfoProps {
  id?: string;
  description?: string;
  descriptionComp?: ReactElement;
  hasIcon?: boolean;
}

const CustomedBubbleInfo = ({
  id,
  description,
  descriptionComp,
  hasIcon = true,
}: ICustomedBubbleInfoProps) => {
  return (
    <CustomedBubbleInfoWrapper id={id}>
      {hasIcon && (
        <ImgWrapper>
          <base-icon icon="info-fill" />
        </ImgWrapper>
      )}
      <div className="bubbleinfo-description">
        {description}
        {descriptionComp}
      </div>
    </CustomedBubbleInfoWrapper>
  );
};

export default CustomedBubbleInfo;
