/* eslint-disable no-nested-ternary */

import { useEffect, useMemo, useRef, useState } from 'react';
import {
  InputContainer,
  StyledInput,
  StyledInputContainerWithBorder,
  InputAfterText,
  StyledErrorMessage,
  StyledLabel,
  StyledInputWithResetButton,
  StyledResetButton,
  CheckMark,
  StyledAfterContainer,
} from './styles';
import { Check } from '../Icons/AppSpecific/Check';

export type InputProps = {
  id: string;
  type: string;
  label?: string;
  onChange: (e) => void;
  value?: string | number;
  placeholder?: string;
  errorMessage?: string;
  afterText?: string;
  resetLabel?: string;
  maxLength?: number;
  width?: string;
  resetFunction?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

export const Input = ({
  id,
  type,
  value,
  label,
  onChange,
  errorMessage,
  placeholder,
  afterText,
  resetLabel,
  maxLength,
  width,
  resetFunction,
  onBlur: onBlurProp,
  onFocus: onFocusProp,
}: InputProps) => {
  const inputRef = useRef<any>();
  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(
    typeof errorMessage === 'string' && errorMessage !== ''
  );
  const isValid = useMemo(
    () =>
      typeof errorMessage === 'undefined' &&
      value !== undefined &&
      value !== '',
    [errorMessage]
  );

  const onBlur = () => {
    setIsFocused(false);
    if (onBlurProp) {
      onBlurProp();
    }
  };
  const onFocus = () => {
    setIsFocused(true);
    if (onFocusProp) {
      onFocusProp();
    }
  };

  const handleInputShouldOnlyBeNumberWithPossibleMaxLength = (event) => {
    event.target.value = (event.target.value as string)
      .replace(/[^0-9]/g, '')
      .substring(0, maxLength || undefined);
    onChange(event);
  };

  const onReset = () => {
    onFocus();
    if (resetFunction) {
      resetFunction();
    }
  };

  useEffect(() => {
    setIsError(typeof errorMessage === 'string' && errorMessage !== '');
  }, [errorMessage]);

  const computedValue = useMemo(
    () => (value !== undefined ? value : value === 0 ? 0 : ''),
    [value]
  );

  const onClickFocusInput = () => {
    inputRef.current.focus();
  };

  return (
    <InputContainer>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInputWithResetButton width={width} onClick={onClickFocusInput}>
        <StyledInputContainerWithBorder
          isFocused={isFocused}
          isError={isError}
          isValid={isValid}
        >
          <StyledInput
            id={id}
            ref={inputRef}
            type={type}
            name={id}
            onInput={
              (type === 'number' &&
                handleInputShouldOnlyBeNumberWithPossibleMaxLength) ||
              undefined
            }
            onChange={onChange}
            value={computedValue}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <StyledAfterContainer>
            {afterText ? <InputAfterText>{afterText}</InputAfterText> : <></>}
            {isValid ? (
              <CheckMark>
                <Check />
              </CheckMark>
            ) : (
              <></>
            )}
          </StyledAfterContainer>
        </StyledInputContainerWithBorder>
        {resetLabel ? (
          <StyledResetButton
            type="button"
            isSelected={type === 'text' ? value === '' : value === 0}
            onClick={() =>
              resetFunction
                ? onReset()
                : (() => {
                    console.log(
                      `input ${id} reset button clicked => No reset function provided`
                    );
                  })()
            }
          >
            {resetLabel}
          </StyledResetButton>
        ) : (
          <></>
        )}
      </StyledInputWithResetButton>
      {errorMessage ? (
        <StyledErrorMessage id="errorMessage">
          {errorMessage}
        </StyledErrorMessage>
      ) : (
        <></>
      )}{' '}
    </InputContainer>
  );
};
