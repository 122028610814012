import styled from 'styled-components';
import { BackArrowIcon } from '../Icons';

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledBackArrow = styled(BackArrowIcon)`
  justify-self: start;
`;

export const StyledBackButton = styled.button`
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10rem;

  span {
    font-family: 'Open Sans Bold';
    letter-spacing: 0;
    text-align: end;
    font-size: 1.8rem;
    line-height: 2.6rem;
    color: ${({ theme }) => theme.vendor.colors.accent};
    text-align: center;
  }
`;
