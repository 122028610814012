import { ReactNode } from 'react';
import BubbleInfoWrapper, {
  BubbleInfoListWrapper,
  BubbleInfoTitleWrapper,
  Chevron,
  ImgWrapper,
} from './styles';

/**
 * @property {string} marker the css by default have many default values
 * to see some list, see the documentation https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type
 * otherwise, make the @property {boolean} isCustomedMarker as true to put some customed markers
 */

export interface IBubbleInfoProps {
  id?: string;
  title?: ReactNode;
  secondTitle?: ReactNode;
  strongTitle?: ReactNode;
  bubbleItems?: ReactNode[];
  marker?: string;
  textColor?: string;
  isCustomedMarker: boolean;
  hasIcon?: boolean;
}

const BubbleInfo = ({
  id,
  title,
  secondTitle,
  strongTitle,
  textColor,
  bubbleItems,
  isCustomedMarker = false,
  marker,
  hasIcon = false,
}: IBubbleInfoProps) => {
  return (
    <>
      <Chevron></Chevron>
      <BubbleInfoWrapper id={id}>
        {hasIcon && (
          <ImgWrapper>
            <base-icon icon="information-lamp" />
          </ImgWrapper>
        )}
        <div className="bubble-content">
          {(title || strongTitle) && (
            <BubbleInfoTitleWrapper>
              {title && (
                <span
                  className="light-title"
                  style={{ color: textColor ?? '' }}
                >
                  {title}
                </span>
              )}
              {strongTitle && (
                <span
                  className="strong-title"
                  style={{ color: textColor ?? '' }}
                >
                  {strongTitle}
                </span>
              )}
              {secondTitle && (
                <span
                  className="light-title"
                  style={{ color: textColor ?? '' }}
                >
                  {secondTitle}
                </span>
              )}
            </BubbleInfoTitleWrapper>
          )}
          {bubbleItems && bubbleItems.length > 0 && (
            <BubbleInfoListWrapper
              marker={marker}
              color={textColor}
              isCustomedMarker={isCustomedMarker}
            >
              {bubbleItems.map((item, index) => (
                <li key={`bubble-item-${index + 1}`}>{item}</li>
              ))}
            </BubbleInfoListWrapper>
          )}
        </div>
      </BubbleInfoWrapper>
    </>
  );
};

export default BubbleInfo;
