import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseSpinnerDS extends HTMLElement {
  size?: string;
  color?: string;
  label?: string;
  constructor() {
    super();
  }
}

export const BaseSpinner = createComponent(React, 'base-spinner', BaseSpinnerDS, {

});
