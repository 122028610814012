import { CetelemLogo } from '@vat/components';
import { CetelemHeader } from './styles';

const CetelemHeaderLogo = () => {
  return (
    <CetelemHeader>
      <CetelemLogo />
    </CetelemHeader>
  );
};
export default CetelemHeaderLogo;
