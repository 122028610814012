import { styled } from 'styled-components';

export const CetelemHeader = styled.div`
  display: flex;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
