import { cleanSession } from '@sweb-front/services';
import {
  getUrlRedirectToPopPage,
  getAbsoluteUrlRedirectToPopPage,
} from '@sweb-front/utils';
import {
  clearStore,
  selectConfiguration,
  useAppSelector,
} from '@sweb-front/store';

const useRedirection = (): {
  redirectToPopPage: (path: string, isRelativePath: boolean) => void;
  redirectToPopHome: () => void;
  logout: () => void;
} => {
  // TODO : invalider la session BFF et revoker le token 2 APIM
  const configuration = useAppSelector(selectConfiguration);

  const returnUrlBasename = configuration?.returnUrl;

  const redirectToPopPage = (path: string, isRelativePath: boolean) => {
    const url = isRelativePath
      ? getUrlRedirectToPopPage(path, returnUrlBasename)
      : getAbsoluteUrlRedirectToPopPage(path, returnUrlBasename);
    cleanSession();
    sessionStorage.clear();
    clearStore();
    window.location.replace(url);
  };

  const redirectToPopHome = () => {
    redirectToPopPage('accueil', true);
  };

  const logout = () => {
    redirectToPopPage('c/portal/logout', false);
  };

  return {
    redirectToPopPage,
    redirectToPopHome,
    logout,
  };
};

export default useRedirection;
