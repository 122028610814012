const SvgChevronUp: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.963 8.232l8.809 9.058c.272.28.714.28.986 0a.732.732 0 000-1.015L12.456 6.71a.684.684 0 00-.987 0l-9.302 9.565a.732.732 0 000 1.015c.272.28.714.28.987 0l8.809-9.058z"
    />
  </svg>
);

export default SvgChevronUp;
