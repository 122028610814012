import { Section } from '@sweb-front/components';
import { TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const MainSectionWrapper = styled((props) => <Section {...props} />)`
  font-size: 1.2rem;
`;

export const StyledParagraph = styled.div`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 1.6rem 0;
`;

export const StyledTitle = styled.h1`
  font-family: 'Ubuntu Bold';
  color: var(--neutrals-700);
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h1};
  line-height: 3.6rem;
  @media screen and ${TAB} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const BoldSpan = styled.span`
  font-family: 'Open Sans Bold';
`;
