const SvgChevronDown: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="16px"
    height="8px"
    className={className}
    viewBox="0 0 16 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>B1627ABF-59AB-4CDF-A6F4-6C4BC1113D12</title>
    <g
      id="📱Mobile"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Recap-&gt;-Multi-inf3K"
        transform="translate(-268.000000, -582.000000)"
        fill="#292C2E"
      >
        <g id="Group-27" transform="translate(16.000000, 264.000000)">
          <g id="Group-12" transform="translate(0.000000, 42.000000)">
            <g id="Group-16" transform="translate(0.000000, 239.000000)">
              <g id="Group-8-Copy" transform="translate(16.000000, 17.000000)">
                <g id="Group-3" transform="translate(4.000000, 0.000000)">
                  <g id="Group-2" transform="translate(60.000000, 0.000000)">
                    <g
                      id="Ic/24px/Action/plus-circle"
                      transform="translate(172.000000, 20.000000)"
                    >
                      <path
                        d="M0.261100251,0.234946058 C0.427835126,0.0845331111 0.654229602,0 0.890326939,0 C1.12642428,0 1.35281875,0.0845331111 1.51955363,0.234946058 L8.00023302,6.06806064 L14.4809124,0.234946058 C14.8284247,-0.0777562216 15.3918535,-0.0777562178 15.7393658,0.234946066 C16.0868781,0.54764835 16.0868781,1.05463892 15.7393658,1.36734121 L8.6294597,7.76505394 C8.46272483,7.91546689 8.23633035,8 8.00023302,8 C7.76413568,8 7.5377412,7.91546689 7.37100633,7.76505394 L0.261100251,1.36734121 C0.0939433365,1.21730803 0,1.0135913 0,0.801143634 C0,0.58869597 0.0939433365,0.384979239 0.261100251,0.234946058 Z"
                        id="Path-Copy"
                        transform="translate(8.000000, 4.000000) rotate(-360.000000) translate(-8.000000, -4.000000) "
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgChevronDown;
