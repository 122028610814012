import { IPricings, IThemeDemat } from '@sweb-front/types';
import getErrorMessage from './httpError';
import axiosInstance from './httpCommon';

export interface IDataValidateOptions {
  insuranceCd: string | null;
}

export interface IDataScoring {
  financialDetails: {
    overdraftAmt?: number;
  };
  personalInformation: {
    birthInformation: {
      birthDt?: string;
    };
  };
  professionalInformation: {
    cspCd?: string;
  };
  proposal: {
    productCd?: string;
  };
}
export interface IUpdateOpportunityProject {
  project: {
    equipmentTakenAwayBo?: boolean;
    orderNumber?: string;
  };
}
export interface IUpdateOpportunitySigningInformation {
  signingInformation: {
    dematerializationBo?: boolean;
    emailAddress?: string;
    phoneNb?: string;
  };
}

// le tokenJwt est un token généré par LOS permettant le transfert d'information entre POP et SMAG
// le token1 est le premier token généré par l'APIM et envoyé par POP à SMAG via le paramètre d'URL "token1", il sert à être échangé par le BFF pour qu'il récupère son "token2" et qu'il appelle LOS via l'APIM
// le token1 est facultatif, il est renseigné quand on souhaite utiliser l'APIM

export const getPricings = (
  vendorId: string,
  onFailure: (error: string | undefined) => void
): Promise<IPricings> => {
  return axiosInstance()
    .get(`/distributors/${vendorId}/pricings`)
    .then((response) => response.data)
    .catch((err) => {
      const message = err?.response?.data?.message;
      onFailure(message);
    });
};

export const validateProposition = (
  dataIn: IDataValidateOptions,
  handleFailureValidate: (error: string) => void
): Promise<{
  scoreLightCd: string;
}> => {
  return axiosInstance()
    .get(`opportunities/proposals/validation`, {
      params: {
        insuranceCd: dataIn.insuranceCd,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      const message = getErrorMessage(err);
      handleFailureValidate(message);
    });
};

export const calculateScore = (
  forcedDecision: string | null,
  onFailure: (error: string) => void
): Promise<{ scoreLightCd: string }> => {
  return axiosInstance()
    .get(`/opportunities/proposals/score`, {
      params: {
        forcedDecision,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      if (err != null) {
        const message = err?.response
          ? err.response.data
          : `Error encountered. ${err}`;
        onFailure(message);
      }
    });
};

export const updateOpportunity = (
  dataIn: IUpdateOpportunityProject | IUpdateOpportunitySigningInformation,
  onFailure: (error: string, message?: string) => void
): Promise<{
  status: number;
} | void> => {
  return axiosInstance()
    .put(`opportunities/update`, dataIn)
    .then((response) => {
      return Promise.resolve(response);
    })

    .catch((err) => {
      const message = getErrorMessage(err);
      onFailure(message);
    });
};

export const editContract = (
  onFailure: (error: string) => void
): Promise<{
  pdfOutputBytes: string;
  previewImages: string[];
}> => {
  return axiosInstance()
    .get(`opportunities/contracts/edit`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
        : `Error encountered. ${err}`;
      onFailure(message);
    });
};

export const editDematContract = (
  dataIn: {
    themeDemat: IThemeDemat;
    backUrl?: string;
  },
  onFailure: (error: string, message?: string) => void
): Promise<{
  url?: string;
}> => {
  return axiosInstance()
    .post(
      `opportunities/contracts/editDemat`,
      {
        ...dataIn.themeDemat,
      },
      {
        params: { backUrl: dataIn.backUrl },
      }
    )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      const message = getErrorMessage(err);
      onFailure(message);
    });
};
