import { useCallback } from 'react';
import type { Event, EventParams } from '@sweb-front/types';
import { selectConfiguration, useAppSelector } from '@sweb-front/store';

export interface IXtn {
  closeToken: (endSession: boolean) => Promise<unknown>;
  fullSupport: () => Promise<unknown>;
  getExternalId: () => Promise<unknown>;
  getSdkVersion: () => Promise<unknown>;
  getSessionId: () => Promise<unknown>;
  getTokenId: () => Promise<unknown>;
  getUserAuthtenticated: () => Promise<unknown>;
  getUserId: () => Promise<unknown>;
  minimalSupport: () => Promise<unknown>;
  setExternalId: (externalId: string) => Promise<unknown>;
  setMetadata: (metadata: Record<string, string>) => Promise<unknown>;
  setTag: (tag: string) => Promise<unknown>;
  setUserAuthenticated: (isAuthenticated: boolean) => Promise<unknown>;
  setUserId: (userId: string) => Promise<unknown>;
}

declare global {
  interface Window {
    dataLayer: (Event | { 'gtm.start': number })[];
    seapweb?: IXtn;
  }
}
interface IUseAdobeTagManagerProps {
  LOGGER: Record<string, (...args: unknown[]) => void> | Console;
}
interface IUseAdobeTagManager {
  trackEvent: (data: EventParams) => void;
  FormNameLabel: string;
  EventModuleInteractionLabel: string;
  PageNamePreLabel: string;
  site?: string;
}

const useAdobeTagManager = ({
  LOGGER,
}: IUseAdobeTagManagerProps): IUseAdobeTagManager => {
  const { REACT_APP_CREDIT_TYPE } = process.env;
  const configuration = useAppSelector(selectConfiguration);

  const SITE = configuration?.businessLine;
  const FormNameLabel = `${SITE}_${REACT_APP_CREDIT_TYPE}`;
  const EventModuleInteractionLabel = 'module_interaction';
  const PageNamePreLabel = `${SITE} : Formulaire : `;

  const pushData = (data: Event) => {
    try {
      if (window.dataLayer) {
        window.dataLayer.push(data);
        if (configuration?.envName?.toLowerCase() === 'local') {
          // Décommenter pour débuguer la WebAnalyse (log l'event en table, plus pratique)
          // console.table(data);
        }
      }
    } catch (error) {
      LOGGER.error(error);
    }
  };

  const trackEvent = useCallback(
    (data: EventParams) => {
      pushData({
        site: SITE,
        environment:
          configuration?.envName?.toLowerCase() === 'prod' ? 'prod' : 'dev',
        visitorStatus: 'loggued',
        modeTest: configuration?.isInTestMode ? 'oui' : 'non',
        ...(configuration?.vendorId
          ? {
              ContributorCode: configuration?.vendorId,
            }
          : {}),
        ...(configuration?.requestId
          ? {
              AtosId: configuration?.requestId,
            }
          : {}),
        ...data,
      });
    },
    [configuration]
  );

  return {
    trackEvent,
    FormNameLabel,
    EventModuleInteractionLabel,
    PageNamePreLabel,
    site: SITE,
  };
};

export default useAdobeTagManager;
