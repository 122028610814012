import { useManagingStep } from '@sweb-front/hooks';
import { useTranslation } from 'react-i18next';

const SupportiveInformation = () => {
  const { goToNextStep, goBackToStep } = useManagingStep();
  const { t } = useTranslation();

  return (
    <div>
      {t('supportiveInformation.title')}
      <div>
        <base-button onClick={goToNextStep}>Suivant</base-button>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '16px',
          justifyContent: 'center',
          alignContent: 'center',
          textAlign: 'center',
        }}
      >
        <button-link label={t('common.back')} onClick={goBackToStep} />
      </div>
    </div>
  );
};

export default SupportiveInformation;
