export type InfoGlyphIconProps = {
  className?: string;
  fill?: string;
};

const InfoGlyphIcon = ({ className, fill }: InfoGlyphIconProps) => {
  return (
    <svg viewBox="0 0 24 24" className={className} fill={fill}>
      <path
        fill={fill || 'currentColor'}
        fillRule="evenodd"
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 8.413l-.1.006a.794.794 0 00-.694.787v5.556l.007.1a.794.794 0 00.787.694l.1-.007a.794.794 0 00.694-.787v-5.556a.794.794 0 00-.794-.793zm0-3.969a1.19 1.19 0 000 2.381l.122-.006a1.19 1.19 0 001.068-1.184l-.006-.122A1.19 1.19 0 0012 6.444z"
      />
    </svg>
  );
};

export default InfoGlyphIcon;
