import { createComponent } from '@lit-labs/react';
import React from 'react';
import { CustomValidity, Replacement } from '.';

export class NumericInputDS extends HTMLElement {
  hasClearButtonFocus?: boolean | null;
  invalid?: boolean | null;
  valid?: boolean | string | null;
  pattern?: string;
  customValidityPattern?: string | CustomValidity;
  value?: string;
  required?: boolean | null;
  disabled?: boolean | null;
  label?: string;
  title = '';
  type?: 'text' | 'number';
  placeholder?: string;
  footer?: string;
  maxlength?: number;
  readonly?: boolean | null;
  withReset?: boolean | null;
  withRound?: boolean | null;
  resetButton?: HTMLElement & { selected: boolean };
  replacement?: string | Replacement;
  showRequired?: boolean | null;
  mobileType?: 'text' | 'number';
  hasFocus?: boolean | null;
  canDelete?: boolean | null;
  errorMessage?: string;
  isMobile?: boolean | null;
  isOnIOS?: boolean | null;
}

export const NumericInput = createComponent(
  React,
  'numeric-input',
  NumericInputDS,
  {
    onInputChange: 'input-changed',
    onFocus: 'element-focused',
    onLetterChanged: 'letter-changed',
    onBlur: 'element-blured',
  }
);
