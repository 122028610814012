import { useManagingStep } from '@sweb-front/hooks';
import {
  IOpportunityState,
  IParameterState,
  setEducationChoice,
  setPerson,
  useAppDispatch,
} from '@sweb-front/store';
import { findPriOverdraftAmount, trackEvent } from '@sweb-front/utils';
import { postData } from '@vat/containers';
import { ErrorContext, useAppOptions } from '@vat/utils';
import { useContext, useEffect } from 'react';
import merge from 'lodash.merge';
import { AxiosResponse } from 'axios';
import { CHANELTYPE, IOpportunity } from '@sweb-front/types';

const useEducation = (
  opportunity: IOpportunityState,
  parameters: IParameterState
) => {
  const educationChoices = ['ETUD', 'PEDU', 'PETU'];
  const dispatch = useAppDispatch();
  const { goToNextStep } = useManagingStep();
  const updateError = useContext(ErrorContext);
  const { setAppOptions } = useAppOptions();

  useEffect(() => {
    setAppOptions({
      isMounted: true,
    });
    if (opportunity?.opportunityIdExt) {
      const isMono: boolean = opportunity?.bagType === 'MONO';
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      trackEvent({
        event: 'pageLoaded',
        Telesales:
          (opportunity?.distributionChannelCD === CHANELTYPE.TEL)?.toString() ??
          'false',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        pageName: 'E-Commerce : Choix education',
        // eslint-disable-next-line no-underscore-dangle
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        opportunityIdHashed: opportunity.opportunityIdExt,
        ContributorCode: opportunity.distributor.distributorNb,
        Amount: (isMono
          ? financialDetails?.overdraftAmt
          : findPriOverdraftAmount(opportunity.offers[0].loans)
        )?.toString(),
        Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
        Term: isMono ? financialDetails?.term?.toString() : undefined,
        MonthlyPayment: isMono
          ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
          : undefined,
        pathType: isMono ? 'mono panier' : 'multi panier',
        studentUser: opportunity?.isEducation ? 'Oui' : 'Non',
        tradeinWorkflow: opportunity?.tradeInWorkflow
      });
    }
  }, []);

  const onChoiceClick = (choice) => {
    postData(
      'vendors/opportunities/v1/opportunity',
      {
        persons: [
          {
            personId: opportunity.person?.personId,
            segmentationClientCd: choice,
          },
        ],
      },
      (response: AxiosResponse<IOpportunity>) => {
        dispatch(setEducationChoice(choice));
        dispatch(
          setPerson(
            merge({ ...opportunity.person }, response.data?.persons?.[0])
          )
        );
        goToNextStep();
      },
      () => {
        updateError();
      }
    );
  };

  return {
    educationChoices,
    onChoiceClick,
  };
};

export default useEducation;
