export type ThemeVendor = {
  logo: string;
  colors: {
    primary: string;
    primaryContrast; // To use in situations where contrast is limited
    title: string;
    titleCard: string;
    light: string;
    lightest: string;
    accent: string;
    border: string;
    borderLight: string;
    text: string;
    disabled: string;
    disabledBackground: string;
    background: string;
    stepline: string;
    error: string;
    placeholder: string;
  };
};

export const CETELEM: ThemeVendor = {
  logo: 'cetelem',
  colors: {
    primary: '#268038',
    primaryContrast: '#268038',
    title: '#292C2E',
    titleCard: '#6F757C',
    light: '#E8F5E9',
    lightest: '#F5F9F6',
    accent: '#6F757C',
    border: '#6F757C',
    borderLight: '#DCDEE0',
    text: '#4B4F54',
    disabled: '#B1B5B9',
    disabledBackground: '#F2F3F4',
    background: '#EFF8F0',
    stepline: '#E9EBEC',
    error: '#EF3654',
    placeholder: '#B1B5B9',
  },
};

export const WEST_COAST: ThemeVendor = {
  logo: 'cetelem',
  colors: {
    primary: '#0071E3',
    primaryContrast: '#0052E6',
    title: '#292C2E',
    titleCard: '#6F757C',
    light: '#E5F5FD',
    lightest: '#E4F4FD',
    accent: '#6F757C',
    border: '#6F757C',
    borderLight: '#DCDEE0',
    text: '#4B4F54',
    disabled: '#B1B5B9',
    disabledBackground: '#F2F3F4',
    background: '#F2F3F3',
    stepline: '#E9EBEC',
    error: '#EF3654',
    placeholder: '#B1B5B9',
  },
};

export const none: ThemeVendor = {
  logo: '',
  colors: {
    primary: '#151515',
    primaryContrast: '#3A913F',
    title: '#151515',
    titleCard: '#6F757C',
    light: '#F2F2F2',
    lightest: '#FAFAFA',
    accent: '#151515',
    border: '#C7CACD',
    borderLight: '#DCDEE0',
    text: '#4B4F54',
    disabled: '#6F757C',
    disabledBackground: '#F2F3F3',
    background: '#EFF8F0',
    stepline: '#E9EBEC',
    error: '#C8102E',
    placeholder: '#B1B5B9',
  },
};
