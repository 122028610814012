import styled from 'styled-components';

export const StyledRadioButton = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
`;

export const StyledRadioLabel = styled.div<{
  disabled: boolean;
}>`
  color: ${({ disabled }) => (disabled ? 'var(--neutrals-400)' : 'inherit')};
`;

export const StyledCheckmark = styled.span<{
  checked: boolean;
  disabled: boolean;
  $isValid: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 2px;
  width: ${({ theme }) => theme.variables.layout.spaces.medium};
  height: ${({ theme }) => theme.variables.layout.spaces.medium};
  border-radius: 50%;
  border: ${({ checked, theme }) =>
    !checked
      ? `${theme.variables.colors.chartColors.grey_200} solid 1px`
      : 'none'};
  background-color: ${({ checked, disabled, theme }) => {
    if (checked && !disabled) {
      return theme.vendor.colors.primary;
    }
    if (checked && disabled) {
      return theme.variables.colors.chartColors.grey_200;
    }
    return 'transparent';
  }};
  border: ${({ $isValid, theme }) =>
    !$isValid && `${theme.variables.colors.chartColors.red} solid 1px`};

  &::before {
    content: '';
    width: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
    height: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
    box-shadow: inset
      ${({ theme }) =>
        `${theme.variables.layout.spacing.spacing_xs} ${theme.variables.layout.spacing.spacing_xs} ${theme.variables.colors.chartColors.white}`};
    border-radius: 50%;
    transition: 180ms transform ease-in-out;
    transform: ${({ checked }) => (checked ? 'scale(1)' : 'scale(0)')};
  }
`;

export const WrapperRadioLabel = styled.label<{
  checked: boolean;
  $isClickable: boolean;
  disabled: boolean;
}>`
  display: grid;
  grid-template-columns: 2.4rem fit-content(100%);
  grid-gap: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  cursor: pointer;
  pointer-events: ${({ $isClickable }) => ($isClickable ? 'auto' : 'none')};

  &:hover ${StyledCheckmark} {
    ${({ checked, disabled, theme }) =>
      !checked &&
      !disabled &&
      `
      box-shadow: 0 0 0 ${theme.variables.layout.spaces.small} ${theme.vendor.colors.light};
      border: ${theme.vendor.colors.primary} solid 0.1rem;
    `}
  }
`;

export const WrapperRadioButton = styled.div`
  position: relative;
`;
