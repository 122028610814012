import { Section } from '@sweb-front/components';
import { useTranslation } from 'react-i18next';
import { IncomesExpensesLongForm } from '@vat/containers';
import { useAppOptions } from '@vat/utils';
import { useEffect } from 'react';
import { ascSort } from '@sweb-front/utils';
import { FORM_FIELDS_MAPPING, SOLVALONG } from '@vat/configuration';
import { usePageRedirection } from '@sweb-front/hooks';

export const IncomesExpensesLong = () => {
  const { t } = useTranslation();
  const { setAppOptions } = useAppOptions();
  const { isPageLoading } = usePageRedirection(SOLVALONG);
  const fields = Object.keys(FORM_FIELDS_MAPPING.SOLVAL).sort((k, l) =>
    ascSort(FORM_FIELDS_MAPPING.SOLVAL[k], FORM_FIELDS_MAPPING.SOLVAL[l])
  );

  useEffect(() => {
    setAppOptions({
      isMounted: !isPageLoading,
    });
  }, [isPageLoading]);

  return (
    !isPageLoading && (
      <Section title={t('incomeAndExpensesLong.title')}>
        <IncomesExpensesLongForm fields={fields} />
      </Section>
    )
  );
};
