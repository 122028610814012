import { useState } from 'react';

const useLoadingHook = () => {
  const [isActionLoading, setIsActionLoading] = useState(false);

  const updateIsActionLoading = (st: boolean) => {
    setIsActionLoading(st);
  };

  return {
    isActionLoading,
    updateIsActionLoading,
  };
};

export default useLoadingHook;
