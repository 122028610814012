import { createComponent } from '@lit-labs/react';
import React from 'react';

export class SelectedButtonDS extends HTMLElement {
  secondary?: string;

  value: unknown;
}

export const SelectedButton = createComponent(
  React,
  'selected-button',
  SelectedButtonDS,
  {
    onSelectedChange: 'selected-changed',
  }
);
