import styled from 'styled-components';

export const NumericListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
`;

export const NumberWrapper = styled.div<{ backgroundColor?: string }>`
  font-family: 'Ubuntu Bold';
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.vendor.colors.background};
  vertical-align: middle;
  color: ${({ theme }) => theme.vendor.colors.text};
  padding: 0.1rem;
`;

export const ContentWrapper = styled.div`
  margin-left: 0.8rem;
  flex: 80%;
`;

export const TitleWrapper = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop || '0.14rem'};
  font-size: 1.6rem;
  font-family: 'Open Sans Bold';
  color: ${({ theme }) => theme.vendor.colors.text};
  line-height: 2.4rem;
`;

export const DescriptionWrapper = styled.div<{ marginTop?: string }>`
  font-size: 1.6rem;
  font-family: 'Open Sans';
  color: ${({ theme }) => theme.vendor.colors.text};
  line-height: 2.4rem;
  margin-top: ${({ marginTop }) => marginTop || ''};
`;
