import Lottie from 'react-lottie-player';
import lottieJson from './lottieData.json';
import {
  WrapperLoader,
  Background,
  PrimaryText,
  SecondaryText,
} from './styles';

export interface ILoaderProps {
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  primaryText?: string;
  secondaryText?: string;
  isTextInline?: boolean;
  isHideBackground?: boolean;
  animationDuration?: number;
}

const Loader = ({
  className = '',
  isLoading = true,
  isTextInline = false,
  primaryText,
  secondaryText,
  children,
  isHideBackground = true,
}: ILoaderProps): React.ReactElement => (
  <WrapperLoader
    isLoading={isLoading}
    className={className}
    isTextInline={isTextInline}
    data-testid="app-loader"
  >
    {isLoading && (
      <Background
        $isLoading={isLoading}
        $isTextInline={isTextInline}
        $isHideBackground={isHideBackground}
      >
        <Lottie
          play={isLoading}
          loop
          animationData={lottieJson}
          style={{ width: 47, height: 47 }}
        />
        {primaryText && <PrimaryText>{primaryText}</PrimaryText>}
        {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
      </Background>
    )}
    {children}
  </WrapperLoader>
);

export default Loader;
