import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { TextWrapper, formatNumberWithComma } from '@vat/containers';
import { getUniqueId } from '@vat/utils';
import {
  BoldTextAmount,
  BolderTextAmount,
  CardContentGlobal,
  CardContentLineGlobal,
  CardContentLineGlobalTAEG,
  CardDisclosure,
  CardSummaryCardCreditContent,
  CenterCol,
  MediumSpan,
  OrderSummaryCardGlobal,
  OrderSummaryCardHeaderProductSummary,
  OrderSummaryGlobalCardTitle,
  OrderSummaryProductSummaryDescription,
  PaddedSpan,
  SmallTextPill,
  StyledChevronDownIcon,
  StyledLink,
  SubTextLabel,
  PeriodLineGlobal,
  MonthlyLabel,
  StyledChevronDownIconWrapper,
} from './styles';
import { Pill } from '../Pill/Pill';

export type OrderSummaryCardGlobalContentProps = {
  periods?: {
    startMonth?: string;
    endMonth?: string;
    amountPerMonth?: number;
  }[];
  total?: number;
  borrowedAmount?: number;
  fixedTAEG?: number;
  interests?: number;
  withBorderTop?: boolean;
  openModal?: () => void;
};

const OrderSummaryCardGlobalContent = ({
  periods,
  total,
  borrowedAmount,
  fixedTAEG,
  interests,
  openModal,
}: OrderSummaryCardGlobalContentProps) => {
  const { t } = useTranslation();
  const nodeRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <CardSummaryCardCreditContent data-testid="orderSummary-global">
        <CardDisclosure
          fullWidth
          onClick={() => setIsOpened((value) => !value)}
        >
          <OrderSummaryCardGlobal>
            <OrderSummaryCardHeaderProductSummary>
              <OrderSummaryGlobalCardTitle>
                {t('orderSummary.global.title')}
              </OrderSummaryGlobalCardTitle>
              <StyledChevronDownIconWrapper>
                <StyledChevronDownIcon isOpened={isOpened} />
              </StyledChevronDownIconWrapper>
            </OrderSummaryCardHeaderProductSummary>
            <OrderSummaryProductSummaryDescription isRecapDescription={true}>
              {t('orderSummary.global.description')}
            </OrderSummaryProductSummaryDescription>
            <PeriodLineGlobal>
              {periods?.map((period) => (
                <CardContentLineGlobal key={getUniqueId()}>
                  <Pill>
                    <CenterCol>
                      <SmallTextPill>
                        {period.startMonth === '0'
                          ? `Les ${period.endMonth} premiers mois`
                          : `Du ${period.startMonth} au ${period.endMonth}ème mois`}
                      </SmallTextPill>
                    </CenterCol>
                  </Pill>
                  <PaddedSpan className="summaryPaddedSpan">
                    <BoldTextAmount>
                      {formatNumberWithComma(period.amountPerMonth)} €
                    </BoldTextAmount>
                    &nbsp;
                    <MonthlyLabel>
                      {t('orderSummary.summary.monthlyCost')}
                    </MonthlyLabel>
                  </PaddedSpan>
                </CardContentLineGlobal>
              ))}
            </PeriodLineGlobal>
            <CardContentLineGlobal name={'totalAmount'}>
              <MediumSpan>{t('orderSummary.global.total')}</MediumSpan>
              <BolderTextAmount className="summaryBolderTextAmount">
                {formatNumberWithComma(total)} €
              </BolderTextAmount>
            </CardContentLineGlobal>
          </OrderSummaryCardGlobal>
          <CSSTransition
            in={isOpened}
            nodeRef={nodeRef}
            timeout={225}
            unmountOnExit
            classNames="animation"
          >
            <CardContentGlobal ref={nodeRef}>
              <CardContentLineGlobal>
                <span>{t('orderSummary.global.borrowedAmount')}</span>
                <span>{formatNumberWithComma(borrowedAmount)} €</span>
              </CardContentLineGlobal>
              <CardContentLineGlobalTAEG>
                <TextWrapper>
                  <BoldTextAmount className="boldTextAmountFixedTaeg">
                    {t('orderSummary.global.fixedTAEG')}
                  </BoldTextAmount>
                  <BoldTextAmount className="boldTextAmountFixedTaeg">
                    {formatNumberWithComma(fixedTAEG) === 0
                      ? '0,00'
                      : formatNumberWithComma(fixedTAEG)}{' '}
                    %
                  </BoldTextAmount>
                </TextWrapper>
                <SubTextLabel>
                  {t('orderSummary.global.fixedTAEGLabel')}
                </SubTextLabel>
              </CardContentLineGlobalTAEG>
              <CardContentLineGlobal>
                <span>{t('orderSummary.global.interests')}</span>
                <span>{formatNumberWithComma(interests)} €</span>
              </CardContentLineGlobal>
            </CardContentGlobal>
          </CSSTransition>
          <StyledLink onClick={openModal} id="open-recap-modal">
            {t('orderSummary.global.understandSchedule')}
          </StyledLink>
        </CardDisclosure>
      </CardSummaryCardCreditContent>
    </>
  );
};

export default OrderSummaryCardGlobalContent;
