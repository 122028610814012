import { ReactNode } from 'react';
import DescriptionWrapper from './styles';

export interface IDescriptionProps {
  id?: string;
  className?: string;
  children: ReactNode;
}

const Description: React.FC<IDescriptionProps> = ({
  children,
  id,
  className,
}: IDescriptionProps) => {
  return (
    <DescriptionWrapper id={id} className={className}>
      {children}
    </DescriptionWrapper>
  );
};

export default Description;
