import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2.4rem;
  padding: 1.4rem 0;

  .animation-enter {
    opacity: 0;
  }
  .animation-enter-active {
    -webkit-animation: fromTop 200ms ease-in both; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fromTop 200ms ease-in both; /* Firefox < 16 */
    -ms-animation: fromTop 200ms ease-in both; /* Internet Explorer */
    -o-animation: fromTop 200ms ease-in both; /* Opera < 12.1 */
    animation: fromTop 200ms ease-in both;
  }

  @keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fromTop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .animation-exit {
    opacity: 1;
  }

  .animation-exit-active {
    -webkit-animation: toTop 200ms ease-in-out both; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: toTop 200ms ease-in-out both; /* Firefox < 16 */
    -ms-animation: toTop 200ms ease-in-out both; /* Internet Explorer */
    -o-animation: toTop 200ms ease-in-out both; /* Opera < 12.1 */
    animation: toTop 200ms ease-in-out both;
  }

  @keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes toTop {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const IncomeDetailsInfoWrapper = styled.div`
  margin-top: 0.8rem 0 0 0;
  &.bubbleinfo-income-activity {
    margin-top: calc(0.8rem - 2.4rem); // minus row-gap
  }
`;

export const ModalRadioGroupContainer = styled.div`
  padding: 0 2.5%;
  max-height: 90vh;
`;

export const Spacer = styled.div`
  height: -0.8rem;
`;
