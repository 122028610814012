import { CustomValidity, Replacement } from '.';

const sanitizeRule: (rule: InputRules) => {
  replacement: string;
  customValidityPattern: string;
} = (rule) => {
  return {
    replacement: rule.replacement ? JSON.stringify(rule.replacement) : '',
    customValidityPattern: rule.customValidityPattern
      ? JSON.stringify(rule.customValidityPattern)
      : '',
  };
};

type InputRules = {
  replacement?: Replacement[];
  customValidityPattern?: CustomValidity[];
};

export const LastNameRules = sanitizeRule({
  replacement: [{ regexp: "[^a-zA-Zzàâçèéêîôùû'ëä -]", replacement: '' }],
  customValidityPattern: [
    { regexp: '.{2,30}', errorMessage: 'Le format du nom est incorrect.' },
    { regexp: '.+', errorMessage: 'Veuillez renseigner votre nom.' },
  ],
} as InputRules);

export const FirstNameRules = sanitizeRule({
  replacement: [{ regexp: "[^a-zA-Zzàâçèéêîôùû'ëä -]", replacement: '' }],
  customValidityPattern: [
    { regexp: '.{2,30}', errorMessage: 'Le format du prénom est incorrect.' },
    { regexp: '.+', errorMessage: 'Veuillez renseigner votre prénom.' },
  ],
} as InputRules);

export const BirthNameRules = sanitizeRule({
  replacement: [{ regexp: "[^a-zA-Zzàâçèéêîôùû'ëä -]", replacement: '' }],
} as InputRules);

const backslash = '\\';
export const EmailRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9A-Za-z-_.@]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: `^(?!aaaa|abc|abcd|abcde|abcdef|azerty|bbbb|cccc|dns|host|hostmaster|ipadmin|masterdns|noc|nospam|postmaster|qwerty|root|security|sysadmin|usenet|webmaster)(?:[a-z0-9A-Z_-]+(?:${backslash}.[a-z0-9A-Z_-]+)*|")@(?!corp.aol.com|corp.aol.fr|mail.com|activexx.com|ahoo.fr|alicedsl.fr|anadoo.fr|aol.cm|aposte.net|ayahoo.com|caramail.fr|caramail.com|chello.fr|clubinternet.com|clubinternet.fr|com.com|corp.free.fr|euf.fr|fre.fr|gemail.com|geocitie.com|geocitiesyahoo.com|geocitis.com|gmail.fr|gocities.com|googl.com|homail.co.uk|homail.com|homail.fr|homtail.com|hormail.com|hotail.com|hotamail.fr|hotamil.com|hotmai.com|hotmai.fr|hotmailcom.com|hotmaill.com|hotmil.com|hoymail.co.uk|hoymail.fr|htomail.com|htomail.fr|hiahoo.com|jubii.co.uk|jubii.com|jubii.de|jubii.dk|jubii.es|jubii.fr|jubii.it|jubii.nl|jubiiconnect.dk|lapsote.net|lemel.fr|lfdv.fr|lycos.at|lycos.co.uk|lycos.de|lycos.de|lycos.es|lycos.fr|lycos.it|lycos.nl|lymedias.com|mobistar.be|mobistarmail.be|myahoo.com|myyahoo.com|oange.fr|online.net|orane.fr|business.fr|otmail.com|otmail.fr|pourriels.org|range.fr|ree.fr|tele.fr|tele2.be|tele2.fr|terra.es|tiscali.be|tiscali.fr|voila.fr|wanadoo.com|wandaoo.fr|wajunnadoo.fr|yafoo.com|yahll.com|yahll.com|yaho.com|yahomail.com|yahooguide.com|yahoonews.com|yahoosearch.com|yahos.com|yahow.com|yahu.com|yahuu.com|yahwho.com|yahwoo.com|yanoo.com|yaoo.fr|yauoo.com|yayoo.fr|yyahoo.com|ayhoo.com|carmail.com|geocties.com|goecities.com|hayoo.fr|ayoo.fr)(?:[a-z0-9A-Z-]+(?:${backslash}.[a-z0-9A-Z-]+)*|")${backslash}.(?:[a-z0-9A-Z-]+(?:${backslash}.[a-z0-9A-Z-]+)*|")$`,
      errorMessage: 'Le format de votre adresse e-mail est incorrect.',
    },
    { regexp: '.+', errorMessage: 'Veuillez renseigner votre adresse e-mail.' },
  ],
} as InputRules);

export const MobilePhoneRules = sanitizeRule({
  customValidityPattern: [
    {
      regexp: '^0(6|7)([-. ]?\\d{2}){4}?$',
      errorMessage:
        'Le format de votre numéro de mobile doit commencer par 06 ou 07. Et il doit contenir 10 chiffres.',
    },
    {
      regexp: '.+',
      errorMessage: 'Veuillez renseigner votre numéro de mobile.',
    },
  ],
} as InputRules);

export const HomePhoneRules = sanitizeRule({
  customValidityPattern: [
    {
      regexp: '^0[0-9 ]{13}$',
      errorMessage:
        'Votre numéro de domicile doit contenir 10 chiffres et commencer par 0.',
    },
  ],
} as InputRules);

export const EmploymentPhoneRules = sanitizeRule({
  customValidityPattern: [
    {
      regexp: '^0[0-9 ]{13}$',
      errorMessage:
        'Votre numéro de professionnel doit contenir 10 chiffres et commencer par 0.',
    },
  ],
} as InputRules);

export const BirthCityRules = sanitizeRule({
  replacement: [{ regexp: "[^a-zA-Zzàâçèéêîôùû'ëä -]", replacement: '' }],
  customValidityPattern: [
    {
      regexp: `[A-Za-z0-9' ]`,
      errorMessage: "L'information saisie est incorrecte.",
    },
    {
      regexp: '.+',
      errorMessage: 'Veuillez renseigner votre ville de naissance.',
    },
  ],
} as InputRules);

export const AddressRules = sanitizeRule({
  replacement: [{ regexp: "[^a-zA-Z0-9-àâçèéêîôùû'ëä -]", replacement: '' }],
  customValidityPattern: [
    {
      regexp: `[a-zA-Z0-9-àâçèéêîôùû'ëä -]{2,}`,
      errorMessage:
        'Veuillez vérifier votre adresse, elle ne semble pas complète.',
    },
    { regexp: '.+', errorMessage: 'Veuillez renseigner votre adresse.' },
  ],
} as InputRules);

export const AddressBisRules = sanitizeRule({
  replacement: [{ regexp: "[^a-zA-Z0-9-àâçèéêîôùû'ëä -]", replacement: '' }],
} as InputRules);

export const ZipCodeRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '[0-9]{5}$',
      errorMessage:
        'Le format de votre code postal semble incorrect (ex : 59000). Veuillez réessayer.',
    },
    { regexp: '.+', errorMessage: 'Veuillez renseigner votre code postal.' },
  ],
} as InputRules);

export const AddressCityRules = sanitizeRule({
  customValidityPattern: [
    {
      regexp: "([A-Za-z0-9 '])",
      errorMessage: "L'information saisie est incorrecte.",
    },
    { regexp: '.+', errorMessage: 'Veuillez renseigner votre ville.' },
  ],
} as InputRules);

export const logementDateRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
} as InputRules);

export const AccountOpeningDateRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
} as InputRules);

export const RentAmountRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '.+',
      errorMessage:
        'Veuillez préciser votre loyer ou remboursement de votre résidence principale ou sélectionner "Aucun".',
    },
  ],
} as InputRules);

export const CtlmLoanAmountRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '.+',
      errorMessage:
        'Veuillez indiquer le montant de vos crédit(s) à la consommation Cetelem en cours ou sélectionner "Aucun".',
    },
  ],
} as InputRules);

export const OthersLoanAmountRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '.+',
      errorMessage:
        'Veuillez  indiquer le montant de vos autre(s) crédit(s) à la consommation en cours ou sélectionner "Aucun".',
    },
  ],
} as InputRules);

export const ExpenseFoodPensionRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '.+',
      errorMessage:
        'Veuillez  indiquer le montant de vos pension(s) alimentaire(s) versée(s) ou sélectionner "Aucun".',
    },
  ],
} as InputRules);

const errorChildCareExpenses = `Veuillez indiquer le montant de vos frais de garde d'enfant(s) ou sélectionner "aucun".`;

export const ChildCareExpensesRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    { regexp: '.+', errorMessage: errorChildCareExpenses },
  ],
} as InputRules);

export const PropertyIncomeRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '.+',
      errorMessage:
        'Veuillez préciser vos revenus fonciers ou sélectionner "aucun".',
    },
  ],
} as InputRules);

export const FamilyAllowanceRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '.+',
      errorMessage:
        'Veuillez préciser vos allocations familiales ou sélectionner "aucun".',
    },
  ],
} as InputRules);

export const PensionIncomeRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '.+',
      errorMessage:
        'Veuillez préciser vos pensions reçues ou sélectionner "aucun".',
    },
  ],
} as InputRules);

export const HolderSalaryRules = sanitizeRule({
  replacement: [{ regexp: '[^0-9]', replacement: '' }],
  customValidityPattern: [
    {
      regexp: '.+',
      errorMessage:
        'Veuillez préciser votre salaire mensuel net ou sélectionner "aucun".',
    },
  ],
} as InputRules);
