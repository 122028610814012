import { t } from 'i18next';
import {
  ButtonWrapper,
  CustomerInformationnCardWrapper,
  ImgWrapper,
  InfoWrapper,
  MessageWrapper,
  StyledButton,
  TitleWrapper,
} from './styles';
import { trackEvent } from '@sweb-front/utils';

export type CustomerInformation = {
  id?: string;
  firstName: string;
  lastName: string;
  adsress: string;
  zipCode: string;
  city: string;
  counrty: string;
  email: string;
  cellPhone: string;
  onButtonClick: () => void;
};

export interface ICustomerInformationnProps {
  props: CustomerInformation;
}

const trackEventEditButton = () => {
  trackEvent({ event: 'confirmExit' });
};

const CustomerInformationCard = ({ props }: ICustomerInformationnProps) => {
  return (
    <CustomerInformationnCardWrapper id={props.id}>
      <ImgWrapper>
        <base-icon icon="warning-Fill" />
      </ImgWrapper>
      <div className="content">
        <TitleWrapper>{t('customerInformation.title')}</TitleWrapper>
        <MessageWrapper>
          {t('customerInformation.confirmationMessage')}
        </MessageWrapper>
        <InfoWrapper>
          <div>
            {props.firstName} {props.lastName}
          </div>
          <div>{props.adsress}</div>
          <div>
            {props.zipCode} {props.city} ({props.counrty})
          </div>
          <div>{props.email}</div>
          <div>{props.cellPhone}</div>
        </InfoWrapper>
        <ButtonWrapper>
          <StyledButton
            onClick={(e) => {
              e.preventDefault();
              trackEventEditButton();
              props.onButtonClick();
            }}
            id="contract-download-button"
          >
            {t('customerInformation.modify')}
            <base-icon icon="arrow-right-short" />
          </StyledButton>
        </ButtonWrapper>
      </div>
    </CustomerInformationnCardWrapper>
  );
};

export default CustomerInformationCard;
